import styled from 'styled-components';
import {
  Text,
  List as BaseList,
} from '@vizir-banking/banking-app-core/dist/layout';

export const Message = styled(Text)`
  margin-top: 20px;
`;

export const List = styled(BaseList)`
  margin-bottom: 15px;
`;
