import React, {ReactElement, ReactNode} from 'react';
import {connect} from 'react-redux';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {StatementService} from '@vizir-banking/banking-app-core-plugins/dist/statement/services/interfaces/get-statement-request/banking-statement-service';
import {BankingStatementService} from '@vizir-banking/banking-app-core-plugins/dist/statement/services/banking-statement-service';
import {PluginsContext} from '@vizir-banking/banking-app-core-plugins/dist/contexts/plugins-context';
type Props = {children?: ReactNode};
class Provider extends React.PureComponent<Props> {
  private statementService?: StatementService;
  static contextType = ApplicationContext;
  public context!: ApplicationContextType;
  public getStatementService(): StatementService {
    if (this.statementService === undefined) {
      this.statementService = new BankingStatementService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.statementService;
  }
  public render(): ReactElement {
    return (
      <PluginsContext.Provider
        value={{
          statementService: this.getStatementService(),
        }}
      >
        {this.props.children}
      </PluginsContext.Provider>
    );
  }
}
export const PluginsProvider = connect()(Provider);
