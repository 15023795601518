import {AppRoute} from '@vizir-banking/banking-app-core/dist/navigation/app-route/app-route';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import EditProfile from '~/settings/screens/edit-profile/edit-profile';
import ChangeAppPassword from '~/settings/screens/change-app-password/change-app-password';
import SensorSettings from '~/settings/screens/sensor-settings/sensor-settings.container';
import {ProfileScreenContainer} from '~/settings/screens/profile/profile.container';
import TermsOfUseScreen from '~/settings/screens/terms-of-use/terms-of-use.container';
import PrivacyPolicyScreen from '~/settings/screens/privacy-policy/privacy-pocily.container';
import {IncomeReportScreenContainer} from '~/settings/screens/income-report/income-report.container';
import definitions from '~/settings/navigation/screen-definitions';

RoutesManager.addSection('settings', definitions, (): AppRoute[] => {
  return [
    new AppRoute(definitions.editProfile, EditProfile, {isPrivate: true}),
    new AppRoute(definitions.changeAppPassword, ChangeAppPassword, {
      isPrivate: true,
      isSensible: true,
    }),
    new AppRoute(definitions.termsOfUse, TermsOfUseScreen, {
      isPrivate: true,
    }),
    new AppRoute(definitions.privacyPolicy, PrivacyPolicyScreen, {
      isPrivate: true,
    }),
    new AppRoute(definitions.sensorSettings, SensorSettings, {isPrivate: true}),
    new AppRoute(definitions.settings, ProfileScreenContainer, {
      isPrivate: true,
    }),
    new AppRoute(definitions.incomeReport, IncomeReportScreenContainer, {
      isPrivate: true,
      isSensible: true,
    }),
  ];
});
