import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';

import IdentityScreen from './identity';
import {UserIdentity} from './types';

const actionCreators = {addCreationUser};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  identity: {
    rg: string;
    rgIssuingDate: string;
  };
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  identity: {
    rg: get(onboarding, 'creationUser.rg'),
    rgIssuingDate: get(onboarding, 'creationUser.rgIssuingDate'),
  },
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

const IdentityContainer = ({
  identity,
  actions,
  navigateToNextOnboardingScreen,
}: Props): ReactElement => {
  const onSubmit = (formValues: UserIdentity): void => {
    actions.addCreationUser('rg', formValues.rg);
    actions.addCreationUser('rgIssuingDate', formValues.rgIssuingDate);

    navigateToNextOnboardingScreen();
  };

  return <IdentityScreen identity={identity} onSubmit={onSubmit} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(IdentityContainer, ONBOARDING_SCREENS.identity));
