import React, {ReactElement} from 'react';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import DocumentComponent from '~/onboarding/screens/send-document/photo-identification-document/photo-identification-document-base';

type Props = OnboardingNavigationProps;

class PhotoIdentificationDocument extends React.PureComponent<Props> {
  onConfirmPicture = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  render(): ReactElement {
    return <DocumentComponent onConfirmPicture={this.onConfirmPicture} />;
  }
}

export default withOnboardingNavigation(
  PhotoIdentificationDocument,
  ONBOARDING_SCREENS.photoIdentificationDocument,
);
