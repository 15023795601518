import React, {ReactElement} from 'react';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import {ApplicationError} from '@vizir-banking/banking-app-core/dist/errors/application-error';

import sadFaceImage from '~/assets/images/sad_face.png';
import translate from '~/i18n/translate';
import {
  Title,
  Message,
  TitleWrapper,
  Button,
  Image,
} from '~/onboarding/screens/account-creating/account-creating.styles';

const RetryFailure = ({
  error,
  title,
  message,
  buttonLabel,
  onPressRetry,
}: {
  error?: ApplicationError;
  title?: string;
  message?: string;
  buttonLabel?: string;
  onPressRetry: () => void;
}): ReactElement => {
  const errorCode = error?.code;

  return (
    <TitleWrapper>
      <Image source={sadFaceImage} />

      <Title>{title || translate('onboarding.accountCreating.retry')}</Title>
      <Message>
        {message || translate('onboarding.accountCreating.retryMessage')}
      </Message>
      <Button
        type={ButtonTypes.PRIMARY}
        onPress={onPressRetry}
        text={
          buttonLabel || translate('onboarding.accountCreating.retryButton')
        }
      />
      {errorCode && (
        <Message>
          {translate('onboarding.accountCreating.errorDetail', {errorCode})}
        </Message>
      )}
    </TitleWrapper>
  );
};

export default RetryFailure;
