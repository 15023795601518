import React, {ReactElement} from 'react';

import {
  ListItemWrapper,
  LabelItemWrapper,
  Label,
  BoldLabel,
  Icon,
} from '~/onboarding/screens/send-document/photo-identification-document/components/photo-identification-document-item-list.styles';

type PhotoDocumentItemTypes = {
  icon: string;
  boldLabel: string;
  label: string;
};

const PhotoIdentificationDocumentItemList = (
  props: PhotoDocumentItemTypes,
): ReactElement => {
  const {icon, boldLabel, label} = props;

  return (
    <ListItemWrapper>
      <Icon name={icon} size={30} />
      <LabelItemWrapper>
        <BoldLabel>{boldLabel}</BoldLabel>
        <Label>{label}</Label>
      </LabelItemWrapper>
    </ListItemWrapper>
  );
};

export default PhotoIdentificationDocumentItemList;
