import {ListOption} from '@vizir-banking/banking-app-core/dist/layout/list/types';

import translate from '~/i18n/translate';

import {CompanyStatus} from './types';

export const CompanyStatusSelectOptions = (): ListOption[] => [
  {
    value: CompanyStatus.ACTIVE,
    label: `${translate('onboarding.companyStatus.status.active')}`,
  },
  {
    value: CompanyStatus.SUSPENDED,
    label: `${translate('onboarding.companyStatus.status.suspended')}`,
  },
  {
    value: CompanyStatus.INEPT,
    label: `${translate('onboarding.companyStatus.status.inept')}`,
  },
  {
    value: CompanyStatus.DISCHARGED,
    label: `${translate('onboarding.companyStatus.status.discharged')}`,
  },
  {
    value: CompanyStatus.NULL,
    label: `${translate('onboarding.companyStatus.status.null')}`,
  },
];
