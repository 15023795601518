import {
  HomeManager,
  MenuItem,
} from '@vizir-banking/banking-app-core/dist/hooks/home-manager';
import BILLET_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/deposit/navigation/screen-definitions';
import TRANSFER_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/transfer/navigation/screen-definitions';
import STATEMENT_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/statement/navigation/screen-definitions';
import VIRTUAL_CARD_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/virtual-card/navigation/screen-definitions';
import CARD_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/physical-card/navigation/screen-definitions';
import PAYMENT_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/payment/navigation/screen-definitions';

import {SETTINGS_SCREENS} from '~/navigation/screen-definitions';

export const MENU_ITEMS: MenuItem[] = [
  {
    order: 1,
    label: 'home.transfer',
    icon: 'transfer_menu',
    screenName: TRANSFER_SCREENS.bankData,
  },
  {
    order: 2,
    label: 'home.payment',
    icon: 'payment',
    screenName: PAYMENT_SCREENS.barcodeReader,
    eventLabel: 'Pagamento_Acessar',
  },
  {
    order: 3,
    label: 'home.depositBillet',
    icon: 'deposit_billet',
    screenName: BILLET_SCREENS.depositBillet,
    iconHeight: 24,
  },
  {
    order: 4,
    label: 'home.statement',
    icon: 'statement',
    screenName: STATEMENT_SCREENS.allStatements,
    iconHeight: 31,
    iconWidth: 14,
  },
  {
    order: 5,
    label: 'home.myCard',
    icon: 'request_card_menu',
    screenName: CARD_SCREENS.cardData,
    iconHeight: 26,
    iconWidth: 39,
  },
  {
    order: 6,
    label: 'home.virtualCard',
    icon: 'virtual_card_menu',
    screenName: VIRTUAL_CARD_SCREENS.checkPasswordAccessCard,
    iconHeight: 28,
    iconWidth: 28,
  },
  {
    order: 7,
    label: 'home.myDetails',
    icon: 'person',
    screenName: SETTINGS_SCREENS.settings,
  },
];

export const registerMenuItems = (homeManager: typeof HomeManager): void => {
  homeManager.addHomeMenuItem(MENU_ITEMS);
};
