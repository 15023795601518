import styled from 'styled-components';
import {Screen as BaseScreen} from '@vizir-banking/banking-app-core/dist/common';
import {
  Text,
  View,
  Link as BaseLink,
  TextInput,
} from '@vizir-banking/banking-app-core/dist/layout';

import {
  ScreenStyle,
  MessageStyle,
  SendEmailTextStyle,
  LabelStyle,
  WrapMessageStyle,
  LinkTextStyle,
  AccessKeyInputStyle,
  ErrorMessageStyle,
} from '~/onboarding/screens/access-key/access-key.styles.base';

export const Screen = styled(BaseScreen)`
  ${ScreenStyle}
`;

export const Message = styled(Text)`
  ${MessageStyle}
`;

export const SendEmailText = styled(Text)`
  ${SendEmailTextStyle}
`;

export const Label = styled(Text)`
  ${LabelStyle}
`;

export const WrapMessage = styled(View)`
  ${WrapMessageStyle}
  flex-flow: row wrap;
  justify-content: center;
`;

export const LinkText = styled(Text)`
  ${LinkTextStyle}
  cursor: pointer;
  margin-left: 8px;

  @media (max-width: 320px) {
    word-break: break-all;
  }
`;

export const Link = styled(BaseLink)`
  text-decoration: none;
`;

export const AccessKeyInput = styled(TextInput)`
  ${AccessKeyInputStyle}
`;

export const ErrorMessage = styled(Text)`
  ${ErrorMessageStyle}
`;
