import styled from 'styled-components';
import {
  RadioGroup as BaseRadioGroup,
  FormField as BaseFormField,
} from '@vizir-banking/banking-app-core/dist/common';
import {
  Form as BaseForm,
  Button as BaseButton,
  Text,
  View,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Input} from '@vizir-banking/banking-app-core/dist/common/text-input/text-input.styles';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  flex-grow: 1;
`;

export const RadioGroup = styled(BaseRadioGroup)`
  margin: 0;
  justify-content: center;
`;

export const Title = styled(Text)`
  font-family: ${useTheme(({fonts}) => fonts.primary.weight.bold)};
  font-size: 25px;
  margin: 0 0 20px;
  text-align: center;
`;

export const Form = styled(BaseForm)`
  flex-grow: 1;
  justify-content: center;
`;

export const FormField = styled(BaseFormField).attrs({
  textInputStyle: {
    fontSize: 20,
  },
  prefixStyle: {
    paddingBottom: 12,
  },
})`
  ${Input} {
    font-size: 20px;
  }
`;

export const FieldWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const Button = styled(BaseButton)``;
