import get from 'lodash/get';
import EventEmitter, {
  Events,
} from '@vizir-banking/banking-app-core/dist/utils/event-emitter/event-emitter';
import analytics from '@vizir-banking/banking-app-core/dist/utils/firebase/analytics';

import {Listener} from '~/navigation/listeners/listener';

export class FirebaseRouteTrackingListener implements Listener {
  private previousRoutePath?: string;

  public constructor() {}

  private onNavigate = (event: unknown): void => {
    this.logRouteChangeToAnalytics(event).catch(() => {
      /* ignore firebase tracking errors */
    });
  };

  private async logRouteChangeToAnalytics(event: unknown): Promise<void> {
    const currentRoutePath = get(event, 'path');

    if (typeof currentRoutePath !== 'string') return;
    if (this.previousRoutePath === currentRoutePath) return;

    await analytics.logEvent(currentRoutePath, {
      event_type: 'view',
    });

    this.previousRoutePath = currentRoutePath;
  }

  public setListener(): void {
    EventEmitter.on(Events.ROUTE_NAVIGATION, this.onNavigate);
  }

  public unsetListener(): void {
    EventEmitter.off(Events.ROUTE_NAVIGATION, this.onNavigate);
  }
}
