import React, {ReactElement, ComponentClass} from 'react';
import isEmpty from 'lodash/isEmpty';
import {Screen, ScreenTypes} from '@vizir-banking/banking-app-core/dist/common';
import {View} from '@vizir-banking/banking-app-core/dist/layout';
import CardMenuItem from '@vizir-banking/banking-app-core/dist/layout/card-menu-item/card-menu-item';
import {
  HomeManager,
  MenuItem,
} from '@vizir-banking/banking-app-core/dist/hooks/home-manager';
import analytics from '@vizir-banking/banking-app-core/dist/utils/firebase/analytics';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {connect} from 'react-redux';
import {RestrictionTypes} from '@vizir-banking/banking-app-core/dist/redux/application/types';

import translate from '~/i18n/translate';
import RestrictionCard from '~/home/components/restriction-card/restriction-card';
import RestrictionModal from '~/home/components/restriction-modal/restriction-modal';

import {CardsWrapper, Version} from './home.styles';
import {Props as HomeProps} from './types';

const pluginComponents = (): ReactElement | null => {
  const classes = HomeManager.getComponentClasses();

  if (isEmpty(classes)) {
    return null;
  }

  return (
    <View>
      {classes.map((Component: ComponentClass, i) => (
        <Component key={i} />
      ))}
    </View>
  );
};

type Props = HomeProps & ReturnType<typeof mapStateToProps>;

const Home = ({
  menuItems,
  redirectToScreen,
  readOnlyState,
  navigate,
}: Props): ReactElement => {
  const handlePress = async ({
    screenName,
    onPress,
    eventLabel,
  }: MenuItem): Promise<void> => {
    await analytics.logEvent(eventLabel, {
      event_type: 'clicked',
    });

    onPress && onPress();
    screenName && redirectToScreen(screenName);
  };

  const renderMenuItem = (menuItem: MenuItem, index: number): ReactElement => {
    const key = `home-card-menu-item-${index}`;

    const {
      label,
      icon,
      iconHeight,
      iconWidth,
      badgeText,
      badgeColor,
      wrapperHOC,
    } = menuItem;

    const Item = wrapperHOC ? wrapperHOC(CardMenuItem) : CardMenuItem;

    return (
      <Item
        key={key}
        label={translate(label)}
        iconName={icon}
        iconHeight={iconHeight}
        iconWidth={iconWidth}
        onPress={(): Promise<void> => handlePress(menuItem)}
        badgeText={badgeText}
        badgeColor={badgeColor}
      />
    );
  };

  const renderRestrictionCard = (): ReactElement | null => {
    if (readOnlyState === RestrictionTypes.NO_RESTRICTION) {
      return null;
    }

    return (
      <RestrictionCard restrictionType={readOnlyState} navigate={navigate} />
    );
  };

  return (
    <Screen type={ScreenTypes.HOME}>
      {renderRestrictionCard()}
      {pluginComponents()}
      <CardsWrapper>{menuItems.map(renderMenuItem)}</CardsWrapper>
      <Version />
      <RestrictionModal />
    </Screen>
  );
};

type State = {
  readOnlyState: RestrictionTypes;
};

const mapStateToProps = ({application}: ReduxState): State => ({
  readOnlyState: application.readOnlyState,
});

export default connect(mapStateToProps)(Home);
