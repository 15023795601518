import React, {ReactElement} from 'react';
import Dialog from '@vizir-banking/banking-app-core/dist/layout/dialog/dialog';
import {ButtonTypes, Button} from '@vizir-banking/banking-app-core/dist/layout';

import {
  DialogTitle,
  TextWrapper,
  InfoText,
  SecondaryButton,
} from '~/onboarding/screens/company-partners/partner-action-dialog/partner-action-dialog.styles';

import {Props} from './types';

class PartnerActionDialog extends React.Component<Props> {
  render(): ReactElement {
    const {onActionButtonPress} = this.props;
    return (
      <Dialog show={this.props.dialogVisible} onClose={this.props.onClose}>
        <DialogTitle>{this.props.dialogTitle}</DialogTitle>
        <TextWrapper>
          <InfoText>{this.props.dialogText}</InfoText>
        </TextWrapper>
        <Button
          type={ButtonTypes.PRIMARY}
          text={this.props.actionButtonText}
          onPress={onActionButtonPress}
        />
        <SecondaryButton
          type={ButtonTypes.SECONDARY}
          text={this.props.secondButtonText}
          onPress={this.props.onClose}
        />
      </Dialog>
    );
  }
}

export default PartnerActionDialog;
