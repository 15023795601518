export const legalNatureList = [
  {
    value: '201-1',
    label: '201-1 Empresa Pública',
  },
  {
    value: '203-8',
    label: '203-8 Sociedade de Economia Mista',
  },
  {
    value: '204-6',
    label: '204-6 Sociedade Anônima Aberta',
  },
  {
    value: '205-4',
    label: '205-4 Sociedade Anônima Fechada',
  },
  {
    value: '206-2',
    label: '206-2 Sociedade Empresária Limitada',
  },
  {
    value: '207-0',
    label: '207-0 Sociedade Empresária em Nome Coletivo',
  },
  {
    value: '208-9',
    label: '208-9 Sociedade Empresária em Comandita Simples',
  },
  {
    value: '209-7',
    label: '209-7 Sociedade Empresária em Comandita por Ações',
  },
  {
    value: '212-7',
    label: '212-7 Sociedade em Conta de Participação',
  },
  {
    value: '213-5',
    label: '213-5 Empresário (Individual)',
  },
  {
    value: '214-3',
    label: '214-3 Cooperativa',
  },
  {
    value: '215-1',
    label: '215-1 Consórcio de Sociedades',
  },
  {
    value: '216-0',
    label: '216-0 Grupo de Sociedades',
  },
  {
    value: '223-2',
    label: '223-2 Sociedade Simples Pura',
  },
  {
    value: '224-0',
    label: '224-0 Sociedade Simples Limitada',
  },
  {
    value: '225-9',
    label: '225-9 Sociedade Simples em Nome Coletivo',
  },
  {
    value: '226-7',
    label: '226-7 Sociedade Simples em Comandita Simples',
  },
  {
    value: '229-1',
    label: '229-1 Consórcio Simples',
  },
  {
    value: '230-5',
    label:
      '230-5 Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
  },
  {
    value: '231-3',
    label:
      '231-3 Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
  },
  {
    value: '232-1',
    label: '232-1 Sociedade Unipessoal de Advogados',
  },
  {
    value: '233-0',
    label: '233-0 Cooperativas de Consumo',
  },
  {
    value: '306-9',
    label: '306-9 Fundação Privada',
  },
  {
    value: '322-0',
    label: '322-0 Organização Religiosa',
  },
  {
    value: '330-1',
    label: '330-1 Organização Social (OS)',
  },
  {
    value: '399-9',
    label: '399-9 Associação Privada',
  },
  {
    value: '408-1',
    label: '408-1 Contribuinte Individual',
  },
];
