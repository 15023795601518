import React, {ReactElement} from 'react';
import ToggleSwitch from '@vizir-banking/banking-app-core/dist/layout/toggle-switch/toggle-switch';
import {Screen, ScreenTypes} from '@vizir-banking/banking-app-core/dist/common';

import translate from '~/i18n/translate';
import {
  Message,
  ToggleWrapper,
  ToggleLabel,
} from '~/settings/screens/sensor-settings/sensor-settings.styles';

import {Props} from './types';

export function SensorSettings({actions, sensorIsUsed}: Props): ReactElement {
  const handleToggleSwitch = (): void => {
    actions.setSensorIsUsed(!sensorIsUsed);
    actions.setUserDeclinedSensor(sensorIsUsed);
  };

  return (
    <Screen
      type={ScreenTypes.LOGGED}
      title={translate('settings.sensor.title')}
    >
      <Message>{translate('settings.sensor.message')}</Message>
      <ToggleWrapper>
        <ToggleLabel>{translate('settings.sensor.label')}</ToggleLabel>
        <ToggleSwitch onChangeValue={handleToggleSwitch} value={sensorIsUsed} />
      </ToggleWrapper>
    </Screen>
  );
}
