import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('ptBr', 'settings', {
  editProfile: {
    title: 'Dados pessoais',
    form: {
      name: 'Nome completo',
      phoneNumber: 'Telefone Celular',
      email: 'E-mail',
      save: 'Salvar',
    },
    successModal: {
      message: 'Perfil atualizado!',
      buttonLabels: {
        backToStart: 'voltar ao ínicio',
      },
    },
  },
  changeAppPassword: {
    title: 'Alterar Senha do App',
    header: 'Digite a senha atual, a nova senha e depois a confirme',
    form: {
      password: 'Nova Senha',
      confirmPassword: 'Confirmar Nova Senha',
      passwordInfo:
        'A senha deve conter pelo menos 8 caracteres e deve ser diferente da senha do cartão.',
      currentPassword: 'Senha Atual',
      continue: 'Continuar',
    },
    successModal: {
      message: 'Senha atualizada!',
      buttonLabels: {
        backToStart: 'voltar ao ínicio',
      },
    },
  },
  changeCardPassword: {
    title: 'Alterar Senha do Cartão',
    unblockCard: 'Desbloquear cartão',
  },
  incomeReport: {
    title: 'Informe de Rendimentos',
    header: 'Enviar por e-mail',
    description: 'Seu Informe de Rendimentos será enviado para:',
    confirm: 'Deseja confirmar o envio?',
    send: 'Enviar',
    cancel: 'Cancelar',
    successModal: {
      title: 'Sucesso!',
      message: 'Seu Informe de Rendimentos será enviado para:',
      button: 'Ok, Entendi',
    },
    unavailableReportModal: {
      title: 'Atenção',
      message:
        'Não há informe de rendimentos disponível para o último ano-base.',
    },
  },
  title: 'Perfil',
  rateApp: 'Avaliar o App',
  documents: {
    cpf: 'CPF',
    cnpj: 'CNPJ',
  },
  seeBankDataButton: 'Ver dados bancários',
  digitalAccount: 'Conta Digital',
  agency: 'Agência',
  bankAccount: 'Conta bancária',
  sensor: {
    title: 'Biometria',
    message:
      'Para acessar a sua conta usando o sistema de desbloqueio do celular:',
    label: 'Ativar para acessar a conta',
    errorTitle: 'Autenticação obrigatória',
    errorMessage:
      'Não foi possivel alterar sua senha, pedimos que repita o processo',
  },
  faq: {
    title: 'Ajuda',
  },
  termsOfUse: {
    title: 'Termos de Uso',
  },
  privacyPolicy: {
    title: 'Política de Privacidade',
  },
  logout: {
    title: 'Sair',
    confirmMessage: 'Tem certeza que deseja sair?',
    submit: 'Sim',
    cancel: 'Não',
  },
});
