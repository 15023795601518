import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import CompanyStatusScreen from '~/onboarding/screens/company-status/company-status';

import {CompanyStatus} from './types';

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  isLoading: boolean;
};

const mapStateToProps = ({application}: ReduxState): State => ({
  isLoading: application.isLoading,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

export const CompanyStatusContainer = (props: Props): ReactElement => {
  const onSubmit = (value: CompanyStatus): void => {
    props.actions.addCreationUser('companyStatus', value);
    props.navigateToNextOnboardingScreen();
  };

  return (
    <CompanyStatusScreen isLoading={props.isLoading} onSubmit={onSubmit} />
  );
};

const actionCreators = {addCreationUser};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    CompanyStatusContainer,
    ONBOARDING_SCREENS.companyStatus,
  ),
);
