import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('en', 'versionGate', {
  updateModal: {
    prompt: {
      title: 'Update available',
      message:
        'We have a new version of the application, we recommend that you update now.',
    },
    force: {
      title: 'Required update',
      message:
        'We have a new version of the application, update now to access your account again.',
    },
    buttonLabel: 'Update',
  },
});
