import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import getFirstName from '@vizir-banking/banking-app-core/dist/utils/first-name';
import {
  Bold,
  Text,
  MaskTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import translate from '~/i18n/translate';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const actionCreators = {addCreationUser};

type State = {
  name?: string;
  isCompanyFlow: boolean;
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  name: onboarding.creationUser.name,
  isCompanyFlow: onboarding.isCompanyFlow,
});

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

class BirthdayScreen extends React.PureComponent<Props> {
  submit = (value: string): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('birthday', value);

    navigateToNextOnboardingScreen();
  };

  renderText = (): ReactElement => {
    const {name = '', isCompanyFlow} = this.props;

    const firstName = getFirstName(name);
    const flowKey = isCompanyFlow ? 'companyFlow' : 'personFlow';

    const normal = translate(`onboarding.birthday.${flowKey}.title.normal`, {
      name: firstName,
    });

    const bold = translate(`onboarding.birthday.${flowKey}.title.bold`);

    const normal2 = translate(`onboarding.birthday.${flowKey}.title.normal2`, {
      name: firstName,
    });

    return (
      <>
        <Text>
          {normal}
          <Bold> {bold}</Bold>
          {isCompanyFlow && normal2}?
        </Text>
      </>
    );
  };

  render(): ReactElement {
    const {isCompanyFlow} = this.props;

    return (
      <OnboardingScreen showProgress>
        <SingleInputForm
          reduxValueKey="birthday"
          validationSchemaName={SchemaNames.BIRTHDATE}
          extraValidationData={{isCompanyFlow}}
          content={this.renderText}
          onSubmit={this.submit}
          textInputMask={MaskTypes.DATE}
          placeholder={translate('onboarding.datePlaceholder')}
        />
      </OnboardingScreen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(BirthdayScreen, ONBOARDING_SCREENS.birthday));
