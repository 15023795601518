import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('ptBr', 'app', {
  DefaultSuccessMessage: 'Requisição bem sucedida!',
  CameraTitlePermission: 'Permissão para usar a camera',
  FailedToTakePicture:
    'Não foi possível tirar a foto, por favor, verifique se a permissão de câmera foi aceita e tente novamente.',
  CameraMessagePermission:
    'Nós precisamos da sua permissão para acessar a sua camera',
  PositiveLabel: 'Ok',
  NegativeLabel: 'Cancel',
  AudioTitlePermission: 'Permissão para gravar áudio',
  AudioMessagePermission:
    'Nós precisamos da sua permissão para usar a gravação de áudio',
  decimalSeparator: ',',
  thousandsSeparator: '.',
  dialog: {close: 'Fechar'},
  moneyPrefix: 'R$',
  dateFormat: 'DD/MM/YYYY',
  yourBalance: 'Seu saldo',
  drawer: {
    aliasBank: {
      branch: 'Agência',
      account: 'Conta',
      bank: 'Banco',
    },
    digitalAccount: 'Conta Digital',
    statement: 'Extrato',
    transfer: 'Transferência bancária',
    payment: 'Pagamento de contas',
    depositBillet: 'Adicionar dinheiro',
    requestCard: 'Solicitação de cartão',
    unlockCard: 'Desbloqueio de cartão',
    myCard: 'Meu cartão',
    virtualCard: 'Cartão Virtual',
    myDetails: 'Perfil',
    terms: 'Termos e Condições',
    faq: 'Ajuda',
    logout: 'Sair',
    version: 'Versão',
  },
  shareTitle: 'Compartilhar',
  apiErrors: {
    GENERIC_ERROR: {
      title: 'Requisição não processada',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_USER_INVALID_PASSWORD: {
      title: 'Atenção',
      message: 'Usuário e/ou senha inválidos.',
    },
    BANK_API_USER_BLOCKED: {
      title: 'Credenciais bloqueadas',
      message:
        'Para desbloquear, fale com o suporte pelo telefone {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    BANK_API_UPDATE_PASSWORD_BLOCKED: {
      title: 'Senha não alterada',
      message:
        'Suas credenciais estão parcialmente bloqueadas. Para desbloquear, fale com o suporte pelo telefone {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    BANK_API_PERSON_NOT_FOUND: {
      title: 'Falha na requisição',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_COMPANY_NOT_FOUND: {
      title: 'Falha na requisição',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_CARD_CREATE_ERROR: {
      title: 'Requisição não processada',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_GET_TERMS_FAILED: {
      title: 'Atenção',
      message:
        'Houve um problema ao carregar os termos, por favor, tente novamente.',
    },
    BANK_API_ACCOUNT_NOT_FOUND: {
      title: 'Atenção',
      message: 'Conta de destino não encontrada',
    },
    BANK_API_SEND_DOCUMENTS_FAILED: {
      title: 'Atenção',
      message:
        'Houve um problema no envio dos documentos, por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_CARD_MAX_PASSWORD_RETRIES: {
      title: 'Temos um problema',
      message:
        'Foi excedido o número máximo de tentativas de senha do seu cartão. Por favor, entre em contato com nosso suporte.',
    },
    BANK_API_UNAUTHORIZED: {
      title: 'Atenção',
      message:
        'Sua sessão expirou, por favor, refaça o login e tente novamente.',
    },
    BANK_API_CARD_BLOCKED: {
      title: 'Cartão bloqueado',
      message: 'Por favor, entre em contato com nosso suporte',
    },
    BANK_API_CARD_DOESNT_BELONG_TO_ACCOUNT: {
      title: 'Atenção',
      message:
        'O cartão não pertence à conta atual, por favor, refaça o login e tente novamente.',
    },
    BANK_API_CARD_INVALID_PASSWORD: {
      title: 'Cuidado',
      message: 'A senha informada para o cartão é inválida.',
    },
    BANK_API_CARD_QUANTITY_EXCEEDED: {
      title: 'Atenção',
      message: 'Ops, você excedeu limite de cartões virtuais por mês',
    },
    BANK_API_COMPANY_WAITING_PARTNERS_REGISTRY: {
      title: 'Cadastro incompleto',
      message: 'Estamos aguardando o cadastro dos sócios.',
    },
    BANK_API_ACCOUNT_WAITING_ANALYSIS: {
      title: 'Conta em análise',
      message: 'Entraremos em contato quando o processo for finalizado.',
    },
    BANK_API_ACCOUNT_REJECTED: {
      title: 'Conta não aprovada',
      message:
        'Por favor, entre em contato com a central através do número {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}.',
    },
    BANK_API_CPF_ALREADY_TAKEN: {
      title: 'Tente novamente',
      message: 'Já existe um cadastro com o documento informado.',
    },
    BANK_API_PASSWORD_FAILED_TO_DECRYPT: {
      title: 'Tente novamente',
      message:
        "Houve um problema ao processar sua requisição, por favor, tente novamente ou entre em contato com nosso suporte.'",
    },
    BANK_API_PERSON_TELEPHONE_NOT_FOUND: {
      title: 'Telefone incorreto',
      message:
        'O telefone não pertence à conta atual, por favor, refaça o login e tente novamente.',
    },
    BANK_API_PHONE_DOESNT_BELONG_TO_ACCOUNT: {
      title: 'Telefone incorreto',
      message:
        'O telefone não pertence à conta atual, por favor, refaça o login e tente novamente.',
    },
    BANK_API_SESSION_EXPIRED: {
      title: 'Sessão expirada',
      message: 'Por favor, refaça o login e tente novamente.',
    },
    BANK_API_ONBOARDING_SESSION_EXPIRED: {
      title: 'Sessão expirada',
      message: 'Comece novamente a criação da sua conta.',
    },
    BANK_API_SMS_FAILED: {
      title: 'Código não enviado',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_VERIFICATION_CODE_IS_INVALID: {
      title: 'Atenção',
      message: 'O código de verificação informado é inválido.',
    },
    USER_CREATION_FAILED: {
      title: 'Atenção',
      message:
        'Erro ao finalizar o cadastro. Por favor, tente novamente ou entre em contato com o suporte.',
    },
    BANK_API_BILLET_ALREADY_PAID: {
      title: 'Atenção',
      message: 'Este boleto já foi pago',
    },
    BANK_API_BILLET_DATE_LIMIT_EXCEEDED: {
      title: 'Boleto vencido',
      message: 'Este boleto não pode mais ser pago',
    },
    BANK_API_BILLET_EXPIRED: {
      title: 'Atenção',
      message: 'Boleto expirado',
    },
    BANK_API_BILLET_INVALID: {
      title: 'Atenção',
      message: 'O boleto não foi reconhecido ou já foi pago',
    },
    BANK_API_BILLET_PAYMENT_FAILED: {
      title: 'Transação incompleta',
      message:
        'Houve um problema ao processar seu pagamento. Verifique se seu boleto não está vencido. Lembre-se que pagamentos de boletos bancários só são permitidos das 7h às 20h30 e, contas e tributos das 7h às 16h30. Ambos de segunda a sexta-feira (exceto feriados)',
    },
    BANK_API_BILLET_PAYMENT_TIME_NOT_ALLOWED: {
      title: 'Pagamento solicitado',
      message:
        'O horário autorizado para pagamento deste boleto é das {{minTime}} às {{maxTime}}, de segunda a sexta-feira (exceto feriados). Você pode pagar depois ou agendar o pagamento.',
    },
    BANK_API_BILLET_BILL_PAYMENT_TYPE_ID_UNKNOWN: {
      title: 'Tipo de boleto desconhecido',
      message:
        'O tipo do boleto não foi reconhecido, tente novamente ou entre em contato com o suporte.',
    },
    BANK_API_CHECK_PASSWORD_FAILED: {
      title: 'Atenção',
      message: 'Senha atual incorreta.',
    },
    BANK_API_UPDATE_PASSWORD_FAILED: {
      title: 'Senha não alterada',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte',
    },
    BANK_API_RESET_PASSWORD_FAILED: {
      title: 'Senha não recuperada',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte',
    },
    BANK_API_USER_DOCUMENT_ALREADY_EXISTS: {
      title: 'Atenção',
      message: 'Usuario já está cadastrado.',
    },
    BANK_API_INVALID_RECIPIENT_ACCOUNT: {
      title: 'Atenção',
      message:
        'Não conseguimos encontrar a conta destino. Por favor verifique e tente novamente.',
    },
    BANK_API_TRANSFER_FAILED: {
      title: 'Saldo insuficiente',
      message: 'Além do valor transferido, não esqueça da tarifa de TED.',
    },
    BANK_API_BILLET_GENERATION_FAILED: {
      title: 'Pedimos desculpa',
      message:
        'Ocorreu um problema ao solicitar a geração de seu boleto. Por favor, tente novamente mais tarde.',
    },
    BANK_API_USER_INVALID_DOCUMENT: {
      title: 'Atenção',
      message: 'Documento inválido.',
    },
    BANK_API_USER_NOT_REGULAR_DOCUMENT: {
      title: 'Atenção',
      message: 'Documento inválido.',
    },
    BANK_API_USER_NAME_NOT_MATCH: {
      title: 'Atenção',
      message: 'O nome foi digitado errado.',
    },
    BANK_API_USER_NAME_INVALID_LENGT: {
      title: 'Atenção',
      message: 'O nome digitado não deve ter mais que 100 caracteres',
    },
    BANK_API_COMPANY_FANTASY_NAME_INVALID_LENGTH: {
      title: 'Atenção',
      message: 'O nome fantasia digitado não deve ter mais que 50 caracteres',
    },
    BANK_API_USER_MOTHER_NAME_NOT_MATCH: {
      title: 'Atenção',
      message: 'Nome da mãe digitado errado.',
    },
    BANK_API_USER_BIRTH_NOT_MATCH: {
      title: 'Atenção',
      message: 'Data de nascimento incorreta.',
    },
    BANK_API_USER_NOT_ALLOW_INFORMED_DOCUMENT: {
      title: 'Atenção',
      message: 'Documento inválido.',
    },
    BANK_API_USER_BLACK_LIST: {
      title: 'Atenção',
      message: 'Número do documento está na lista negra.',
    },
    BANK_API_UNKNOWN_USER_DATA: {
      title: 'Atenção',
      message: 'Houve um erro desconhecido ao processar os seus dados',
    },
    BANK_API_COMPANY_INVALID_DATA: {
      title: 'Atenção',
      message: 'Encontramos dados inválidos no seu cadastro',
    },
    invalid_recipient: {
      title: 'Pagamento não realizado',
      message: 'Este boleto não pode ser pago pela Wallet Lucree.',
    },
    contact_not_found: {
      title: 'Atenção',
      message: 'O contato não encontrado.',
    },
    bank_transfer_fail: {
      title: 'Atenção',
      message:
        'Não foi possível realizar a transferência. Tente novamente mais tarde.',
    },
    billet_payment_failed: {
      title: 'Pagamento não realizado',
      message: 'Houve um erro desconhecido ao pagar o boleto',
    },
    billet_invalid_bank: {
      title: 'Ops, não posso avançar!',
      message:
        'Infelizmente por enquanto não realizamos o pagamento de boletos desta instituição.',
    },
    billet_invalid_barcode: {
      title: 'Ops tem algo estranho!',
      message:
        'Parece que o número do boleto não está correto. Consegue verificar novamente antes de tentar avançar?',
    },
    billet_already_paid: {
      title: 'Ops, não posso avançar!',
      message:
        'Parece que esse boleto já foi pago. Não quer verificar antes de tentar de novo?',
    },
    billet_invalid_or_already_paid: {
      title: 'Ops, tem algo estranho!',
      message:
        'Parece que esse boleto já foi pago ou não é válido. Não quer verificar antes de tentar de novo?',
    },
    cannot_update_password_blocked_by_password: {
      title: 'Atenção',
      message:
        'Por motivo de segurança, o status do seu cartão não permite que essa operação seja concluída. Se deseja regularizar sua situação, por favor entre em contato.',
    },
    card_blocked_incorrect_password: {
      title: 'Atenção',
      message:
        'Por motivo de segurança, o status do seu cartão não permite que essa operação seja concluída. Se deseja regularizar sua situação, por favor entre em contato.',
    },
    card_for_password_validation_not_found: {
      title: 'Atenção',
      message:
        'Infelizmente não é possível realizar operações sem possuir pelo menos um cartão associado com a sua conta. Para regularizar sua situação, solicite seu cartão ou crie um cartão virtual. Se não for possível, por favor entre em contato.',
    },
    invalid_amount_to_be_financed: {
      title: 'Pagamento não realizado',
      message:
        'Pagamentos abaixo de R$20 não poderão ser realizados via Wallet',
    },
    invalid_cvv: {
      title: 'Dados incorretos',
      message:
        'Verifique se está digitando os números impressos no seu cartão.',
    },
    invalid_password: {
      title: 'Cuidado',
      message:
        'A senha está incorreta. Você tem até 3 tentativas. Após isso, seu cartão será bloqueado.',
    },
    internal_error: {
      title: 'Atenção',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    destination_phone_not_found: {
      title: 'Atenção',
      message:
        'Não encontramos nenhum número de celular para o documento informado',
    },
    destination_account_not_found: {
      title: 'Atenção',
      message:
        'A conta de destino não foi encontrada. Por favor verifique se inseriu corretamente o número.',
    },
    destination_account_inactive: {
      title: 'Atenção',
      message:
        'Não é possível realizar a transferência para essa conta no momento. Por favor, tente mais tarde ou confirme com o portador se ela está ativa.',
    },
    destination_account_is_the_same_account: {
      title: 'Ops, tem algo errado',
      message: 'Você inseriu o número da sua própria conta.',
    },
    update_password_blocked: {
      title: 'Senha não alterada',
      message:
        'Suas credenciais estão parcialmente bloqueadas. Para desbloquear, fale com o suporte pelo telefone {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    current_credential_invalid: {
      title: 'Atenção',
      message: 'Senha atual incorreta',
    },
    p2p_transfer_fail: {
      title: 'Atenção',
      message:
        'Não foi possível realizar a transferência. Tente novamente mais tarde.',
    },
    proposal_in_progress: {
      title: 'Atenção',
      message: 'Já existe uma solicitação em andamento.',
    },
    recent_rejection: {
      title: 'Atenção',
      message: 'Ainda não é possível iniciar uma nova solicitação de crédito.',
    },
    payment_authorization_failed: {
      title: 'Atenção',
      message: 'Ops, falha no processamento, tente novamente mais tarde.',
    },
    payment_failed: {
      title: 'Atenção',
      message: 'Ops, falha no processamento, tente novamente mais tarde.',
    },
    verification_code_is_invalid: {
      title: 'Atenção',
      message: 'O código de verificação informado está incorreto ou expirado',
    },
    wallet_card_not_allowed: {
      title: 'Atenção',
      message: 'Cartão não permitido.',
    },
    wallet_transaction_denied: {
      title: 'Atenção',
      message:
        'Houve um problema ao processar sua requisição. Por favor, verifique seu cvv e tente novamente.',
    },
    wallet_invalid_payment_time_window: {
      title: 'Atenção',
      message:
        'O horário autorizado para pagamento deste boleto é das 7H às 18:30H, de segunda a sexta-feira (exceto feriados).',
    },
    card_validation_failed: {
      title: 'Valor não correspondido',
      message:
        'O valor que você inseriu não corresponde com a cobrança que enviamos e não pudemos verificar seu cartão.',
    },
    validation_max_retries_exceeded: {
      title: 'Atenção',
      message: 'Foi excedido o número máximo de tentativas.',
    },
    UNKNOWN_API_ERROR: {
      title: 'Requisição não processada',
      message:
        'Por favor, tente novamente ou entre em contato com nosso suporte.',
    },
    BANK_API_CARD_VALIDATE_CVV_ERROR: {
      title: 'Dados incorretos',
      message:
        'Verifique se está digitando os números impressos no seu cartão.',
    },
    BANK_API_CARD_UNBLOCK_ERROR: {
      title: 'Atenção',
      message:
        'Por motivo de segurança, o status do seu cartão não permite que essa operação seja concluída. Se deseja regularizar sua situação, por favor entre em contato.',
    },
    BANK_API_LACK_OF_REGULATORY_INFORMATION: {
      title: 'Transação não permitida',
      message:
        'Existem documentos pendentes no cadastro, por favor entre em contato com o suporte pelo 0000-0000.',
    },
    BANK_API_USER_DOCUMENT_NOT_AUTHORIZED_ON_WHITELIST: {
      title: 'Acesso restrito',
      message: 'O seu CPF/CNPJ não está autorizado a criar uma conta.',
    },
  },
  requestSuccessDialogTitle: 'Sucesso!',
  genericErrorTitle: 'Atenção',
  genericErrorMessage:
    'Por favor, tente novamente ou entre em contato com nosso suporte.',
  customerName: '{{env.REACT_APP_CUSTOMER_NAME}}',
  contactEmail: '{{env.REACT_APP_CUSTOMER_CONTACT_EMAIL}}',
  contactPhone: '{{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
  button: {
    continue: 'Continuar',
  },
  codePushDialog: {
    title: 'Atualização',
    descriptionPrefix: 'Descrição',
    mandatoryUpdateMessage: 'Nova versão do app disponível',
    mandatoryContinueButtonLabel: 'Instalar',
    optionalUpdateMessage: 'Nova versão do app disponível',
    optionalIgnoreButtonLabel: 'Cancelar',
    optionalInstallButtonLabel: 'Instalar',
  },
  resetScreens: {
    appPassword: {
      smsCode: {
        invalidCode: 'Código de validação inválido',
        resendSms: 'Enviar novamente',
        sendedSms: 'Sms enviado',
        smsCodeTitle:
          'Enviamos um código de validação via SMS para você. Preencha-o abaixo:',
        smsSubmissionFailed: 'Falha no envio do sms',
      },
    },
  },
  creditCard: {
    defaultCardHolderNamePlaceholder: 'Nome e Sobrenome',
    defaultCardExpiryMonthPlaceholder: 'MM',
    defaultCardExpiryYearPlaceholder: 'AA',
    defaultMainCardPlaceholder: '(Principal)',
    validateCard: 'Clique para verificar seu cartão',
    options: 'Opções',
  },
  verificationMinValue: '0,50',
  verificationMaxValue: '10,00',
});
