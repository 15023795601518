import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';

import * as env from '~/env'; //eslint-disable-line import/no-namespace
import appDetails from '~/utils/app-details/app-details';
import {deviceKeyAdapter} from '~/providers/device-key-adapter/device-key-adapter';

const headers: Record<string, string> = {};

headers['x-vizir-banking-app-name'] = appDetails.name;
headers['x-vizir-banking-app-platform'] = appDetails.platform;

if (appDetails.buildNumber !== null) {
  headers['x-vizir-banking-build-number'] = appDetails.buildNumber;
}

if (appDetails.version !== null) {
  headers['x-vizir-banking-version'] = appDetails.version;
}

const bankingCoreHttpClient = new BankingCoreHttpClient(
  env.REACT_APP_API_URL,
  deviceKeyAdapter,
);

bankingCoreHttpClient.addDefaultHeaders(headers);

export {bankingCoreHttpClient};
