import styled from 'styled-components';
import {
  View,
  Text,
  Button as BaseButton,
  ButtonTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

import {Title as SharedTitle} from '~/onboarding/components/shared-styles/shared-styles';

export const Title = styled(SharedTitle)`
  font-family: ${useTheme(({fonts}) => fonts.primary.weight.normal)};
  font-size: 25px;
  margin-bottom: 60px;
`;

export const TextWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
  padding: 26px 0px;
`;

export const Message = styled(Text)`
  margin-top: 25px;
  font-size: 18px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;

export const SecondaryButton = styled(BaseButton).attrs({
  type: ButtonTypes.TRANSPARENT,
})`
  margin-top: 29px;
`;
