import styled from 'styled-components';
import {
  Form as BaseForm,
  View,
  Text,
} from '@vizir-banking/banking-app-core/dist/layout';
import {FormField as BaseFormField} from '@vizir-banking/banking-app-core/dist/common';
import {ifStyle} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

const hasPaddingRight = ifStyle('hasPaddingRight');
const isUpperCase = ifStyle('isUpperCase');

type extraFormFieldProps = {
  hasPaddingRight?: boolean;
  isUpperCase?: boolean;
};

export const FormField = styled(BaseFormField)<extraFormFieldProps>`
  ${hasPaddingRight('padding-right: 15px;')}
  ${isUpperCase('& input { text-transform: uppercase; }')}
`;

export const Form = styled(BaseForm)`
  flex-grow: 1;
`;

export const Wrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const LabelInfo = styled(Text)`
  font-size: 14px;
`;

export const LabelWrapper = styled(View)`
  margin-bottom: 20px;
`;
