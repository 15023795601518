import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('en', 'home', {
  Welcome: 'Welcome',
  SignUp: 'Sign Up',
  LogIn: 'Log In',
  wallet: 'Wallet',
  transfer: 'Transfer',
  payment: 'Payment',
  statement: 'Statement',
  depositBillet: 'Add money',
  assignCard: 'Assign card',
  requestCard: 'Request card',
  unlockCard: 'Unlock card',
  myCard: 'My card',
  virtualCard: 'Virtual card',
  myDetails: 'Profile',
  faq: 'Faq',
  restrictionsDialog: {
    default: {
      title: 'Restricted access',
      message:
        'Your access is restricted due to pending information on your registration, call our call center {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}} and inform the SPD status: {{restrictions}}',
    },
  },
  restrictionCard: {
    deviceKey: {
      title: 'Pending Authorization',
      text:
        'Release your account to perform transactions. Click and learn more',
    },
    spd: {
      title: 'Invalid Documentation',
      text:
        'Validate your documents to continue to use the entire application.',
    },
  },
});
