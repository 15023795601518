import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import isNil from 'lodash/isNil';
import {Bold} from '@vizir-banking/banking-app-core/dist/layout';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';

import {
  Props,
  State,
} from '~/onboarding/screens/politically-expose-person/types';
import {
  RadioGroup,
  Button,
  Title,
  FieldWrapper,
  Wrapper,
  InfoText,
} from '~/onboarding/screens/politically-expose-person/politically-exposed-peson.styles';
import translate from '~/i18n/translate';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

class PoliticallyExposedPersonScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {isPEP: props.isPEP, hasError: false};
  }

  isPEPNull = (): boolean => {
    return isNil(this.state.isPEP);
  };

  handleSubmit = (): void => {
    if (this.isPEPNull()) {
      return this.setState({hasError: true});
    }

    const formValues = {
      isPEP: this.state.isPEP!,
    };

    return this.props.onSubmit(formValues);
  };

  changeIsPEP = (value: boolean): void => {
    this.setState({isPEP: value, hasError: false});
  };

  renderText = (): ReactElement => {
    return (
      <Title>
        {translate('onboarding.PEP.title.normal')}
        <Bold> {translate('onboarding.PEP.title.bold')}</Bold>?
      </Title>
    );
  };

  render(): ReactElement {
    const {isPEP} = this.state;

    return (
      <OnboardingScreen showProgress>
        <Wrapper>
          <FieldWrapper>
            {this.renderText()}
            <RadioGroup
              error={translate('onboarding.PEP.required')}
              initialValue={isPEP}
              hasError={this.state.hasError}
              onChangeValue={this.changeIsPEP}
              radioOptions={[
                {label: translate('onboarding.PEP.yes'), value: true},
                {label: translate('onboarding.PEP.no'), value: false},
              ]}
            />
            <InfoText
              title={translate('onboarding.PEP.infoText.button')}
              text={translate('onboarding.PEP.infoText.info')}
            />
          </FieldWrapper>
          <Button
            text={translate('onboarding.PEP.continue')}
            onPress={this.handleSubmit}
          />
        </Wrapper>
      </OnboardingScreen>
    );
  }
}

type StateType = {
  birthday?: Date;
};

const mapStateToProps = ({onboarding}: ReduxState): StateType => ({
  birthday: onboarding.creationUser.birthday,
});

export default connect(mapStateToProps)(PoliticallyExposedPersonScreen);
