import React, {ReactElement} from 'react';
import {Button} from '@vizir-banking/banking-app-core/dist/layout';
import {
  Camera,
  RequestErrorDialog,
} from '@vizir-banking/banking-app-core/dist/common';
import {File} from '@vizir-banking/banking-app-core/dist/api/user/types';

import translate from '~/i18n/translate';
import {PhotoDocumentPreviewProps} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-preview/types';
import {DismissButton} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-tips-modal/photo-document-tips-modal.styles';
import {
  Title,
  Message,
  SubTitle,
  ImageWrapper,
  SecondaryButton,
} from '~/onboarding/screens/send-document/base-screens/shared.styles';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

type Props = PhotoDocumentPreviewProps & {
  hasHints?: boolean;
  onPhotoCaptured?: (file: File) => void;
};

class PhotoDocumentPreview extends React.PureComponent<Props> {
  getMessage = (): string | ReactElement => {
    const {message = ''} = this.props;

    return typeof message === 'string' ? message : message();
  };

  renderTitle(): ReactElement | undefined {
    if (!this.props.title) return;

    return <Title>{this.props.title}</Title>;
  }

  renderSubtitle(): ReactElement | undefined {
    if (!this.props.subTitle) return;

    return <SubTitle>{this.props.subTitle}</SubTitle>;
  }

  renderMessage(): ReactElement {
    return <Message lessMarginTop>{this.getMessage()}</Message>;
  }

  renderSubMessage(): ReactElement | undefined {
    if (!this.props.subMessage) return;

    return <Message lessMarginTop>{this.props.subMessage}</Message>;
  }

  renderSendLaterButton(): ReactElement | undefined {
    if (!this.props.onTransparentButtonPress) return;

    return (
      <SecondaryButton
        text={translate(
          'onboarding.sendDocuments.photoDocument.transparentButton',
        )}
        onPress={this.props.onTransparentButtonPress}
      />
    );
  }

  renderCameraPiker(): ReactElement {
    return (
      <Camera
        hasNavbar
        showCamera={true}
        onPhotoCaptured={this.props.onPhotoCaptured}
      >
        <DismissButton
          text={translate(
            'onboarding.sendDocuments.photoDocument.tips.buttonLabel',
          )}
        />
      </Camera>
    );
  }

  renderButton(): ReactElement {
    if (!this.props.hasHints) {
      return this.renderCameraPiker();
    }

    return (
      <Button
        disabled={!this.props.documentType}
        text={this.props.primaryButtonText}
        onPress={this.props.onPrimaryButtonPress}
      />
    );
  }

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        <RequestErrorDialog />
        {this.renderTitle()}
        {this.renderSubtitle()}
        {this.renderMessage()}
        {this.renderSubMessage()}
        <ImageWrapper>{this.props.children}</ImageWrapper>
        {this.renderButton()}
        {this.renderSendLaterButton()}
      </OnboardingScreen>
    );
  }
}

export default PhotoDocumentPreview;
