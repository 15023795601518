import React, {ReactElement} from 'react';
import {RequestErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {Bold, Button} from '@vizir-banking/banking-app-core/dist/layout';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import {
  Title,
  TextWrapper,
  Message,
  SecondaryButton,
} from '~/onboarding/screens/necessary-documents/necessary-documents.styles';

type Props = OnboardingNavigationProps;

class NecessaryDocumentsScreen extends React.PureComponent<Props> {
  goToNextScreen = (): void => {
    const {navigateToNextOnboardingScreen} = this.props;
    navigateToNextOnboardingScreen();
  };

  goToAccountCreated = (): void => {
    const {navigate} = this.props;
    navigate(ONBOARDING_SCREENS.accountCreating);
  };

  handleClose = (): void => {
    this.setState({showDialog: false});
  };

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        <RequestErrorDialog />
        <TextWrapper>
          <Title>
            {translate('onboarding.WeNeedSome')}
            <Bold>{translate('onboarding.documents.documentsLabel')}</Bold>
            {translate('onboarding.ToValidadeYourSignUp')}
          </Title>
          <Message>{translate('onboarding.validPartnersDocs')}</Message>
          <Message>{translate('onboarding.ProofOfResidence')}</Message>
          <Message>{translate('onboarding.ArticlesOfAssociation')}</Message>
        </TextWrapper>
        <Button
          text={translate('onboarding.SendNow')}
          onPress={this.goToNextScreen}
        />
        <SecondaryButton
          text={translate('onboarding.SendLater')}
          onPress={this.goToAccountCreated}
        />
      </OnboardingScreen>
    );
  }
}

export default withOnboardingNavigation(
  NecessaryDocumentsScreen,
  ONBOARDING_SCREENS.necessaryPartnerDocuments,
);
