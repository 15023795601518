import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import {
  Icon,
  Bold,
  Spinner,
  ButtonTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Types} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import ApiError from '@vizir-banking/banking-app-core/dist/errors/api-error';
import removeSpecialChars from '@vizir-banking/banking-app-core/dist/utils/remove-special-chars';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {
  User,
  ValueOf,
} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {authorization} from '@vizir-banking/banking-app-core/dist/device-key/authorization';
import {clearRequestError} from '@vizir-banking/banking-app-core/dist/redux/application';

import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  Message,
  Title,
  TitleWrapper,
  BottomBar,
  Button,
  Screen,
  Wrapper,
} from '~/onboarding/screens/validate-document-photos/validate-document-photos.styles';

const translateForScreen = (keys: string | string[]): string => {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  return translate(keys.map((key) => `onboarding.validateDocuments.${key}`));
};

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

class ValidateDocumentPhotos extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  state = {error: undefined};

  private get applicationContext(): ApplicationContextType {
    return this.context as ApplicationContextType;
  }

  private validateDocumentPhotos = async (): Promise<void> => {
    const {creationUser} = this.props;
    const {deviceKeyAdapter} = this.applicationContext;

    if (await deviceKeyAdapter.canBeUsedByUser(creationUser.documentNumber)) {
      await this.authorizeDeviceKey();
      return;
    }

    this.props.navigateToNextOnboardingScreen();
  };

  private authorizeDeviceKey = async (): Promise<void> => {
    const {creationUser} = this.props;
    const {bankingCoreHttpClient, deviceKeyAdapter} = this.applicationContext;
    const documentNumber = removeSpecialChars(creationUser.documentNumber);
    const documents = creationUser.documents?.map((document) => ({
      url: document.url,
      downloadUrl: document.url,
      documentType: document.documentType,
    }));

    try {
      await authorization.authorizeWithSelfieAndDocument({
        deviceKey: deviceKeyAdapter,
        httpClient: bankingCoreHttpClient,
        documents: documents ?? [],
        userUniqueKey: documentNumber,
        userInfo: {
          userFullName: creationUser.name ?? '',
          userBirthdate: creationUser.birthday
            ? moment(creationUser.birthday, 'DD/MM/YYYY').toDate()
            : new Date(),
        },
      });

      this.props.navigateToNextOnboardingScreen();
    } catch (error) {
      this.setState({error});
    }
  };

  private clearDocumentsAndRequestError = (): void => {
    this.props.actions.clearRequestError();
    this.props.actions.addCreationUser('documents', []);
  };

  private navigateToDocumentPhotos = (): void => {
    this.clearDocumentsAndRequestError();
    this.props.navigateToDocumentUploadScreen();
  };

  private navigateToOnboardingStart = (): void => {
    this.clearDocumentsAndRequestError();
    this.props.navigateToFirstOnboardingScreen();
  };

  private renderErrorContent = (): React.ReactElement => {
    const errorCode = get(this.state, 'error.code');

    return (
      <TitleWrapper>
        <Icon name="exclamation_icon" size={90} />

        <Title>
          {translateForScreen([
            `errors.${errorCode}.title`,
            'errors.default.title',
          ])}
        </Title>
        <Message>
          {translateForScreen([
            `errors.${errorCode}.message`,
            'errors.default.message',
          ])}
        </Message>

        <Button
          text={translateForScreen('retryNewPhotos')}
          onPress={this.navigateToDocumentPhotos}
        />
        <Button
          type={ButtonTypes.TRANSPARENT}
          text={translateForScreen('reviewFromStart')}
          onPress={this.navigateToOnboardingStart}
        />
      </TitleWrapper>
    );
  };

  private renderLoadingContent = (): React.ReactElement => {
    return (
      <TitleWrapper>
        <Spinner isLoading={true} size={100} />

        <Title>{translateForScreen('loading.title')}</Title>
        <Message>{translateForScreen('loading.message')}</Message>
        <Message>
          <Bold>{translateForScreen('loading.subMessage')}</Bold>
        </Message>
      </TitleWrapper>
    );
  };

  private renderContent = (): ReactElement => {
    if (this.state.error) {
      return this.renderErrorContent();
    }

    return this.renderLoadingContent();
  };

  componentDidMount(): void {
    this.validateDocumentPhotos().catch((error) => {
      this.setState({error});
    });
  }

  render(): ReactElement {
    return (
      <Screen navType={Types.NONE}>
        <Wrapper>{this.renderContent()}</Wrapper>
        <BottomBar />
      </Screen>
    );
  }
}

export type Actions = {
  clearRequestError: () => void;
  addCreationUser: (fieldName: keyof User, value: ValueOf<User>) => void;
};

type State = {
  error?: ApiError;
  creationUser: Partial<User>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(
    {
      clearRequestError,
      addCreationUser,
    },
    dispatch,
  ),
});

const mapStateToProps = ({application, onboarding}: ReduxState): State => ({
  error: application.error as ApiError,
  creationUser: onboarding.creationUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    ValidateDocumentPhotos,
    ONBOARDING_SCREENS.validateDocumentPhotos,
  ),
);
