import React, {ReactElement} from 'react';
import {Bold, Spinner} from '@vizir-banking/banking-app-core/dist/layout';

import translate from '~/i18n/translate';
import {
  Title,
  Message,
  TitleWrapper,
} from '~/onboarding/screens/account-creating/account-creating.styles';

const Loading = (): ReactElement => {
  return (
    <TitleWrapper>
      <Spinner isLoading={true} size={100} />

      <Title>
        {translate('onboarding.accountCreating.sendingYourInformation')}
      </Title>
      <Message>
        {translate(
          'onboarding.accountCreating.youWillBeRedirectedToTheConfirmationScreen',
        )}
      </Message>
      <Message>
        <Bold>{translate('onboarding.accountCreating.doNotCloseTheApp')}</Bold>
      </Message>
    </TitleWrapper>
  );
};

export default Loading;
