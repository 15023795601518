import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('ptBr', 'home', {
  Welcome: 'Bem Vindo',
  signUp: 'Cadastre-se',
  logIn: 'Já possuo uma conta',
  wallet: 'Wallet',
  transfer: 'Transferir',
  payment: 'Pagar Contas',
  statement: 'Extrato',
  editProfile: 'Editar Perfil',
  depositBillet: 'Adicionar dinheiro',
  assignCard: 'Cadastrar cartão',
  requestCard: 'Solicitar cartão',
  unlockCard: 'Desbloquear cartão',
  myCard: 'Meu cartão',
  virtualCard: 'Cartão virtual',
  myDetails: 'Perfil',
  faq: 'Ajuda',
  restrictionsDialog: {
    default: {
      title: 'Restrições de uso',
      message:
        'Existem pendências com sua documentação, para resolver entre em contato com nossa central no {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}} e informe o código de status SPD: {{restrictions}}',
    },
  },
  restrictionCard: {
    deviceKey: {
      title: 'Autorização pendente',
      text: 'Libere a sua conta para realizar operações. Clique e saiba mais',
    },
    spd: {
      title: 'Documentação inválida',
      text:
        'Para continuar tendo acesso a todo o aplicativo, valide seus documentos.',
    },
  },
});
