import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
  RequestErrorDialog,
  HtmlView,
} from '@vizir-banking/banking-app-core/dist/common';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {
  getTermsConditions,
  clearTerms,
} from '@vizir-banking/banking-app-core/dist/redux/application/terms-conditions';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {
  Terms,
  Term,
} from '@vizir-banking/banking-app-core/dist/api/terms/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {Button} from '~/onboarding/components/shared-styles/shared-styles';
import translate from '~/i18n/translate';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import {
  ScrollView,
  Title,
} from '~/onboarding/screens/terms-conditions/terms-conditions.styles';

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

class TermsConditionsScreen extends React.PureComponent<Props> {
  static contextType = ApplicationContext;
  state = {
    isButtonDisabled: false,
  };

  componentDidMount(): void {
    const context = this.context as ApplicationContextType;

    this.props.actions.getTermsConditions(context.bankingCoreHttpClient);
  }

  handleScrollEnd = (): void => {
    this.setState({isButtonDisabled: false});
  };

  submit = (): void => {
    const {
      actions,
      navigateToNextOnboardingScreen,
      termsConditions,
    } = this.props;

    actions.addCreationUser('terms', termsConditions.terms);

    navigateToNextOnboardingScreen();
  };

  rejectTerms = (): void => {
    const {actions, navigateToFirstOnboardingScreen} = this.props;

    actions.clearTerms();
    navigateToFirstOnboardingScreen();
  };

  render(): ReactElement {
    const {termsConditions} = this.props;

    return (
      <OnboardingScreen showProgress>
        <RequestErrorDialog />
        <Title>{translate('onboarding.TermsAndConditions')}</Title>
        <ScrollView
          scrollEnabled
          showsVerticalScrollIndicator
          scrollEventThrottle={16}
          onEndReach={this.handleScrollEnd}
        >
          <HtmlView htmlContent={termsConditions.description} />
        </ScrollView>
        <Button
          disabled={this.state.isButtonDisabled || !termsConditions.description}
          text={translate('onboarding.Accept')}
          onPress={this.submit}
        />
        <Button
          type={ButtonTypes.TRANSPARENT}
          text={translate('onboarding.Decline')}
          onPress={this.rejectTerms}
        />
      </OnboardingScreen>
    );
  }
}

const actionCreators = {getTermsConditions, clearTerms, addCreationUser};

type State = {
  termsConditions: Terms;
};

const mapStateToProps = ({application}: ReduxState): State => ({
  termsConditions: application.termsConditions || {},
});

type Actions = {
  getTermsConditions: (httpClient: BankingCoreHttpClient) => void;
  clearTerms: () => void;
  addCreationUser: (fieldName: keyof User, value: Term[]) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    TermsConditionsScreen,
    ONBOARDING_SCREENS.termsConditions,
  ),
);
