import {ApplicationContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import React, {PropsWithChildren, ReactElement} from 'react';
import {ThemeProvider} from 'styled-components';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {connect} from 'react-redux';

import * as env from '~/env'; //eslint-disable-line import/no-namespace
import {NavigationAdapter} from '~/providers/application/navigation-adapter';
import {theme} from '~/theme/theme';
import {bankingCoreHttpClient} from '~/providers/banking-core-http-client-factory';
import {deviceKeyAdapter} from '~/providers/device-key-adapter/device-key-adapter';
import {notificationAdapter} from '~/providers/notification-adapter/notification-adapter';
import {databaseAdapter} from '~/providers/database-adapter/database-adapter';
import {featureToggleAdapter} from '~/providers/feature-toggle-adapter/feature-toggle-adapter';

type Props = {
  balance?: number;
};

class BaseApplicationProvider extends React.PureComponent<
  PropsWithChildren<Props>
> {
  componentWillUnmount(): void {
    databaseAdapter.closeConnection();
  }

  render(): ReactElement {
    return (
      <ApplicationContext.Provider
        value={{
          theme,
          bankingCoreHttpClient,
          env,
          navigationAdapter: NavigationAdapter,
          notificationAdapter,
          deviceKeyAdapter,
          databaseConnection: databaseAdapter,
          featureToggleAdapter,
          balance: this.props.balance,
        }}
      >
        <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
      </ApplicationContext.Provider>
    );
  }
}

export const mapStateToProps = ({user}: ReduxState): Props => ({
  balance: user.currentUser.balance,
});

export const ApplicationProvider = connect(mapStateToProps)(
  BaseApplicationProvider,
);
