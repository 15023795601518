import styled from 'styled-components';
import {
  View,
  Text,
  Bold,
  Icon,
  TextInput,
  Button as BaseButton,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const FormTitle = styled(Text)`
  font-size: 25px;
  margin: 20px 0;
  text-align: center;
`;

export const Title = styled(Bold)`
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
  font-size: ${useTheme(({sizes}) => sizes.titleSize)}px;
`;

export const ErrorText = styled(Text)`
  color: ${useTheme(({colors}) => colors.error.primary)};
`;

export const SuccessText = styled(Text)`
  color: ${useTheme(({colors}) => colors.success)};
`;

export const TextCentered = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Button = styled(BaseButton)`
  width: 100%;
  margin-top: 20px;
`;

export const DefaultInputForm = styled(TextInput)`
  text-align: ${(props): string => props.align || 'left'};
  padding-left: 0;
  margin-top: 10px;
`;

export const PaddingContainer = styled(View)`
  padding: 20px;
`;

export const PaddingView = styled(View)`
  padding: 20px;
`;

export const CenteredContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const InputContainer = styled(View)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
`;

export const PasswordIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 35px;
`;

export const Center = styled(View)`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;
