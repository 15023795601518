import React, {ReactElement} from 'react';
import WarningCard from '@vizir-banking/banking-app-core/dist/common/warning-card/warning-card';
import {useApplicationFromContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {RestrictionTypes} from '@vizir-banking/banking-app-core/dist/redux/application/types';
import DeviceScreens from '@vizir-banking/banking-app-core-plugins/dist/device-key/navigation/screen-definitions';
import SpdScreens from '@vizir-banking/banking-app-core-plugins/dist/document-validation/navigation/screen-definitions';

import translate from '~/i18n/translate';

import {Props} from './types';

const RestrictionCard = ({
  restrictionType,
  navigate,
}: Props): ReactElement | null => {
  const {env} = useApplicationFromContext();
  const defaultIcon = {
    size: 15,
    name: 'arrow2',
  };

  const renderSpdRestrictionCard = (): ReactElement | null => {
    if (!env.REACT_APP_ENABLE_SPD_RESTRICTION) {
      return null;
    }

    const handleOnPress = (): void => {
      navigate(SpdScreens.invalidDocument);
    };

    return (
      <WarningCard
        title={translate('home.restrictionCard.spd.title')}
        text={translate('home.restrictionCard.spd.text')}
        onPress={handleOnPress}
        icon={defaultIcon}
      />
    );
  };

  const renderDeviceKeyRestrictionCard = (): ReactElement | null => {
    if (!env.REACT_APP_ENABLE_DEVICE_KEY) {
      return null;
    }

    const handleOnPress = (): void => {
      navigate(DeviceScreens.validationWarning);
    };

    return (
      <WarningCard
        title={translate('home.restrictionCard.deviceKey.title')}
        text={translate('home.restrictionCard.deviceKey.text')}
        onPress={handleOnPress}
        icon={defaultIcon}
      />
    );
  };

  const RestrictionsCards = {
    [RestrictionTypes.SPD_BLOCKED]: renderSpdRestrictionCard,
    [RestrictionTypes.DEVICE_KEY_INVALID]: renderDeviceKeyRestrictionCard,
  };

  return RestrictionsCards[restrictionType]();
};

export default RestrictionCard;
