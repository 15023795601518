import Yup from '@vizir-banking/banking-app-core/dist/utils/yup/yup';
import {
  requiredField,
  unmatchedPassword,
} from '@vizir-banking/banking-app-core/dist/utils/validation-messages';

import {
  REACT_APP_NUMERIC_APP_PASSWORD,
  REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD,
} from '~/env';

const minCaracter = 'Senhas devem conter no minimo 8 caracteres.';
const numericMinCaracter = 'Senhas devem conter no minimo 6 caracteres.';
const numericPasswordLength = 'As senhas devem conter 6 dígitos';
const onlyNumbers = 'A senha deve conter somente números.';

const alphanumericPasswordSchema = Yup.object({
  currentPassword: Yup.string().min(8, minCaracter).required(requiredField),
  password: Yup.string().min(8, minCaracter).required(requiredField),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], unmatchedPassword)
    .required(requiredField),
});

const numericPasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .min(6, numericMinCaracter)
    .required(requiredField),
  password: Yup.string()
    .noSequentialNumbers(REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD)
    .noAllRepeatedChars(REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD)
    .length(6, numericPasswordLength)
    .matches(/^[0-9]+$/, onlyNumbers)
    .required(requiredField),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], unmatchedPassword)
    .required(requiredField),
});

const schema = REACT_APP_NUMERIC_APP_PASSWORD
  ? numericPasswordSchema
  : alphanumericPasswordSchema;

export default schema;
