import React, {ReactElement} from 'react';
import {
  Text,
  Button,
  MaskTypes,
} from '@vizir-banking/banking-app-core/dist/layout';

import translate from '~/i18n/translate';
import {FormTitle} from '~/onboarding/components/shared-styles/shared-styles';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import SCHEMA from '~/onboarding/screens/identity/identity-form.schema';

import {
  Form,
  FormField,
  Wrapper,
  LabelInfo,
  LabelWrapper,
} from './identity.styles';
import {Props} from './types';

const defaultIdentity = {
  rg: '',
  rgIssuingDate: '',
};

const IdentityScreen = ({
  identity = defaultIdentity,
  onSubmit,
}: Props): ReactElement => {
  const renderIdentityLabel = (): ReactElement => {
    return (
      <LabelWrapper>
        <Text>{translate('onboarding.identity.RG')}</Text>
        <LabelInfo>{translate('onboarding.identity.RGInfo')}</LabelInfo>
      </LabelWrapper>
    );
  };

  const renderIssuingDateLabel = (): ReactElement => {
    return (
      <LabelWrapper>
        <Text>{translate('onboarding.identity.issuanceDate')}</Text>
        <LabelInfo>
          {translate('onboarding.identity.issuanceDateInfo')}
        </LabelInfo>
      </LabelWrapper>
    );
  };

  return (
    <OnboardingScreen showProgress>
      <Form schema={SCHEMA} onSubmit={onSubmit} initialValues={identity}>
        <Wrapper>
          <FormTitle>
            <Text>{translate('onboarding.identity.title')}</Text>
          </FormTitle>
          <FormField
            autoFocus
            name="rg"
            label={renderIdentityLabel}
            maxLength={18}
            returnKeyType="next"
          />
          <FormField
            name="rgIssuingDate"
            label={renderIssuingDateLabel}
            mask={MaskTypes.DATE}
            placeholder={translate('onboarding.datePlaceholder')}
          />
        </Wrapper>
        <Button isSubmit text={translate('onboarding.Continue')} />
      </Form>
    </OnboardingScreen>
  );
};

export default IdentityScreen;
