import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {Document as DocumentType} from '@vizir-banking/banking-app-core/dist/entities/user/document';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import t from '~/i18n/translate';
import {Image} from '~/onboarding/screens/send-document/base-screens/shared.styles';
import image from '~/assets/images/document_back.png';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import DocumentComponent from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document';

const translate = (key: string): string => t(`onboarding.sendDocuments.${key}`);

type Props = ReturnType<typeof mapStateToProps> & OnboardingNavigationProps;

class PhotoIdentificationDocumentBack extends React.PureComponent<Props> {
  tips = [
    {
      iconName: 'plastic_cnh',
      iconSize: 42,
      tip: translate('photoDocument.tips.document.plastic'),
    },
    {
      iconName: 'rne_photo',
      iconSize: 39,
      tip: translate('photoDocument.tips.document.frame'),
    },
    {
      iconName: 'rne',
      iconSize: 42,
      tip: translate('photoDocument.tips.document.surface'),
    },
    {
      iconName: 'light_fixture',
      iconSize: 44,
      tip: translate('photoDocument.tips.document.lights'),
    },
  ];

  onConfirmPicture = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  getIdentificationDocument = (): DocumentType | undefined => {
    const {documents} = this.props;

    if (!documents) return;

    return documents.find((document) =>
      document.documentType.includes('FRONT'),
    );
  };

  getDocumentType = (): DocumentTypes | undefined => {
    const identificationDocument = this.getIdentificationDocument();

    return identificationDocument
      ? (identificationDocument.documentType.replace(
          'FRONT',
          'VERSE',
        ) as DocumentTypes)
      : undefined;
  };

  renderImage = (): ReactElement => {
    return <Image source={image} />;
  };

  render(): ReactElement {
    return (
      <DocumentComponent
        hasHints
        hasChangeCamera
        tips={this.tips}
        documentType={this.getDocumentType()}
        documentPreviewSubMessage={translate('photoMainDocumentBack.title')}
        documentPreviewMessage={translate('photoMainDocumentBack.message')}
        documentPreviewChildren={this.renderImage()}
        onConfirmPicture={this.onConfirmPicture}
        documentPreviewTitle={''}
      />
    );
  }
}

type State = {
  documents?: DocumentType[];
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  documents: onboarding.creationUser.documents,
});

export default connect(
  mapStateToProps,
  null,
)(
  withOnboardingNavigation(
    PhotoIdentificationDocumentBack,
    ONBOARDING_SCREENS.photoIdentificationDocumentBack,
  ),
);
