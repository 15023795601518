import styled from 'styled-components';
import {
  Bold,
  Button,
  Text,
  View,
} from '@vizir-banking/banking-app-core/dist/layout';

export const Wrapper = styled(View)`
  flex-grow: 1;
  justify-content: space-between;
`;

export const Header = styled(Bold)`
  font-size: 18px;
`;

export const Description = styled(Text)`
  margin-top: 18px;
`;

export const Email = styled(Bold)`
  margin-top: 12px;
`;

export const Confirm = styled(Text)`
  margin-top: 12px;
`;

export const CancelButton = styled(Button)`
  margin-top: 12px;
`;
