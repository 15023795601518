import React, {ReactElement} from 'react';
import {FormFieldTypes} from '@vizir-banking/banking-app-core/dist/common';
import {Bold, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';

import {CompanyStatusSelectOptions} from '~/onboarding/screens/company-status/options';
import translate from '~/i18n/translate';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

import {CompanyStatus, Props} from './types';

class CompanyStatusScreen extends React.PureComponent<Props> {
  renderText = (): ReactElement => {
    return (
      <Text>
        {translate('onboarding.companyStatus.title.normal')}
        <Bold>{translate('onboarding.companyStatus.title.bold')}</Bold>
        {translate('onboarding.companyStatus.title.normal2')}
      </Text>
    );
  };

  handleSubmit = (value: CompanyStatus): void => {
    this.props.onSubmit(value);
  };

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress isCompanyFlow>
        <SingleInputForm
          reduxValueKey="companyStatus"
          validationSchemaName={SchemaNames.COMPANY_STATUS}
          content={this.renderText}
          onSubmit={this.handleSubmit}
          formFieldType={FormFieldTypes.SELECT}
          modalTitle={translate('onboarding.companyStatus.select.placeHolder')}
          placeholder={translate('onboarding.companyStatus.select.placeHolder')}
          options={CompanyStatusSelectOptions()}
        />
      </OnboardingScreen>
    );
  }
}

export default CompanyStatusScreen;
