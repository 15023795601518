import styled from 'styled-components';
import {View, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  height: 15px;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const Label = styled(Text)`
  font-size: 13px;
  margin-left: 10px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;
