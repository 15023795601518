import styled from 'styled-components';
import {
  View,
  Text,
  ShareableView,
} from '@vizir-banking/banking-app-core/dist/layout';
import BaseList from '@vizir-banking/banking-app-core/dist/layout/list/list';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const ProfileInfo = styled(ShareableView)`
  align-items: center;
  background-color: ${useTheme(({colors}) => colors.screenBackground)};
`;

export const UserName = styled(Text)`
  margin-top: 15px;
  font-size: 18px;
  color: ${useTheme(({colors}) => colors.label.primary)};
`;

export const UserDocument = styled(Text)`
  font-size: 13px;
  color: ${useTheme(({colors}) => colors.label.secondary)};
  margin-top: 5px;
`;

export const ButtonWrapper = styled(View)`
  margin-top: 30px;
  justify-content: flex-end;
`;

export const AccountWrapper = styled(View)`
  margin-top: 15px;
  background-color: ${useTheme(({colors}) => colors.screenBackground)};
`;

export const AccountInfo = styled(Text)`
  color: ${useTheme(({colors}) => colors.textOverPrimary)};
  font-size: 18px;
  background-color: ${useTheme(({colors}) => colors.screenBackground)};
`;

export const List = styled(BaseList)`
  border: 0;
  border-style: solid;
  border-top-width: 1px;
  border-top-color: ${useTheme(({colors}) => colors.border.secondary)};
  margin-top: 30px;
  background-color: ${useTheme(({colors}) => colors.screenBackground)};
`;
