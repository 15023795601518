import Yup from '@vizir-banking/banking-app-core/dist/utils/yup/yup';
import {
  requiredField,
  unmatchedPassword,
} from '@vizir-banking/banking-app-core/dist/utils/validation-messages';

import {REACT_APP_NUMERIC_APP_PASSWORD} from '~/env';

const numericPasswordSchema = Yup.string()
  .length(6, 'As senhas devem conter 6 dígitos.')
  .matches(/^[0-9]+$/, 'A senha deve conter somente números.')
  .required(requiredField);

const alphanumericPasswordSchema = Yup.string()
  .min(8, 'Senhas devem conter no minimo 8 caracteres.')
  .required(requiredField);

const passwordSchema = REACT_APP_NUMERIC_APP_PASSWORD
  ? numericPasswordSchema
  : alphanumericPasswordSchema;

const schema = Yup.object({
  password: passwordSchema,
  newPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], unmatchedPassword)
    .required(requiredField),
});

export default schema;
