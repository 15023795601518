import styled from 'styled-components';
import {View} from '@vizir-banking/banking-app-core/dist/layout';
import AppVersion from '@vizir-banking/banking-app-core/dist/common/app-version/app-version';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const CardsWrapper = styled(View)`
  flex-wrap: wrap;
  flex-direction: row;
  background-color: transparent;
  justify-content: space-between;
`;

export const Version = styled(AppVersion)`
  color: ${useTheme(({colors}) => colors.textSecondary)};
  text-align: center;
`;
