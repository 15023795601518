import React, {ReactElement} from 'react';
import {Camera} from '@vizir-banking/banking-app-core/dist/common';

import translate from '~/i18n/translate';
import PhotoTipsModal from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-tips-modal/photo-document-tips-modal';
import {
  PhotoDocumentCameraProps,
  PhotoDocumentCameraState,
} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/types';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import PhotoDocumentImageTipsModal from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-image-tips-modal/photo-document-image-tips-modal';
import {CheckPhotoDocument} from '~/onboarding/screens/send-document/base-screens/photo-document/check-photo-document';
import {DismissButton} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-tips-modal/photo-document-tips-modal.styles';

class PhotoDocumentCamera extends React.PureComponent<
  PhotoDocumentCameraProps,
  PhotoDocumentCameraState
> {
  constructor(props: PhotoDocumentCameraProps) {
    super(props);
    const showTips = CheckPhotoDocument.isDocumentFrontValid(
      this.props.documentType,
    );
    const showDocumentImageTips = CheckPhotoDocument.isVerse(
      this.props.documentType,
    );

    this.state = {
      showTips: showTips,
      showDocumentImageTips: showDocumentImageTips,
    };
  }

  handleOnPress = (): void => {
    if (CheckPhotoDocument.isFront(this.props.documentType!)) {
      this.setState({showTips: false, showDocumentImageTips: true});
      return;
    }

    this.setState({showTips: false, showDocumentImageTips: false});
  };

  handleCloseDocumentImageTips = (): void => {
    this.setState({showTips: true, showDocumentImageTips: false});
  };

  isRenderDismissButton = (): boolean => {
    return (
      this.state.showTips &&
      CheckPhotoDocument.isFront(this.props.documentType!)
    );
  };

  renderPhotoTipsModal = (): ReactElement | undefined => {
    if (!this.props.tips) return;
    if (!this.state.showTips) return;

    return (
      <PhotoTipsModal
        show={this.state.showTips}
        tips={this.props.tips}
        onClose={this.props.onClose}
      >
        {this.renderCamera()}
      </PhotoTipsModal>
    );
  };

  renderDocumentImagesTipsModal = (): ReactElement | undefined => {
    if (!this.props.documentType) return;
    if (!this.state.showDocumentImageTips) return;

    return (
      <PhotoDocumentImageTipsModal
        show={this.state.showDocumentImageTips}
        onClose={this.handleCloseDocumentImageTips}
        documentType={this.props.documentType}
      >
        {this.renderCamera()}
      </PhotoDocumentImageTipsModal>
    );
  };

  renderCamera = (): ReactElement | undefined => {
    if (this.isRenderDismissButton()) {
      return (
        <DismissButton
          text={translate(
            'onboarding.sendDocuments.photoDocument.tips.buttonLabel',
          )}
          onPress={this.handleOnPress}
        />
      );
    }

    return (
      <Camera
        hasNavbar
        showCamera={true}
        onPhotoCaptured={this.props.onPhotoCaptured}
      >
        <DismissButton
          text={translate(
            'onboarding.sendDocuments.photoDocument.tips.buttonLabel',
          )}
        />
      </Camera>
    );
  };

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        {this.renderPhotoTipsModal()}
        {this.renderDocumentImagesTipsModal()}
      </OnboardingScreen>
    );
  }
}

export default PhotoDocumentCamera;
