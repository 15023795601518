import styled from 'styled-components';
import {
  View,
  Icon,
  Text,
  TextInput as BaseTextInput,
  Form as BaseForm,
} from '@vizir-banking/banking-app-core/dist/layout';
import BaseFormField from '@vizir-banking/banking-app-core/dist/common/form-field/form-field';
import {Input} from '@vizir-banking/banking-app-core/dist/common/text-input/text-input.styles';
import {
  useTheme,
  ifStyle,
} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

const hasMarginTop = ifStyle('hasMarginTop');

type extraFieldWrapperProps = {
  hasMarginTop?: boolean;
};

export const Form = styled(BaseForm)`
  flex-grow: 1;
  margin-top: 30px;
  justify-content: center;
`;

export const FormField = styled(BaseFormField).attrs({
  textInputStyle: {textAlign: 'center'},
})`
  ${Input} {
    text-align: center;
  }
`;

export const FieldsWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 30px;
`;

export const InfoWrapper = styled(View)`
  flex-direction: row;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
`;

export const InfoIcon = styled(Icon).attrs(({theme}: {theme: Theme}) => ({
  name: 'info',
  size: 14,
  color: theme.colors.icons.info.primary,
}))`
  margin-right: 10px;
`;

export const InfoText = styled(Text)`
  font-size: 13px;
  color: ${useTheme(({colors}) => colors.info.tertiary)};
  width: 90%;
`;

export const TextInput = styled(BaseTextInput)`
  text-align: center;
`;

export const FieldWrapper = styled(View)<extraFieldWrapperProps>`
  ${hasMarginTop('margin-top: 50px;')}
`;
