import EventEmitter, {
  Events,
} from '@vizir-banking/banking-app-core/dist/utils/event-emitter/event-emitter';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {UnauthorizedError} from '@vizir-banking/banking-app-core/dist/errors/unauthorized-error';

import {Actions, Listener} from '~/navigation/listeners/listener';

export class UnauthorizedListener implements Listener {
  public constructor(
    private readonly actions: Actions,
    private readonly handleUnauthorized: () => void,
    private httpClient: BankingCoreHttpClient,
  ) {
    this.onUnauthorized = this.onUnauthorized.bind(this);
  }

  private onUnauthorized(): void {
    this.actions.logout(this.httpClient);
    this.actions.setShowRequestErrorDialog(true);
    this.actions.setRequestError(new UnauthorizedError());
    this.httpClient.clearAuthorization();

    this.handleUnauthorized();
  }

  public setListener(): void {
    EventEmitter.on(Events.RECEIVED_HTTP_UNAUTHORIZED, this.onUnauthorized);
  }

  public unsetListener(): void {
    EventEmitter.off(Events.RECEIVED_HTTP_UNAUTHORIZED, this.onUnauthorized);
  }

  public setHttpClient(httpClient: BankingCoreHttpClient): void {
    this.httpClient = httpClient;
  }
}
