import {getVersions} from '@vizir-banking/banking-app-core/dist/redux/application/get-versions';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {bindActionCreators, Dispatch} from 'redux';
import {Versions} from '@vizir-banking/banking-app-core/dist/entities/application/versions';

type VersionGateState = {
  versions?: Versions;
};

export interface Actions {
  getVersions: (httpClient: BankingCoreHttpClient) => void;
}

const actionCreators = {
  getVersions,
};

export const mapStateToProps = ({
  application,
}: ReduxState): VersionGateState => ({
  versions: application.versions,
});

export const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export type VersionGateProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;
