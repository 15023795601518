import Dialog from '@vizir-banking/banking-app-core/dist/layout/dialog/dialog';
import openUrl from '@vizir-banking/banking-app-core/dist/utils/open-url';
import React, {ReactElement} from 'react';
import isEmpty from 'lodash/isEmpty';

import translate from '~/i18n/translate';
import appDetails from '~/utils/app-details/app-details';
import {
  Message,
  RedirectButton,
} from '~/version-gate/update-modal/update-modal.styles';
import {
  UpdateModalProps,
  UpdateType,
} from '~/version-gate/update-modal/update-modal.type';

export class UpdateModal extends React.Component<UpdateModalProps> {
  state = {
    showModal: true,
  };

  private onClose = (): void => {
    this.setState({showModal: false});
  };

  private navigateToStore(): void {
    if (isEmpty(appDetails.storeUrl)) {
      return;
    }

    openUrl(appDetails.storeUrl!);
  }

  public render(): ReactElement {
    const {updateType} = this.props;

    return (
      <Dialog
        show={this.state.showModal}
        title={translate(`versionGate.updateModal.${updateType}.title`)}
        onClose={this.onClose}
        withRouter={false}
        showAdditionalCloseButton={updateType === UpdateType.prompt}
      >
        <Message>
          {translate(`versionGate.updateModal.${updateType}.message`)}
        </Message>
        <RedirectButton
          text={translate('versionGate.updateModal.buttonLabel')}
          onPress={this.navigateToStore}
        />
      </Dialog>
    );
  }
}
