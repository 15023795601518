import React, {ReactElement} from 'react';
import {Br} from '@vizir-banking/banking-app-core/dist/layout';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {
  Screen,
  Content,
  Title,
  Message,
  Bold,
  Continue,
} from '~/onboarding/screens/mei-info/mei-info.styles';

const MeiInfo = (props: OnboardingNavigationProps): ReactElement => {
  const onPress = (): void => {
    props.navigateToFirstOnboardingScreen();
  };

  return (
    <Screen>
      <Content>
        <Title>{translate('onboarding.meiInfo.title')}</Title>
        <Message>
          {translate('onboarding.meiInfo.almostThere')}
          <Br />
          {translate('onboarding.meiInfo.messageStart')}
          <Bold>{translate('onboarding.meiInfo.naturalPerson')}</Bold>
          {translate('onboarding.meiInfo.messageEnd')}
        </Message>
      </Content>
      <Continue text={translate('onboarding.Continue')} onPress={onPress} />
    </Screen>
  );
};

export default withOnboardingNavigation(MeiInfo, ONBOARDING_SCREENS.meiInfo);
