import React from 'react';
import {connect} from 'react-redux';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {REACT_APP_BETA_ACCESS_KEY} from '~/env';
import {
  navigationDefinitions,
  NavigationDefinitions,
  mapStateToProps,
} from '~/onboarding/navigation/navigation-definitions';

export type OnboardingNavigationProps = NavigationProps & {
  navigateToNextOnboardingScreen: () => void;
  navigateToFirstOnboardingScreen: () => void;
  navigateToDocumentUploadScreen: () => void;
  getFirstOnboardingScreenName: () => string;
};

export const resolveNavigationScreens = (
  props: ReturnType<typeof mapStateToProps>,
): string[] => {
  const screens: string[] = [];

  const resolver = (definitions: NavigationDefinitions): void => {
    definitions.forEach((definition) => {
      if (typeof definition === 'string') {
        screens.push(definition);
        return;
      }

      const resolved = definition(props);

      if (typeof resolved === 'string') {
        screens.push(resolved);
        return;
      }

      resolver(resolved);
    });
  };

  resolver(navigationDefinitions);

  return screens;
};

export const getNavigationProgress = (
  props: ReturnType<typeof mapStateToProps>,
  currentScreen: string,
): number => {
  const screens = resolveNavigationScreens(props);
  const routePosition = screens.indexOf(currentScreen);

  return (100 / screens.length) * routePosition;
};

type InternalProps = OnboardingNavigationProps &
  ReturnType<typeof mapStateToProps>;

export function withOnboardingNavigation<Props>(
  Component: React.ComponentType<Props & OnboardingNavigationProps>,
  currentScreen: string,
): React.ComponentType<Omit<Props, keyof InternalProps>> {
  class OnboardingNavigation extends React.Component<Props & InternalProps> {
    getFirstOnboardingScreenName = (): string => {
      return REACT_APP_BETA_ACCESS_KEY
        ? ONBOARDING_SCREENS.accessKey
        : ONBOARDING_SCREENS.phoneNumber;
    };

    navigateToDocumentUploadScreen = (): void => {
      if (!this.props.isCompanyFlow) {
        this.props.navigate(ONBOARDING_SCREENS.photoIdentificationDocument);
        return;
      }

      if (this.props.isMei) {
        // MEI companies don't have to upload documents, this screen is just a placeholder
        this.props.navigate(ONBOARDING_SCREENS.meiDocument);
        return;
      }

      this.props.navigate(ONBOARDING_SCREENS.attachSocialContract);
    };

    navigateToFirstScreen = (): void => {
      const firstScreen = this.getFirstOnboardingScreenName();

      this.props.navigate(firstScreen);
    };

    navigateToNextScreen = (): void => {
      const screens = resolveNavigationScreens(this.props);
      const indexOfCurrentScreen = screens.indexOf(currentScreen);

      if (indexOfCurrentScreen < 0) {
        throw new Error(
          `Couldn't find the current screen ${currentScreen} on screens ${screens}`,
        );
      }

      const nextScreen = screens[indexOfCurrentScreen + 1];

      if (!nextScreen) {
        return;
      }

      this.props.navigate(nextScreen);
    };

    render(): React.ReactElement {
      return (
        <Component
          {...this.props}
          navigateToNextOnboardingScreen={this.navigateToNextScreen}
          navigateToFirstOnboardingScreen={this.navigateToFirstScreen}
          navigateToDocumentUploadScreen={this.navigateToDocumentUploadScreen}
          getFirstOnboardingScreenName={this.getFirstOnboardingScreenName}
        />
      );
    }
  }

  // @ts-ignore
  return connect(mapStateToProps)(withNavigation(OnboardingNavigation));
}
