import styled from 'styled-components';
import {
  Image,
  View,
  Text,
  Button,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Navbar as BaseNavbar} from '@vizir-banking/banking-app-core/dist/layout/navbar/navbar';
import {ifStyle} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import {Screen as BaseScreen} from '@vizir-banking/banking-app-core/dist/common';

import {ExtraButtonProps} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-confirm/types';
import {
  ScreenStyles,
  ContainerStyles,
  ImagePreviewStyles,
  ActionAreaStyles,
  ConfirmationTextStyles,
  ButtonsWrapperStyles,
  ActionButtonStyles,
  BaseNavbarStyle,
} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-confirm/photo-document-confirm.styles.base';

const hasMarginRight = ifStyle('hasMarginRight');

export const Screen = styled(BaseScreen)`
  ${ScreenStyles};
`;

export const Container = styled(View)`
  ${ContainerStyles};
`;

export const ImagePreview = styled(Image)`
  ${ImagePreviewStyles};
  object-fit: contain;
`;

export const ActionArea = styled(View)`
  ${ActionAreaStyles};
`;

export const ConfirmationText = styled(Text)`
  ${ConfirmationTextStyles};
`;

export const ButtonsWrapper = styled(View)`
  ${ButtonsWrapperStyles};
`;

export const Navbar = styled(BaseNavbar)`
  ${BaseNavbarStyle};
`;

export const ActionButton = styled(Button)<ExtraButtonProps>`
  ${ActionButtonStyles};
  margin-right: ${hasMarginRight('10px', '0')};
`;
