import UserEntity from '@vizir-banking/banking-app-core/dist/entities/user/user';
import EventEmitter, {
  Events,
} from '@vizir-banking/banking-app-core/dist/utils/event-emitter/event-emitter';
import crashlytics from '@vizir-banking/banking-app-core/dist/utils/firebase/crashlytics';
import get from 'lodash/get';

import {Listener} from '~/navigation/listeners/listener';

export class LoginListener implements Listener {
  public constructor(private currentUser: Partial<UserEntity>) {
    this.onLogin = this.onLogin.bind(this);
  }

  private onLogin(): void {
    this.crashlyticsTracking();
  }

  private crashlyticsTracking(): void {
    const personId = get(this.currentUser.personIds, '[0]', '');
    const accountId = get(this.currentUser.accountIds, '[0]', '');

    if (this.currentUser) {
      crashlytics.setAttributes({
        email: String(this.currentUser.email),
        accountId: String(accountId),
        personId: String(personId),
      });
    }
  }

  public setListener(): void {
    EventEmitter.on(Events.LOGIN, this.onLogin);
  }

  public unsetListener(): void {
    EventEmitter.off(Events.LOGIN, this.onLogin);
  }

  public setCurrentUser(currentUser: Partial<UserEntity>): void {
    this.currentUser = currentUser;
    this.crashlyticsTracking();
  }
}
