import styled from 'styled-components';
import {
  View,
  Text,
  Bold as BaseBold,
  Button,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Screen as BaseScreen} from '@vizir-banking/banking-app-core/dist/common';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Screen = styled(BaseScreen)``;

export const Content = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const Title = styled(Text)`
  font-size: ${useTheme(({sizes}) => sizes.titleSize)}px;
  font-family: ${useTheme(({fonts}) => fonts.primary.weight.bold)};
  color: ${useTheme(({colors}) => colors.textTertiary)};
  text-align: center;
  margin-bottom: 40px;
`;

export const Message = styled(Text)`
  font-size: 20px;
  color: ${useTheme(({colors}) => colors.textTertiary)};
  text-align: center;
`;

export const Bold = styled(BaseBold)``;

export const Continue = styled(Button)``;
