import styled from 'styled-components';
import {FormField as BaseFormField} from '@vizir-banking/banking-app-core/dist/common';
import {Form as BaseForm} from '@vizir-banking/banking-app-core/dist/layout';

export const FormField = styled(BaseFormField).attrs({
  textInputStyle: {
    fontSize: 18,
  },
})`
  width: 100%;
  margin-bottom: 15px;
`;

export const Form = styled(BaseForm)`
  flex: 1;
`;
