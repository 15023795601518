import {createGlobalStyle} from 'styled-components';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${useTheme(
      ({fonts}) => fonts.primary.weight.normal,
    )}, 'Roboto', sans-serif;
  }

  body {
    background-color: ${useTheme(({colors}) => colors.screenBackground)};
    overflow: hidden;
  }

  html,
  body,
  #root,
  #drawer-root
  {
    width: 100%;
    height: 100%;
  }

  #drawer-root {
    position: absolute;
    max-width: 786px;
    transform: translateX(-100%);
    transition: transform 0.5s;
  }

  #drawer-root.drawer--open {
    z-index: 2;
    transform: translateX(0);
  }

  #drawer-root.drawer--open + #root{
    transform: translateX(95%);
  }

  #root {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
    max-width: 786px;
    margin: 0 auto;
    position: relative;
    transition: transform 0.5s;
  }

  @font-face {
    font-family: Acre-Medium;
    src: local('Acre-Medium'),
      url('/fonts/Acre-Medium.woff2') format('woff2'),
      url('/fonts/Acre-Medium.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Regular;
    src: local('Acre-Regular'),
      url('/fonts/Acre-Regular.woff2') format('woff2'),
      url('/fonts/Acre-Regular.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Extrabold;
    src: local('Acre-Extrabold'),
      url('/fonts/Acre-Extrabold.woff2') format('woff2'),
      url('/fonts/Acre-Extrabold.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Black;
    src: local('Acre-Black'),
      url('/fonts/Acre-Black.woff2') format('woff2'),
      url('/fonts/Acre-Black.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Bold;
    src: local('Acre-Bold'),
      url('/fonts/Acre-Bold.woff2') format('woff2'),
      url('/fonts/Acre-Bold.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Thin;
    src: local('Acre-Thin'),
      url('/fonts/Acre-Thin.woff2') format('woff2'),
      url('/fonts/Acre-Thin.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Light;
    src: local('Acre-Light'),
      url('/fonts/Acre-Light.woff2') format('woff2'),
      url('/fonts/Acre-Light.woff') format('woff');
  }

  @font-face {
    font-family: Acre-Semibold;
    src: local('Acre-Semibold'),
      url('/fonts/Acre-Semibold.woff2') format('woff2'),
      url('/fonts/Acre-Semibold.woff') format('woff');
  }
`;
