import React, {useState, useEffect, useCallback} from 'react';
import {ErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {connect} from 'react-redux';
import RestrictionCollection from '@vizir-banking/banking-app-core/dist/entities/restriction-collection';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';

import translate from '~/i18n/translate';

type State = {
  restrictions?: RestrictionCollection;
};

const mapStateToProps = ({user: {currentUser}}: ReduxState): State => ({
  restrictions: currentUser.restrictions,
});

type Props = ReturnType<typeof mapStateToProps>;

const RestrictionModal: React.FC<Props> = ({restrictions}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const title = translate('home.restrictionsDialog.default.title');
  const message = (): string =>
    translate('home.restrictionsDialog.default.message', {
      restrictions: restrictions?.toString(),
    });

  const showDialog = useCallback(() => {
    if (restrictions?.hasSimpleRestrictions()) {
      setIsDialogOpen(true);
    }
  }, [restrictions]);

  const hideDialog = (): void => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    showDialog();
  }, [showDialog]);

  return (
    <ErrorDialog
      title={title}
      message={message()}
      iconName="alert"
      show={isDialogOpen}
      onClose={hideDialog}
    />
  );
};

export default connect(mapStateToProps)(RestrictionModal);
