import React, {ReactElement} from 'react';
import {
  Screen,
  ScreenTypes,
  RequestErrorDialog,
  RequestSuccessDialog,
} from '@vizir-banking/banking-app-core/dist/common';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';

import {HOME_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';

import Form from './form';

class EditProfile extends React.PureComponent<NavigationProps> {
  goToMyDetails = (): void => {
    const {reset} = this.props;

    reset(HOME_SCREENS.home);
  };

  render(): ReactElement {
    const requestSuccessDialogButtons = [
      {
        label: translate(
          'settings.editProfile.successModal.buttonLabels.backToStart',
        ),
        action: this.goToMyDetails,
      },
    ];
    return (
      <Screen
        type={ScreenTypes.LOGGED}
        title={translate('settings.editProfile.title')}
      >
        <RequestSuccessDialog buttons={requestSuccessDialogButtons} />
        <RequestErrorDialog />
        <Form />
      </Screen>
    );
  }
}

export default withNavigation(EditProfile);
