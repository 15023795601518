import React, {ReactElement} from 'react';
import {Touchable} from '@vizir-banking/banking-app-core/dist/layout';
import Checkbox from '@vizir-banking/banking-app-core/dist/layout/checkbox/checkbox';

import {
  Wrapper,
  Label,
} from '~/onboarding/components/check-box/check-box.styles';

type Props = {
  text: string;
  checkboxStyle?: object;
  checked: boolean;
  onChangeValue: (checked: boolean) => void;
};

const CheckBox = ({
  checked,
  text,
  checkboxStyle,
  onChangeValue,
}: Props): ReactElement => (
  <Wrapper>
    <Checkbox
      value={checked}
      onChangeValue={onChangeValue}
      checkboxStyle={checkboxStyle}
    />
    <Touchable onPress={(): void => onChangeValue(!checked)}>
      <Label>{text}</Label>
    </Touchable>
  </Wrapper>
);

export default CheckBox;
