import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
  Screen,
  ScreenTypes,
  RequestErrorDialog,
  RequestSuccessDialog,
} from '@vizir-banking/banking-app-core/dist/common';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {
  setShowRequestErrorDialog,
  clearRequestSuccess,
} from '@vizir-banking/banking-app-core/dist/redux/application';

import {SETTINGS_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {Header} from '~/settings/screens/change-app-password/change-app-password.styles';

import Form from './form';

type Props = NavigationProps & ReturnType<typeof mapDispatchToProps>;

class ChangeAppPassword extends React.PureComponent<Props> {
  componentDidMount(): void {
    const {actions} = this.props;

    actions.setShowRequestErrorDialog(false);
    actions.clearRequestSuccess();
  }

  goToMyDetails = (): void => {
    const {navigate} = this.props;

    navigate(SETTINGS_SCREENS.settings);
  };

  render(): ReactElement {
    const requestSuccessDialogButtons = [
      {
        label: translate(
          'settings.changeAppPassword.successModal.buttonLabels.backToStart',
        ),
        action: this.goToMyDetails,
      },
    ];

    return (
      <Screen
        type={ScreenTypes.LOGGED}
        title={translate('settings.changeAppPassword.title')}
      >
        <RequestErrorDialog />
        <RequestSuccessDialog buttons={requestSuccessDialogButtons} />
        <Header>{translate('settings.changeAppPassword.header')}</Header>
        <Form />
      </Screen>
    );
  }
}

type Actions = {
  setShowRequestErrorDialog: (show: boolean) => void;
  clearRequestSuccess: () => {type: string};
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(
    {setShowRequestErrorDialog, clearRequestSuccess: () => clearRequestSuccess},
    dispatch,
  ),
});

export default connect(
  null,
  mapDispatchToProps,
)(withNavigation(ChangeAppPassword));
