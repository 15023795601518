import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

export class CheckPhotoDocument {
  public static isDocumentFrontValid(
    documentType: DocumentTypes | undefined,
  ): boolean {
    if (!documentType) return false;

    const validDocuments = [
      'DRIVER_LICENSE_FRONT',
      'IDENTITY_CARD_FRONT',
      'RNE_FRONT',
      'SELFIE',
    ];

    return validDocuments.includes(documentType);
  }

  public static isFront(documentType: DocumentTypes | undefined): boolean {
    if (!documentType) return false;

    const validDocuments = [
      'DRIVER_LICENSE_FRONT',
      'IDENTITY_CARD_FRONT',
      'RNE_FRONT',
    ];

    return validDocuments.includes(documentType);
  }

  public static isVerse(documentType: DocumentTypes | undefined): boolean {
    if (!documentType) return false;

    const validDocuments = [
      'DRIVER_LICENSE_VERSE',
      'IDENTITY_CARD_VERSE',
      'RNE_VERSE',
    ];

    return validDocuments.includes(documentType);
  }
}
