import React, {useState, ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {removeCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/remove-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import StateInscriptionScreen from '~/onboarding/screens/state-inscription/state-inscription';

const actionCreators = {
  addCreationUser,
  removeCreationUser,
};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
  removeCreationUser: (fieldName: keyof User) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

const StateInscriptionContainer = ({
  actions,
  navigateToNextOnboardingScreen,
}: Props): ReactElement => {
  const [isInscriptionFree, setIsInscriptionFree] = useState(false);

  const handleCheckboxValue = (): void => {
    if (!isInscriptionFree) actions.removeCreationUser('stateInscription');

    setIsInscriptionFree(!isInscriptionFree);
  };

  const onSubmit = (value: string): void => {
    if (value) actions.addCreationUser('stateInscription', value);

    navigateToNextOnboardingScreen();
  };

  return (
    <StateInscriptionScreen
      isInscriptionFree={isInscriptionFree}
      onSubmit={onSubmit}
      onCheckboxChange={handleCheckboxValue}
    />
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    StateInscriptionContainer,
    ONBOARDING_SCREENS.stateInscription,
  ),
);
