import styled from 'styled-components';
import {
  View,
  Icon,
  Text,
  Button,
} from '@vizir-banking/banking-app-core/dist/layout';
import {
  useTheme,
  ifStyle,
} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

import {ListItemProps} from './types';

const isFirst = ifStyle('isFirst');

export const TipsWrapper = styled(View)`
  flex-grow: 1;
  justify-content: flex-start;
`;

export const ListItem = styled(View)<ListItemProps>`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: ${isFirst('30px', 0)};
  margin-bottom: ${useTheme(({sizes}) => sizes.modalVerticalPadding)}px;
`;

export const ItemIcon = styled(Icon)``;

export const ItemTip = styled(Text)`
  width: 67.5%;
`;

export const DismissButton = styled(Button)``;
