import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState} from '@vizir-banking/banking-app-core/dist/redux/application/types';
import {clearRequestError} from '@vizir-banking/banking-app-core/dist/redux/application';
import {ApplicationError} from '@vizir-banking/banking-app-core/dist/errors/application-error';

import DocumentScreen from '~/onboarding/components/document-screen/document-screen';

import {ContainerProps} from './types';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContainerProps;

const DocumentScreenContainer = ({
  reduxValueKey,
  appError,
  actions,
  showProgress = false,
  isCompanyFlow = false,
  renderTitle,
  schemaName,
  maskName,
  onSubmit,
}: Props): ReactElement => {
  const handleOnChangeDocument = (): void => {
    if (appError) {
      actions.clearRequestError();
    }
  };

  const getError = (): string => (appError ? appError.uiMessage : '');

  return (
    <DocumentScreen
      reduxValueKey={reduxValueKey}
      showProgress={showProgress}
      isCompanyFlow={isCompanyFlow}
      renderTitle={renderTitle}
      schemaName={schemaName}
      maskName={maskName}
      onChange={handleOnChangeDocument}
      externalError={getError()}
      onSubmit={onSubmit}
    />
  );
};

type State = {
  appError?: ApplicationError;
};

const mapStateToProps = ({
  application,
}: {
  application: ApplicationState;
}): State => ({
  appError: application.error,
});

type Actions = {
  clearRequestError: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({clearRequestError}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentScreenContainer);
