import React, {ReactElement} from 'react';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import openUrl from '@vizir-banking/banking-app-core/dist/utils/open-url';
import {ApplicationError} from '@vizir-banking/banking-app-core/dist/errors/application-error';

import appDetails from '~/utils/app-details/app-details';
import sadFaceImage from '~/assets/images/sad_face.png';
import translate from '~/i18n/translate';
import {
  Title,
  Message,
  TitleWrapper,
  Button,
  Image,
  CallCenterLink,
  ErrorMessage,
} from '~/onboarding/screens/account-creating/account-creating.styles';

const InvalidRegistration = ({
  error,
  goToStart,
}: {
  error?: ApplicationError;
  goToStart: () => void;
}): ReactElement => {
  const contactPhone = translate('onboarding.accountCreating.contactPhone');

  const onCallPress = (): void => {
    if (appDetails.platform === 'ios') {
      return openUrl(`telprompt: ${contactPhone}`);
    }

    openUrl(`tel: ${contactPhone}`);
  };

  const message =
    error?.uiMessage || translate('app.apiErrors.GENERIC_ERROR.message');

  return (
    <TitleWrapper>
      <Image source={sadFaceImage} />
      <Title>{translate('onboarding.accountCreating.failure')}</Title>
      <ErrorMessage>{message}</ErrorMessage>
      <Button
        type={ButtonTypes.PRIMARY}
        onPress={goToStart}
        text={translate('onboarding.accountCreating.redoButton')}
      />
      <Message>
        {translate('onboarding.accountCreating.contactMessage')}
      </Message>
      <CallCenterLink
        type={ButtonTypes.TRANSPARENT}
        text={contactPhone}
        onPress={onCallPress}
      />
    </TitleWrapper>
  );
};

export default InvalidRegistration;
