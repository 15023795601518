import React, {ReactElement} from 'react';

import {
  Wrapper,
  Label,
  Value,
} from '~/settings/components/form-field-disabled/form-field-disabled.styles';

type Props = {
  label: string;
  value: any;
};

const FormFieldDisabled = ({label, value}: Props): ReactElement => (
  <Wrapper>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Wrapper>
);

export default FormFieldDisabled;
