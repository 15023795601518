export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INEPT = 'INEPT',
  DISCHARGED = 'DISCHARGED',
  NULL = 'NULL',
}

export type Props = {
  isLoading: boolean;
  onSubmit: (value: CompanyStatus) => void;
};
