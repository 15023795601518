import React, {ReactElement} from 'react';
import {Dialog} from '@vizir-banking/banking-app-core/dist/layout';

import translate from '~/i18n/translate';
import modalCheck from '~/assets/images/modal_check.png';
import {
  Wrapper,
  Message,
  Email,
  Button,
} from '~/settings/screens/income-report/success-dialog/success-dialog.styles';

type Props = {
  email: string | undefined;
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const SuccessDialog = ({
  email,
  show,
  onConfirm,
  onClose,
}: Props): ReactElement => {
  const translateShorter = (key: string): string =>
    translate(`settings.incomeReport.successModal.${key}`);

  return (
    <Dialog
      show={show}
      title={translateShorter('title')}
      image={modalCheck}
      onClose={onClose}
    >
      <Wrapper>
        <Message>{translateShorter('message')}</Message>
        <Email>{email ?? ''}</Email>
        <Button text={translateShorter('button')} onPress={onConfirm} />
      </Wrapper>
    </Dialog>
  );
};
