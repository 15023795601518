import styled from 'styled-components';
import {
  Text,
  View,
  Icon,
  Image as BaseImage,
  Button as BaseButton,
  ButtonTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {
  ifStyle,
  useTheme,
} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

import {Title as SharedTitle} from '~/onboarding/components/shared-styles/shared-styles';

const isLessMarginTop = ifStyle('lessMarginTop');
const isBold = ifStyle('isBold');
const isPreview = ifStyle('isPreview');

type ImageExtraProps = {
  isPreview?: boolean;
};

type MessageExtraProps = {
  lessMarginTop?: boolean;
  isBold?: boolean;
};

export const Message = styled(Text)<MessageExtraProps>`
  margin-top: ${isLessMarginTop('10px', '26px')};
  font-size: 18px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
  ${isBold(({fonts}) => `font-family: ${fonts.primary.weight.bold};`)}
`;

export const Title = styled(SharedTitle)`
  margin: 26px 0;
`;

export const SubTitle = styled(Text)`
  margin: 10px 0;
  font-size: 18px;
  font-family: ${useTheme(({fonts}) => fonts.primary.weight['600'])};
  text-align: center;
  color: ${useTheme(({colors}) => colors.textOverPrimary)};
`;

export const TitleNotBold = styled(SharedTitle)`
  font-family: ${useTheme(({fonts}) => fonts.primary.weight.normal)};
`;

export const ImageWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const Image = styled(BaseImage)<ImageExtraProps>`
  align-self: center;
  margin: 30px 0;
  height: ${isPreview('320', '230')}px;
  ${isPreview(`
    max-height: 400px;
  `)}
`;

export const SecondaryButton = styled(BaseButton).attrs({
  type: ButtonTypes.TRANSPARENT,
})`
  margin-top: 30px;
`;

export const InfoWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: 0 12px 20px 12px;
  justify-content: center;
`;

export const InfoIcon = styled(Icon).attrs(({theme}: {theme: Theme}) => ({
  name: 'info',
  size: 15,
  color: theme.colors.icons.info.primary,
}))`
  margin-right: 10px;
`;

export const InfoText = styled(Text)`
  font-size: 13px;
  color: ${useTheme(({colors}) => colors.info.primary)};
`;

export const SuccessIcon = styled(Icon).attrs(({theme}: {theme: Theme}) => ({
  name: 'success',
  size: 57,
  backgroundColor: theme.colors.success,
  checkColor: theme.colors.textAlternative,
}))`
  position: absolute;
  top: 0;
  right: -28.5px;
  z-index: 2;
`;

export const ImageContainer = styled(View)`
  align-self: center;
  position: relative;
`;
