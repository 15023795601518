export default {
  birthday: '/onboarding/birthday',
  code: '/onboarding/code',
  document: '/onboarding/document',
  identity: '/onboarding/identity',
  email: '/onboarding/email',
  motherName: '/onboarding/motherName',
  name: '/onboarding/name',
  accessKey: '/onboarding/accessKey',
  phoneNumber: '/onboarding/phoneNumber',
  zipCode: '/onboarding/zipCode',
  address: '/onboarding/address',
  password: '/onboarding/password',
  stateInscription: '/onboarding/stateInscription',
  termsConditions: '/onboarding/termsConditions',
  legalNature: '/onboarding/legalNature',
  cnpjInfo: '/onboarding/cnpjInfo',
  cnae: '/onboarding/cnae',
  companyPartners: '/onboarding/companyPartners',
  companyStatus: '/onboarding/companyStatus',
  photoIdentificationDocument: '/onboarding/photoIdentificationDocument',
  photoIdentificationDocumentBack:
    '/onboarding/photoIdentificationDocumentBack',
  photoSelfie: '/onboarding/photoSelfie',
  photoResidential: '/onboarding/photoResidential',
  attachContractChange: '/onboarding/attachContractChange',
  attachSocialContract: '/onboarding/attachSocialContract',
  meiInfo: '/onboarding/meiInfo',
  meiDocument: '/onboarding/meiDocument',
  accountCreated: '/onboarding/accountCreated',
  necessaryPartnerDocuments: '/onboarding/necessaryPartnerDocuments',
  companyFantasyName: '/onboarding/companyFantasyName',
  politicallyExposedPerson: '/onboarding/politicallyExposedPerson',
  profession: '/onboarding/profession',
  annualRevenue: '/onboarding/annualRevenue',
  accountCreating: '/onboarding/accountCreating',
  meiAccountCreating: '/onboarding/meiAccountCreating',
  validateDocumentPhotos: '/onboarding/validateDocumentPhotos',
  start: '/onboarding/start',
};
