import forEach from 'lodash/forEach';
import {IconManager} from '@vizir-banking/banking-app-core/dist/hooks/icon-manager';

import * as iconsMapping from './icons-mapping'; // eslint-disable-line import/no-namespace

export const registerIcons = (iconManager: typeof IconManager): void => {
  forEach(iconsMapping, (value, key) => {
    iconManager.add(key, value);
  });
};
