import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import deepmerge from 'deepmerge';
import {ThemeColors} from '@vizir-banking/banking-app-core/dist/theme/types';

/* eslint-disable no-restricted-imports */
import colors from '~/theme/colors.json';
import componentsColors from '~/theme/components-colors.json';
/* eslint-enable no-restricted-imports */

/* eslint-disable no-confusing-arrow */
const deepMapColorsValues = <T extends any>(
  value: T,
  callback: (v: any) => any,
): T =>
  isObject(value)
    ? mapValues(value, (v) => deepMapColorsValues(v, callback))
    : callback(value);
/* eslint-enable no-confusing-arrow */

const baseColors: ThemeColors = {
  screenBackground: colors.white,
  text: colors.black,
  textSecondary: colors.tertiaryDarken4,
  textTertiary: colors.tertiaryDarken6,
  textAlternative: colors.white,
  emptyProgressBar: colors.tertiaryLight1,
  scrollbarBackground: colors.tertiaryLight2,
  filledProgressBar: colors.secondaryLighten1,
  textOverPrimary: colors.tertiaryDarken7,
  inputText: colors.tertiaryDarken7,
  primary: colors.primary,
  primaryDark: colors.primaryDarken1,
  link: colors.secondary,
  linkDark: colors.secondaryDarken1,
  placeholderTextColor: colors.tertiaryDarken1,
  filledSplashScreen: colors.primaryLighten1,
  cameraPlaceholder: colors.black,
  success: colors.success,
  icons: {
    forwardArrowDefault: colors.tertiaryDarken7,
    info: {
      primary: colors.tertiaryDarken6,
      secondary: colors.secondary,
    },
  },
  header: {
    icon: colors.white,
    text: colors.white,
  },
  balance: {
    primary: colors.white,
    secondary: colors.white,
  },
  border: {
    primary: colors.tertiaryLight1,
    secondary: colors.tertiary,
    tertiary: colors.tertiaryDarken1,
  },
  activeIndicator: {
    background: colors.tertiaryDarken6,
    backgroundSecondary: colors.secondary,
  },
  datepicker: {
    primary: colors.tertiaryLight1,
    selected: colors.tertiaryDarken7,
  },
  icon: {
    primary: colors.tertiaryDarken2,
    checkbox: colors.success,
    transparent: colors.transparent,
  },
  table: {
    primary: colors.tertiaryDarken2,
    border: colors.tertiaryLight1,
  },
  checkbox: {
    checked: colors.primary,
    notChecked: colors.tertiaryDarken4,
    border: colors.tertiaryLight1,
    icon: colors.tertiaryDarken6,
  },
  radio: {
    border: colors.tertiaryLight1,
  },
  info: {
    primary: colors.tertiaryDarken5,
    secondary: colors.tertiaryLight1,
    tertiary: colors.tertiaryDarken3,
  },
  error: {
    primary: colors.alert,
    secondary: colors.warning,
  },
  title: {
    primary: colors.tertiaryDarken5,
  },
  label: {
    primary: colors.tertiaryDarken7,
    secondary: colors.tertiaryDarken6,
    selected: colors.secondary,
  },
  maskInput: {
    background: colors.tertiaryDarken6,
  },
  button: {
    background: {
      primary: colors.primary,
      secondary: colors.white,
      tertiary: colors.secondaryDarken2,
      transparent: colors.transparent,
      disabled: colors.tertiary,
      disabledTransparent: colors.transparent,
      underline: colors.transparent,
    },
    text: {
      primary: colors.tertiaryDarken7,
      secondary: colors.tertiaryDarken7,
      tertiary: colors.white,
      transparent: colors.secondary,
      disabled: colors.white,
      disabledTransparent: colors.tertiary,
      underline: colors.tertiaryDarken7,
    },
    border: {
      secondary: colors.tertiaryDarken7,
    },
  },
  drawer: {
    account: colors.tertiaryDarken4,
  },
  avatar: {
    text: colors.white,
    gradientStart: colors.secondaryLighten3,
    gradientEnd: colors.secondaryLighten2,
  },
  clipboardButton: {
    text: colors.secondary,
  },
  dialog: {
    backgroundColor: colors.white,
    titleColor: colors.black,
    messageColor: colors.black,
    topBorderColor: colors.primary,
    closeButtonColor: colors.white,
  },
  creditCard: {
    text: colors.white,
    background: colors.secondaryDarken2,
    noBrandBackground: colors.tertiaryDarken7,
    emptyBrandCircle: colors.tertiaryLight1,
  },
  swipper: {
    paginationActive: colors.primary,
    paginationInactive: colors.tertiaryLight1,
  },
  walletCardSwipper: {
    oddCardBackground: colors.primaryDarken1,
  },
  validateCardsBox: {
    exclamationMark: colors.primaryDarken1,
  },
  selectCards: {
    border: colors.tertiaryLight1,
  },
  moneyIcon: {
    dark: {
      color: colors.tertiaryDarken5,
      background: colors.tertiary,
    },
    green: {
      color: colors.success,
      background: colors.successLight,
    },
  },
  cardMenuItem: {
    background: colors.white,
    text: colors.tertiaryDarken7,
  },
  pix: {
    claimsNotice: {
      background: colors.black,
      text: colors.white,
    },
  },
  warning: colors.warning,
  notifications: {
    badge: colors.alert,
    icon: colors.tertiaryDarken6,
  },
};

export const AppColors = deepmerge<ThemeColors, unknown>(
  baseColors,
  deepMapColorsValues(componentsColors, (value) => get({colors}, value, value)),
);
