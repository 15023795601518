import React, {ReactElement} from 'react';
import {
  RequestErrorDialog,
  SingleInputForm,
} from '@vizir-banking/banking-app-core/dist/common';

import OnboardingSingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

import {DocumentScreenProps} from './types';

const DocumentScreen = ({
  reduxValueKey,
  renderTitle,
  schemaName,
  onSubmit,
  onChange,
  maskName,
  externalError,
  showProgress,
}: DocumentScreenProps): ReactElement => {
  const renderForm = (): ReactElement => {
    if (reduxValueKey) {
      return (
        <OnboardingSingleInputForm
          reduxValueKey={reduxValueKey}
          validationSchemaName={schemaName}
          content={renderTitle}
          onSubmit={onSubmit}
          onChange={onChange}
          textInputMask={maskName}
          externalError={externalError}
        />
      );
    }

    return (
      <SingleInputForm
        validationSchemaName={schemaName}
        content={renderTitle}
        onSubmit={onSubmit}
        onChange={onChange}
        textInputMask={maskName}
        externalError={externalError}
      />
    );
  };

  return (
    <OnboardingScreen showProgress={showProgress}>
      <RequestErrorDialog />
      {renderForm()}
    </OnboardingScreen>
  );
};

export default DocumentScreen;
