import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {get} from 'lodash';
import isWeb from '@vizir-banking/banking-app-core/dist/utils/is-web';
import {clearRequestError} from '@vizir-banking/banking-app-core/dist/redux/application';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {createUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/create-user';
import {resetUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding';
import {userIsRegistered} from '@vizir-banking/banking-app-core/dist/redux/onboarding/user-is-registered';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {withOnboardingNavigation} from '~/onboarding/navigation/with-onboarding-navigation';
import {
  AccountCreating,
  Actions,
} from '~/onboarding/screens/account-creating/account-creating';

class MeiAccountCreating extends AccountCreating {
  async createUser(): Promise<boolean> {
    const partnerDocumentNumber = get(
      this.props.creationUser,
      'partners[0].documentNumber',
    );
    const createdSuccessfully = await super.createUser();

    if (createdSuccessfully && partnerDocumentNumber) {
      this.props.actions.addCreationUser(
        'documentNumber',
        partnerDocumentNumber,
      );
    }

    return createdSuccessfully;
  }

  navigateToNextOnboardingScreen = (): void => {
    const navigationStack = [
      this.props.getFirstOnboardingScreenName(),
      ONBOARDING_SCREENS.meiInfo,
    ];

    if (!isWeb()) {
      navigationStack.unshift(RoutesManager.getRoute('login', 'signUp'));
    }

    this.props.reset(navigationStack);
  };
}

const actionCreators = {
  addCreationUser,
  createUser,
  resetUser,
  userIsRegistered,
  clearRequestError,
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

const mapStateToProps = ({application, onboarding}: ReduxState): any => ({
  error: application.error,
  creationUser: onboarding.creationUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    MeiAccountCreating,
    ONBOARDING_SCREENS.meiAccountCreating,
  ),
);
