import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {parseCurrencyToNumber} from '@vizir-banking/banking-app-core/dist/utils/format-currency';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import AnnualRevenueScreen from '~/onboarding/screens/annual-revenue/annual-revenue';

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

const AnnualRevenueContainer = ({
  navigateToNextOnboardingScreen,
  actions,
}: Props): ReactElement => {
  const handleSubmit = (value: string): void => {
    actions.addCreationUser('annualRevenue', parseCurrencyToNumber(value));

    navigateToNextOnboardingScreen();
  };

  return <AnnualRevenueScreen onSubmit={handleSubmit} />;
};

const actionCreators = {
  addCreationUser,
};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string | number) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    AnnualRevenueContainer,
    ONBOARDING_SCREENS.annualRevenue,
  ),
);
