import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('en', 'onboarding', {
  WhatsYour: 'What is your ',
  email: 'e-mail',
  fullName: 'full name',
  datePlaceholder: 'DD/MM/YYYY',
  partnerData: {
    partnerData: 'Partner Data',
    partnerCompany: 'Partner / Partner Company',
    partnerCompanyName: 'Company Name',
    partnerFullName: 'Full name',
    partnerPhone: 'Cellphone',
    accountAdmin: 'Account Admin',
    holdingMessage:
      'At this time we cannot registrate holdings, but we will soon be able to register companies like yours for you to enjoy everything here! :)',
  },
  WhenWereYou: 'when where you',
  Born: 'born',
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  cpfOrCnpjLabel: 'Insert your CPF or CNPJ',
  cpfOrCnpj: 'CPF or CNPJ',
  forgetPassword: 'Forget my password',
  passwordLabel: 'Insert your password',
  newToHere: 'New to here? ',
  registerNow: 'Register now',
  login: 'Login',
  resetPassword: {
    modal: {
      title: 'password recovery',
      message:
        'Check your inbox! We will send an email via the dock@dock.tech box with the title access recovery.',
    },
  },
  hello: 'Hello',
  ContinueToLogin: 'To continue, sign in.',
  WithoutConnection: 'You have no internet connection. Please try again later.',
  phoneNumber: 'phone number',
  letsStart: "Let's Start!",
  zipcode: {
    zipcodeFormMessage: 'Now enter your address',
    zipcode: 'ZIP CODE',
    infoText: 'Your card will be sent to this address.',
  },
  Continue: 'Continue',
  whatIsThe: 'What is the ',
  legalNature: {
    legalNature: {
      whatIsThe: 'What is the ',
      lowerCaseLegalNature: 'legal nature',
      placeholder: 'Select the legal nature',
      modalTitle: 'Legal Nature',
      modalMessage: 'What is the legal nature from the company?',
      info:
        'You can find information on Legal Nature on your company is CNPJ card.',
    },
    establishmentFormat: {
      whatIsThe: 'What is the ',
      lowerCaseEstablishmentFormat: 'establishment format',
      placeholder: 'Select the establishment format',
      modalTitle: 'Establishment Format',
      modalMessage: 'What is the establishment format of the company?',
      info:
        'In the Corporate Name of your CNPJ card, you will find your form of incorporation, such as SA, LTDA, EIRELI, ME..',
    },
  },
  fromCompany: 'from the company?',
  fromYourCompany: ' from your company?',
  changePasswordSuccessfull: 'You redefined your password successfull!',
  nowCreateLabel: 'Now create a',
  newPasswordLabel: 'new password:',
  password: 'password',
  Password: 'Password',
  fillInThe: 'Fill in the',
  number: 'number',
  andThe: 'and the',
  complement: 'complement',
  zipCodeInputPlaceholder: 'Zip code',
  addressInputPlaceholder: 'Address',
  numberInputPlaceholder: 'Number',
  complementInputPlaceholder: 'Complement',
  NeighborhoodInputPlaceholder: 'Neighborhood',
  stateInputPlaceholder: 'State',
  cityInputPlaceholder: 'City',
  WhatIsYour: 'What is your',
  MothersCompleteName: 'mother complete name',
  MotherNameInfoButton: 'Why do you need to your mother`s complete name',
  MotherNameInfoText:
    'We need this data to comply with the standards required by the Central Bank.',
  Accept: 'Accept',
  Decline: 'Decline',
  TakeAPhoto: 'Take a Photo',
  SendLater: 'Send later',
  WeNeedSome: 'We need some ',
  documents: {
    documentsLabel: 'documents',
    photoConfirmation: 'Did it look good?',
    dismiss: 'No',
    accept: 'Yes',
  },
  ToValidadeYourSignUp: ' to Validate your SignUp:',
  validPartnersDocs: '- RG, CNH ou RNE of all partners',
  ProofOfResidence: '- Proof of residence',
  ArticlesOfAssociation:
    '- Articles of Association, Entrepreneur Application or MEI Certificate.',
  SendNow: 'SEND NOW',
  JustALittleToFinishRegistration:
    'Just a little left for you to finish your registration',
  NowWeNeedSomeDocuments:
    'Now we need some documents. You can send them later if you want',
  AcceptedDocuments: 'Take a photo of front of your RG, CNH or RNE',
  FrontPhoto: 'Photo from the front of the document',
  SendBackPhotoExplanation: 'Now take a photo of the back of the same document',
  BackPhoto: 'Photo from the back of the document',
  NowTakeOnePhotoOfAProofOfResidence:
    'Now take one photo of a proof of residence',
  TakeAnotherPicture: 'Take another picture',
  ResidentialPhoto: 'photo of the proof of recidence',
  termsConditionsUrl:
    'https://lucree-static.s3.amazonaws.com/app/terms/en.html',
  ReceiveEmailText:
    'I want to recive news from {{env.REACT_APP_CUSTOMER_NAME}}',
  smsCode: {
    invalidCode: 'The validation code is invalid',
    resendSms: 'Send again',
    smsCodeTitle: 'We sent a code to your number by SMS. Insert it below:',
    sendedSms: 'sms sended',
    smsSubmissionFailed: 'Sms submission has failed',
  },
  accountCreated: {
    title:
      'Your {{env.REACT_APP_CUSTOMER_NAME}} account has been successfully created.',
    willBeValidated:
      'We will evaluate your registration and validate your account.',
    startUsing: 'Download the app and start using it now!',
    buttonLabel: 'Go to login',
  },
  CompanyPartnerInfoText:
    'To proceed with registration approval you must have all registered members. Without this information the registration will not be released. ',
  DeletePartnerTitle: 'Delete Partner',
  DeletePartnerInfo: 'Do you want to delete partner infos?',
  DeletePartner: 'DELETE',
  KeepPartner: 'KEEP',
  AddAnotherPartner: 'Add another Partner',
  TermsAndConditions: 'Terms and Conditions',
  birthdayLabel: 'birthday',
  companyStatus: {
    title: {
      normal: 'What is the',
      bold: 'current status',
      normal2: 'of the company',
    },
    select: {tittle: 'Select the status', placeHolder: 'Select the status'},
    status: {
      active: 'Active',
      suspended: 'Suspended',
      inept: 'Inept',
      discharged: 'Discharged',
      null: 'Null',
    },
    submit: 'Continue',
  },
  annualRevenue: {
    title: {
      normal: 'What is your',
      bold: "company's annual revenue",
      normal2: '?',
    },
  },
  signUp: {
    welcomeTo: 'WELCOME TO',
    yourDigitalAccount: 'Your digital account, without bureaucracy',
    createAccount: 'Create your account',
    customer: 'I am already a customer',
  },
  identity: {
    title: 'Fill in the information below:',
    issuerEntity: 'Issuer entity',
    RG: 'GR',
    FU: 'FU',
    issuanceDate: 'Issuance date',
    nationality: 'Nationality',
    RGInfo: 'This information is also in the CNH or RNE',
    issuanceDateInfo: 'It can be from RG, CNH or RNE',
  },
  birthday: {
    personFlow: {
      title: {
        normal: '{{name}}, when where you',
        bold: 'born',
      },
    },
    companyFlow: {
      title: {
        normal: 'what is the',
        bold: 'foundation date ',
        normal2: 'of {{name}}',
      },
    },
  },
  companyFantasyName: {
    title: {
      normal: 'what is the',
      bold: 'company name',
    },
  },
  stateInscription: {
    start: "What's the ",
    stateInscription: 'state inscription',
    end: ' of your company?',
    checkboxLabel: 'Free from state inscription',
  },
  sendDocuments: {
    confirmDocument: {
      primaryButton: 'Continue',
      transparentButton: 'Take another picture',
    },
    photoDocument: {
      transparentButton: 'Send later',
      navbarTitle: {
        DRIVER_LICENSE_FRONT: 'Photo front',
        DRIVER_LICENSE_VERSE: 'Photo back',
        IDENTITY_CARD_FRONT: 'Photo front',
        IDENTITY_CARD_VERSE: 'Photo back',
        RNE_FRONT: 'Photo front',
        RNE_VERSE: 'Photo back',
        SELFIE: 'Selfie',
        PROOF_OF_ADDRESS: 'Proof of Address',
      },
      cameraHint: {
        DRIVER_LICENSE_FRONT: {
          hint1: 'Front',
          hint2: '(Side containing the photo)',
        },
        DRIVER_LICENSE_VERSE: {
          hint1: 'Back',
          hint2: '(Side containing the informations)',
        },
        IDENTITY_CARD_FRONT: {
          hint1: 'Front',
          hint2: '(Side containing the photo)',
        },
        RNE_FRONT: {
          hint1: 'Front',
          hint2: '(Side containing the photo)',
        },
        RNE_VERSE: {
          hint1: 'Back',
          hint2: '(Side containing the informations)',
        },
        IDENTITY_CARD_VERSE: {
          hint1: 'Back',
          hint2: '(Side containing the informations)',
        },
        SELFIE: {
          hint1: 'Selfie',
          hint2: 'Center your face',
        },
      },
      tips: {
        title: 'Tips for a good photo',
        document: {
          plastic:
            'Remove the document from the protective plastic, we will take the front (side containing the photo) and the back of it.',
          frame: 'Position the document within the photo frame.',
          surface:
            'Take the picture on a smooth surface. Avoid patterned backgrounds.',
          lights: 'Take the picture in a bright place. Watch out for reflexes.',
        },
        selfie: {
          center: 'Center your face',
          accessories: 'Accessories are not allowed.',
          glasses: 'Glasses are not allowed.',
          light: 'Take the picture in a bright place.',
        },
        buttonLabel: 'Ok, I got it',
      },
    },
    attachDocument: {
      title: {
        notAttached: 'Attach Document',
        attached: 'Document attached',
      },
      button: {
        notAttached: 'Attach',
        attached: 'Continue',
      },
      info: 'Document must be in PDF format and have a maximum of 2 mb.',
      maxFileSizeReached: 'Invalid file size. Maximum allowed size of 2 MB',
    },
    photoDocumentType: {
      formTitle: {
        message: 'Now we will need ',
        particle: 'of ',
        bold: 'your document.',
        selectDocument: 'Select the document',
        wantsPhotograph: ' you want to photograph.',
      },
      list: {
        cnhBold: 'CNH',
        cnhLabel: "Driver's license",
        rgBold: 'RG',
        rgLabel: 'ID card',
        rneBold: 'RNE',
        rneLabel: 'National registration of foreigners',
      },
    },
    photoMainDocument: {
      message: {
        normal: 'Now we will need the ',
        bold: 'seu documento.',
      },
      subMessage: 'Select the document you want to photograph:',
      primaryButton: 'Continue',
      list: {
        cnh: {
          title: 'CNH',
          label: 'Carteira de habilitação',
        },
        rg: {
          title: 'RG',
          label: 'Carteira de identidade',
        },
        rne: {
          title: 'RNE',
          label: 'Registro nacional de estrangeiros',
        },
      },
    },
    photoMainDocumentBack: {
      title: 'Document back',
      message: 'Now take a photo of the back of the same document',
      primaryButton: 'Continue',
    },
    photoSelfie: {
      title: 'Selfie',
      message: "Let's take a selfie?",
      primaryButton: 'Continue',
    },
    photoResidential: {
      title: 'Proof of Address',
      message: 'Now take one photo of a proof of residence',
      primaryButton: 'Continue',
    },
    confirmMainDocument: {
      title: 'Photo from the front of the document',
    },
    confirmMainDocumentBack: {
      title: 'Photo from the back of the document',
    },
    confirmResidential: {
      title: 'Photo of the proof of recidence',
    },
    attachSocialContract: {
      subTitle: 'Social contract, entrepreneur request or MEI certificate',
    },
    attachContractChange: {
      subTitle: 'Contract Change',
      transparentButton: 'No contract change',
    },
  },
  meiDocument: {
    label: 'As microentrepreneur, enter your ',
  },
  meiInfo: {
    title: 'Personal Data',
    almostThere: 'We are almost there!',
    messageStart: 'To complete the LP registration, we need to create a ',
    naturalPerson: 'Natural Person',
    messageEnd: ' account, therefore, we will need some data from you.',
  },
  PEP: {
    yes: 'Yes',
    no: 'No',
    title: {
      normal: 'Are you a',
      bold: 'politically exposed person?',
    },
    infoText: {
      button: 'What politically exposed person means(PEP)?',
      info:
        'A Politically Exposed Person (PEP) is a person who has held or held, for the past five years, relevant public positions, in Brazil or in other countries, as well as their representatives, family members and those close to them, including partners or persons participating in investment funds with a PEP.',
    },
    continue: 'CONTINUE',
    required: 'Required field',
  },
  profession: {
    title: '',
    profession: 'Profession',
    monthlyIncome: 'Average monthly income',
    continue: 'CONTINUE',
    infoButton: 'Why do I need to report profession and my monthly income?',
    infoText:
      'We need this data to follow the standards required by Central Bank',
    professionPlaceholder: 'Select a profession',
  },
  cnae: {
    tellUs: 'Tell us,',
    whatIs: 'what is the',
    cnae: 'CNAE',
  },
  accessKey: {
    invalidKey: 'Invalid access key',
    callout:
      '<0>{{env.REACT_APP_CUSTOMER_NAME}} Digital Account</0> is in beta, soon it will be available for all customers.',
    contactPartOne: 'Send an e-mail to',
    contactPartTwo: 'to be the first to know when your account is active.',
    collaboratorPartOne: 'Are you a collabotator?',
    collaboratorPartTwo: 'Type your <0>access key:</0>',
  },
  accountCreating: {
    sendingYourInformation: "We're almost done! Processing your data",
    youWillBeRedirectedToTheConfirmationScreen:
      "You'll be redirected to the confirmation screen soon...",
    doNotCloseTheApp: 'Do not close the app.',
    retry: 'An error occurred while processing your data',
    retryMessage: 'If the problem persists please contact our Call Center',
    retryButton: 'Retry',
    retryDocumentUpload: "Your documents wasn't accepted",
    retryDocumentUploadMessage:
      "You'll need to capture new images. Read carefully the instructions to capture a good quality image.",
    retryDocumentUploadButton: 'Capture images',
    errorDetail: 'Error code: {{errorCode}}',
    invalidData: 'We detected invalid data',
    invalidDataMessage:
      'Your registrations contains invalid information. Please review the info you entered in the previous steps and try again.',
    goBack: 'Back',
    goBackLogin: 'Go to login',
    failure: 'Ops! We found some errors in the data that you filled:',
    redoButton: 'Redo registration',
    contactMessage: 'If the problem persists please contact our Call Center',
    contactPhone: '{{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
  },
  sensor: {
    titleFaceId: 'Face ID',
    titleBiometrics: 'Biometrics',
    descriptionFaceId: 'Do you want to enable Face ID to access your account?',
    descriptionBiometrics:
      'Do you want to enable Biometrics to access your account?',
    activeFaceId: 'Enable Face ID',
    activeBiometrics: 'Enable Biometrics',
    authentication: 'Authentication',
  },
  validateDocuments: {
    loading: {
      title: 'Validating photos',
      message: "We're validating your photos and matching with your data.",
      subMessage: 'Wait a moment.',
    },
    errors: {
      default: {
        title: 'Failed to validate photos',
        message:
          'Failed to validate your photos, try taking new photos or review your data.',
      },
    },
    retryNewPhotos: 'Retry with new photos',
    reviewFromStart: 'Review your data',
  },
});
