import styled from 'styled-components';
import {FormField as BaseFormField} from '@vizir-banking/banking-app-core/dist/common';
import {
  Icon,
  View,
  Text,
  Form as BaseForm,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

export const FormField = styled(BaseFormField).attrs({
  textInputStyle: {
    fontSize: 18,
  },
})`
  width: 100%;
  margin-bottom: 10px;
`;

export const Form = styled(BaseForm)`
  flex: 1;
`;

export const InfoWrapper = styled(View)`
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const InfoIcon = styled(Icon).attrs(({theme}: {theme: Theme}) => ({
  name: 'info',
  size: 14,
  color: theme.colors.icons.info.primary,
}))`
  margin-right: 10px;
`;

export const InfoText = styled(Text)`
  font-size: 13px;
  color: ${useTheme(({colors}) => colors.info.tertiary)};
`;
