import {IconManager} from '@vizir-banking/banking-app-core/dist/hooks/icon-manager';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';
import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';
import {HomeManager} from '@vizir-banking/banking-app-core/dist/hooks/home-manager';
import {ReduxManager} from '@vizir-banking/banking-app-core/dist/hooks/redux-manager';
import coreEntry from '@vizir-banking/banking-app-core';
import lucreeEntry from '@vizir-banking-lucree/banking-app-lucree-plugin';
import corePluginsEntry from '@vizir-banking/banking-app-core-plugins';
import pixEntry from '@vizir-banking/banking-app-pix-plugin';

// eslint-disable-next-line import/no-namespace
import * as env from '~/env';
import appDetails from '~/utils/app-details/app-details';

const entrypointParams = {
  iconManager: IconManager,
  routesManager: RoutesManager,
  translationsManager: TranslationsManager,
  homeManager: HomeManager,
  reduxManager: ReduxManager,
  appDetails,
  env,
};

export default (): void => {
  corePluginsEntry(entrypointParams);
  coreEntry(entrypointParams);
  pixEntry(entrypointParams);
  lucreeEntry(entrypointParams);
};
