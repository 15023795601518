import initializeCoreI18n from '@vizir-banking/banking-app-core/dist/i18n/initialize';
import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

import '~/onboarding/translations/pt-br';
import '~/home/translations/pt-br';
import '~/translations/pt-br';
import '~/settings/translations/pt-br';
import '~/version-gate/translations/pt-br';
import '~/onboarding/translations/en';
import '~/home/translations/en';
import '~/translations/en-us';
import '~/settings/translations/en';
import '~/version-gate/translations/en';

import * as env from '~/env'; // eslint-disable-line import/no-namespace

export const initializeI18n = (): void => {
  TranslationsManager.addVariables({env});

  initializeCoreI18n(TranslationsManager);
};
