import React, {ReactElement} from 'react';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';

import sadFaceImage from '~/assets/images/sad_face.png';
import translate from '~/i18n/translate';
import {
  Title,
  Message,
  TitleWrapper,
  Button,
  Image,
} from '~/onboarding/screens/account-creating/account-creating.styles';

const InvalidData = ({goBack}: {goBack: () => void}): ReactElement => {
  return (
    <TitleWrapper>
      <Image source={sadFaceImage} />

      <Title>{translate('onboarding.accountCreating.invalidData')}</Title>
      <Message>
        {translate('onboarding.accountCreating.invalidDataMessage')}
      </Message>
      <Button
        type={ButtonTypes.PRIMARY}
        onPress={goBack}
        text={translate('onboarding.accountCreating.goBack')}
      />
    </TitleWrapper>
  );
};

export default InvalidData;
