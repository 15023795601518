import {EstablishmentFormat} from '@vizir-banking/banking-app-core/dist/entities/user/enums/establishment-format';

import {legalNatureRelations} from '~/onboarding/screens/legal-nature/constants/legal-nature-relations';
import {legalNatureList} from '~/onboarding/screens/legal-nature/constants/legal-nature.list';

export class LegalNature {
  private readonly legalNatureList = legalNatureList;
  private readonly legalNatureRelations = legalNatureRelations;

  public constructor(private establishmentFormat: EstablishmentFormat) {}

  private getRelations(): string[] {
    return this.legalNatureRelations[this.establishmentFormat];
  }

  public list(): Array<{value: string; label: string}> {
    const relations = this.getRelations();

    if (relations.length === 0) {
      return this.legalNatureList;
    }

    return this.legalNatureList.filter((legalNature) =>
      relations.includes(legalNature.value),
    );
  }
}
