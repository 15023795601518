import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {Nullable, Nilable} from 'tsdef';
import {Bold, Button} from '@vizir-banking/banking-app-core/dist/layout';
import {FormFieldTypes} from '@vizir-banking/banking-app-core/dist/common';
import {ListOption} from '@vizir-banking/banking-app-core/dist/layout/list/types';
import {ContentContainerTypes} from '@vizir-banking/banking-app-core/dist/common/screen/types';
import INDIVIDUAL_COMPANY_FORMATS from '@vizir-banking/banking-app-core/dist/constants/individual-company-formats';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {changeMeiFlow} from '@vizir-banking/banking-app-core/dist/redux/onboarding/change-mei-flow';
import {
  UserState,
  User,
} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {EstablishmentFormat} from '@vizir-banking/banking-app-core/dist/entities/user/enums/establishment-format';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import backgroundImage from '~/assets/images/national_register_pj.png';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import schema from '~/onboarding/screens/legal-nature/legal-nature.schema';
import {establishmentFormatList} from '~/onboarding/screens/legal-nature/constants/establishment-format.list';
import {LegalNature as LegalNatureEntity} from '~/onboarding/screens/legal-nature/entities/legal-nature';
import {
  Form,
  FieldsWrapper,
  Label,
  FormField,
  Background,
  BackgroundWrapper,
  InfoText,
} from '~/onboarding/screens/legal-nature/legal-nature.styles';

import {FormValues, LegalNatureFields} from './types';

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

type State = {
  establishmentFormat: Nullable<EstablishmentFormat>;
};

export class LegalNature extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      establishmentFormat: null,
    };
  }

  handleEstablishmentFormatChange = (value: EstablishmentFormat): void => {
    this.setState({
      establishmentFormat: value,
    });
  };

  getEstablishmentFormat = (): Nilable<EstablishmentFormat> => {
    return this.state.establishmentFormat || this.props.establishmentFormat;
  };

  getLegalNatureList = (): ListOption[] => {
    const establishmentFormat = this.getEstablishmentFormat();

    if (!establishmentFormat) {
      return [];
    }

    return new LegalNatureEntity(establishmentFormat).list();
  };

  submit = (formValues: FormValues): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('legalNature', formValues.legalNature);
    actions.addCreationUser(
      'establishmentFormat',
      formValues.establishmentFormat,
    );
    actions.changeMeiFlow(
      INDIVIDUAL_COMPANY_FORMATS.includes(formValues.establishmentFormat),
    );

    return navigateToNextOnboardingScreen();
  };

  renderField = (
    field: LegalNatureFields,
    list: ListOption[],
    onChange = noop,
  ): ReactElement => {
    return (
      <>
        <Label>
          {translate(`onboarding.legalNature.${field}.whatIsThe`)}
          <Bold>
            {translate(
              `onboarding.legalNature.${field}.lowerCase${field.replace(
                field[0],
                field[0].toUpperCase(),
              )}`,
            )}
          </Bold>
          {translate('onboarding.fromYourCompany')}
        </Label>
        <FormField
          type={FormFieldTypes.SELECT}
          options={list}
          name={field}
          label=""
          placeholder={translate(`onboarding.legalNature.${field}.placeholder`)}
          modalTitle={translate(`onboarding.legalNature.${field}.modalTitle`)}
          modalMessage={translate(
            `onboarding.legalNature.${field}.modalMessage`,
          )}
          onChange={onChange}
        />
      </>
    );
  };

  render(): React.ReactElement {
    const {legalNature, establishmentFormat} = this.props;

    return (
      <OnboardingScreen
        contentContainerType={ContentContainerTypes.SCROLLVIEW}
        showProgress
        isCompanyFlow
      >
        <Form
          schema={schema}
          onSubmit={this.submit}
          initialValues={{legalNature, establishmentFormat}}
        >
          <FieldsWrapper>
            {this.renderField(
              LegalNatureFields.ESTABLISHMENT_FORMAT,
              establishmentFormatList,
              this.handleEstablishmentFormatChange,
            )}
            <InfoText
              title={translate(
                'onboarding.legalNature.establishmentFormat.info',
              )}
            />
            {this.renderField(
              LegalNatureFields.LEGAL_NATURE,
              this.getLegalNatureList(),
            )}
            <InfoText
              title={translate('onboarding.legalNature.legalNature.info')}
            >
              <BackgroundWrapper>
                <Background source={backgroundImage} resizeMode="contain" />
              </BackgroundWrapper>
            </InfoText>
          </FieldsWrapper>
          <Button isSubmit text={translate('onboarding.Continue')} />
        </Form>
      </OnboardingScreen>
    );
  }
}

const actionCreators = {addCreationUser, changeMeiFlow};

type ComponetProps = {
  legalNature?: string;
  establishmentFormat?: EstablishmentFormat;
};

const mapStateToProps = ({user}: {user: UserState}): ComponetProps => ({
  legalNature: get(user, 'creationUser.legalNature', null),
  establishmentFormat: get(user, 'creationUser.establishmentFormat', null),
});

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
  changeMeiFlow: (isMei: boolean) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(LegalNature, ONBOARDING_SCREENS.legalNature));
