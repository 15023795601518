import styled from 'styled-components';
import {
  Form as BaseForm,
  View,
  Image as BaseImage,
} from '@vizir-banking/banking-app-core/dist/layout';
import {
  FormField as BaseFormField,
  Screen as BaseScreen,
  InfoText as InfoTextBase,
} from '@vizir-banking/banking-app-core/dist/common';

import {FormTitle} from '~/onboarding/components/shared-styles/shared-styles';

export const Screen = styled(BaseScreen)``;

export const Form = styled(BaseForm)`
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 51px;
`;

export const FieldsWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const Label = styled(FormTitle)`
  margin-bottom: 30px;
`;

export const FormField = styled(BaseFormField)``;

export const Background = styled(BaseImage)`
  width: 100%;
  height: 100%;
`;

export const BackgroundWrapper = styled(View)`
  max-height: 600px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const InfoText = styled(InfoTextBase)`
  width: 100%;
  margin-bottom: 35px;
`;
