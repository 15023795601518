import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Bold, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {FormValues} from '@vizir-banking/banking-app-core/dist/layout/form/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {PasswordScreen} from '~/onboarding/components/password-screen/password-screen';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {FormTitle} from '~/onboarding/components/shared-styles/shared-styles';
import ONBOARDING_SCREENS from '~/onboarding/navigation/screen-definitions';

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

const Password = ({
  navigateToNextOnboardingScreen,
  actions,
}: Props): ReactElement => {
  const submit = (formValues: FormValues): void => {
    actions.addCreationUser('password', formValues.password);

    navigateToNextOnboardingScreen();
  };

  const renderPasswordLabel = (): ReactElement => (
    <FormTitle>
      <Text>
        {`${translate('onboarding.nowCreateLabel')} `}
        <Bold>{translate('onboarding.password')}</Bold>:
      </Text>
    </FormTitle>
  );

  return (
    <PasswordScreen
      showProgress
      renderLabel={renderPasswordLabel}
      onSubmit={submit}
    />
  );
};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({addCreationUser}, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(withOnboardingNavigation(Password, ONBOARDING_SCREENS.password));
