import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {RequestErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import CONTRACT_TYPES from '@vizir-banking/banking-app-core/dist/constants/contract-types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import image from '~/assets/images/company_open.png';
import AttachScreen from '~/onboarding/screens/send-document/base-screens/attach-document/attach-document';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';

type Props = OnboardingNavigationProps & ReturnType<typeof mapStateToProps>;

class AttachSocialContract extends React.PureComponent<Props> {
  handlePrimaryButtonPress = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  render(): ReactElement {
    const documentType = get(
      CONTRACT_TYPES,
      this.props.establishmentFormat!,
      CONTRACT_TYPES.default,
    );

    return (
      <>
        <RequestErrorDialog />
        <AttachScreen
          imgSrc={image}
          subTitle={translate(
            'onboarding.sendDocuments.attachSocialContract.subTitle',
          )}
          isCompanyFlow
          onPrimaryButtonPress={this.handlePrimaryButtonPress}
          documentType={documentType.type}
        />
      </>
    );
  }
}

type State = {
  establishmentFormat?: string;
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  establishmentFormat: onboarding.creationUser.establishmentFormat,
});

export default connect(mapStateToProps)(
  withOnboardingNavigation(
    AttachSocialContract,
    ONBOARDING_SCREENS.attachSocialContract,
  ),
);
