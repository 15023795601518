import styled from 'styled-components';
import {Bold, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Title = styled(Bold)`
  margin-top: 32px;
  margin-bottom: 40px;
  font-size: 25px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;

export const HoldingMessage = styled(Text)`
  text-align: center;
`;
