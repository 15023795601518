export const add_card = require('@svgr/webpack?-svgo,+ref!~/assets/icons/add_card_icon.svg');
export const add = require('@svgr/webpack?-svgo,+ref!~/assets/icons/add_icon.svg');
export const alert = require('@svgr/webpack?-svgo,+ref!~/assets/icons/alert_icon.svg');
export const amex = require('@svgr/webpack?-svgo,+ref!~/assets/icons/amex_icon.svg');
export const arrow = require('@svgr/webpack?-svgo,+ref!~/assets/icons/arrow_icon.svg');
export const arrow2 = require('@svgr/webpack?-svgo,+ref!~/assets/icons/arrow_icon_2.svg');
export const back_white = require('@svgr/webpack?-svgo,+ref!~/assets/icons/back_white_icon.svg');
export const back = require('@svgr/webpack?-svgo,+ref!~/assets/icons/back_icon.svg');
export const bank_statement_menu = require('@svgr/webpack?-svgo,+ref!~/assets/icons/bank_statement_menu_icon.svg');
export const calendar = require('@svgr/webpack?-svgo,+ref!~/assets/icons/calendar_icon.svg');
export const camera_selfie = require('@svgr/webpack?-svgo,+ref!~/assets/icons/camera_selfie_icon.svg');
export const chat = require('@svgr/webpack?-svgo,+ref!~/assets/icons/chat_icon.svg');
export const check = require('@svgr/webpack?-svgo,+ref!~/assets/icons/check_icon.svg');
export const chevron_right = require('@svgr/webpack?-svgo,+ref!~/assets/icons/chevron_right_icon.svg');
export const close = require('@svgr/webpack?-svgo,+ref!~/assets/icons/close_icon.svg');
export const close_circle = require('@svgr/webpack?-svgo,+ref!~/assets/icons/close_circle_icon.svg');
export const cnh_list = require('@svgr/webpack?-svgo,+ref!~/assets/icons/cnh_list_icon.svg');
export const copy = require('@svgr/webpack?-svgo,+ref!~/assets/icons/copy_icon.svg');
export const credit = require('@svgr/webpack?-svgo,+ref!~/assets/icons/credit.svg');
export const credit_card_back = require('@svgr/webpack?-svgo,+ref!~/assets/icons/credit_card_back_icon.svg');
export const credit_card_validated_mark = require('@svgr/webpack?-svgo,+ref!~/assets/icons/credit_card_validated_mark_icon.svg');
export const deposit_billet = require('@svgr/webpack?-svgo,+ref!~/assets/icons/deposit_billet_icon.svg');
export const domestic_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/domestic_transactions_icon.svg');
export const doubt = require('@svgr/webpack?-svgo,+ref!~/assets/icons/doubt_icon.svg');
export const education_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/education_transactions_icon.svg');
export const elo = require('@svgr/webpack?-svgo,+ref!~/assets/icons/elo_icon.svg');
export const email = require('@svgr/webpack?-svgo,+ref!~/assets/icons/email_icon.svg');
export const empty_statement = require('@svgr/webpack?-svgo,+ref!~/assets/icons/empty_statement_icon.svg');
export const empty = require('@svgr/webpack?-svgo,+ref!~/assets/icons/empty.svg');
export const error = require('@svgr/webpack?-svgo,+ref!~/assets/icons/error_icon.svg');
export const exclamation_mark = require('@svgr/webpack?-svgo,+ref!~/assets/icons/exclamation_mark_icon.svg');
export const exit = require('@svgr/webpack?-svgo,+ref!~/assets/icons/exit_icon.svg');
export const eye_slash = require('@svgr/webpack?-svgo,+ref!~/assets/icons/eye_slash_icon.svg');
export const eye = require('@svgr/webpack?-svgo,+ref!~/assets/icons/eye_icon.svg');
export const faq = require('@svgr/webpack?-svgo,+ref!~/assets/icons/faq_icon.svg');
export const filter = require('@svgr/webpack?-svgo,+ref!~/assets/icons/filter_icon.svg');
export const flash_on = require('@svgr/webpack?-svgo,+ref!~/assets/icons/flash_on_icon.svg');
export const forward_arrow = require('@svgr/webpack?-svgo,+ref!~/assets/icons/forward_arrow_icon.svg');
export const glasses = require('@svgr/webpack?-svgo,+ref!~/assets/icons/glasses_icon.svg');
export const groceries_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/groceries_transactions_icon.svg');
export const hat = require('@svgr/webpack?-svgo,+ref!~/assets/icons/hat_icon.svg');
export const health_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/health_transactions_icon.svg');
export const hipercard = require('@svgr/webpack?-svgo,+ref!~/assets/icons/hipercard_icon.svg');
export const info = require('@svgr/webpack?-svgo,+ref!~/assets/icons/info_icon.svg');
export const key = require('@svgr/webpack?-svgo,+ref!~/assets/icons/key.svg');
export const light_fixture = require('@svgr/webpack?-svgo,+ref!~/assets/icons/light_fixture_icon.svg');
export const mastercard = require('@svgr/webpack?-svgo,+ref!~/assets/icons/mastercard_icon.svg');
export const menu = require('@svgr/webpack?-svgo,+ref!~/assets/icons/menu_icon.svg');
export const money = require('@svgr/webpack?-svgo,+ref!~/assets/icons/money_icon.svg');
export const non_rounded_check = require('@svgr/webpack?-svgo,+ref!~/assets/icons/non_rounded_check_icon.svg');
export const open_receipt_button = require('@svgr/webpack?-svgo,+ref!~/assets/icons/open_receipt_button_icon.svg');
export const others_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/others_transactions_icon.svg');
export const payment = require('@svgr/webpack?-svgo,+ref!~/assets/icons/payment_icon.svg');
export const person = require('@svgr/webpack?-svgo,+ref!~/assets/icons/person_icon.svg');
export const phone = require('@svgr/webpack?-svgo,+ref!~/assets/icons/phone_icon.svg');
export const plastic_cnh = require('@svgr/webpack?-svgo,+ref!~/assets/icons/plastic_cnh_icon.svg');
export const purchases_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/purchases_transactions_icon.svg');
export const pix = require('@svgr/webpack?-svgo,+ref!~/assets/icons/pix.svg');
export const recreation_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/recreation_transactions_icon.svg');
export const request_card_menu = require('@svgr/webpack?-svgo,+ref!~/assets/icons/request_card_menu_icon.svg');
export const rg_list = require('@svgr/webpack?-svgo,+ref!~/assets/icons/rg_list_icon.svg');
export const rne_list = require('@svgr/webpack?-svgo,+ref!~/assets/icons/rne_list_icon.svg');
export const rne_photo = require('@svgr/webpack?-svgo,+ref!~/assets/icons/rne_photo_icon.svg');
export const rne = require('@svgr/webpack?-svgo,+ref!~/assets/icons/rne_icon.svg');
export const scan = require('@svgr/webpack?-svgo,+ref!~/assets/icons/scan_icon.svg');
export const search = require('@svgr/webpack?-svgo,+ref!~/assets/icons/search_icon.svg');
export const selfie = require('@svgr/webpack?-svgo,+ref!~/assets/icons/selfie_icon.svg');
export const services_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/services_transactions_icon.svg');
export const share = require('@svgr/webpack?-svgo,+ref!~/assets/icons/share_icon.svg');
export const statement = require('@svgr/webpack?-svgo,+ref!~/assets/icons/statement_icon.svg');
export const success = require('@svgr/webpack?-svgo,+ref!~/assets/icons/success_icon.svg');
export const terms = require('@svgr/webpack?-svgo,+ref!~/assets/icons/terms_icon.svg');
export const transaction = require('@svgr/webpack?-svgo,+ref!~/assets/icons/transaction_icon.svg');
export const transfer_menu = require('@svgr/webpack?-svgo,+ref!~/assets/icons/transfer_menu_icon.svg');
export const transportation_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/transportation_transactions_icon.svg');
export const trash = require('@svgr/webpack?-svgo,+ref!~/assets/icons/trash_icon.svg');
export const travel_transactions = require('@svgr/webpack?-svgo,+ref!~/assets/icons/travel_transactions_icon.svg');
export const virtual_card_menu = require('@svgr/webpack?-svgo,+ref!~/assets/icons/virtual_card_menu_icon.svg');
export const visa = require('@svgr/webpack?-svgo,+ref!~/assets/icons/visa_icon.svg');
export const wallet = require('@svgr/webpack?-svgo,+ref!~/assets/icons/wallet_icon.svg');
export const wallet_base = require('@svgr/webpack?-svgo,+ref!~/assets/icons/wallet_base_icon.svg');
export const yellow_faq = require('@svgr/webpack?-svgo,+ref!~/assets/icons/yellow_faq_icon.svg');
export const faceid = require('@svgr/webpack?-svgo,+ref!~/assets/icons/faceid.svg');
export const biometrics = require('@svgr/webpack?-svgo,+ref!~/assets/icons/biometrics.svg');
export const calendar_dialog = require('@svgr/webpack?-svgo,+ref!~/assets/icons/calendar_dialog_icon.svg');
export const whatsapp = require('@svgr/webpack?-svgo,+ref!~/assets/icons/whatsapp_icon.svg');
export const validation_selfie = require('@svgr/webpack?-svgo,+ref!~/assets/icons/validation_selfie_icon.svg');
