import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('ptBr', 'versionGate', {
  updateModal: {
    prompt: {
      title: 'Atualização disponível',
      message:
        'Temos uma nova versão do aplicativo, recomendamos que você atualize agora.',
    },
    force: {
      title: 'Atualização obrigatória',
      message:
        'Temos uma nova versão do aplicativo, atualize agora para voltar a acessar sua conta.',
    },
    buttonLabel: 'Atualizar',
  },
});
