import React, {ReactElement} from 'react';
import {RequestErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {Text, Button} from '@vizir-banking/banking-app-core/dist/layout';
import {useApplicationFromContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';

import translate from '~/i18n/translate';
import {FormTitle} from '~/onboarding/components/shared-styles/shared-styles';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import SCHEMA from '~/onboarding/components/password-screen/password-screen.schema';
import {
  Form,
  FormField,
  FieldsWrapper,
  InfoWrapper,
  InfoIcon,
  InfoText,
} from '~/onboarding/components/password-screen/password-screen.styles';

import {Props} from './types';

const PasswordScreen = ({
  showProgress = false,
  renderLabel,
  onSubmit,
}: Props): ReactElement => {
  const applicationContext = useApplicationFromContext();
  const isNumericAppPassword =
    applicationContext.env.REACT_APP_NUMERIC_APP_PASSWORD;
  const passwordMinSize = isNumericAppPassword ? 6 : 8;

  const renderPasswordConfirmationLabel = (): ReactElement => (
    <FormTitle>
      <Text>{translate('common.passwordScreen.confirmPasswordLabel')}</Text>
    </FormTitle>
  );

  return (
    <OnboardingScreen showProgress={showProgress}>
      <RequestErrorDialog />
      <Form schema={SCHEMA} onSubmit={onSubmit} passDisabledToButton>
        <FieldsWrapper>
          <FormField
            name="password"
            autoFocus
            label={renderLabel}
            inputType="password"
            maxLength={isNumericAppPassword ? 6 : undefined}
            keyboardType={isNumericAppPassword ? 'numeric' : 'default'}
            returnKeyType="next"
          />
          <FormField
            name="newPassword"
            label={renderPasswordConfirmationLabel}
            inputType="password"
            maxLength={isNumericAppPassword ? 6 : undefined}
            keyboardType={isNumericAppPassword ? 'numeric' : 'default'}
          />
          <InfoWrapper>
            <InfoIcon name="info" size={14} />
            <InfoText>
              {translate('common.passwordScreen.passwordMinSize', {
                minSize: passwordMinSize,
              })}
            </InfoText>
          </InfoWrapper>
        </FieldsWrapper>
        <Button isSubmit text={translate('onboarding.Continue')} />
      </Form>
    </OnboardingScreen>
  );
};

export {PasswordScreen};
