import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import isEmpty from 'lodash/isEmpty';
import {Button} from '@vizir-banking/banking-app-core/dist/layout';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {ErrorCodes} from '@vizir-banking/banking-app-core/dist/api/types';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import updateUserPassword from '@vizir-banking/banking-app-core/dist/redux/user/update-password';
import {clearRequestError} from '@vizir-banking/banking-app-core/dist/redux/application';
import {UpdatePasswordParams} from '@vizir-banking/banking-app-core/dist/api/user/types';
import {ApplicationError} from '@vizir-banking/banking-app-core/dist/errors/application-error';
import isWeb from '@vizir-banking/banking-app-core/dist/utils/is-web';
import {ErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {KeyboardTypeOptions} from 'react-native';
import {createCredentials} from '@vizir-banking/banking-app-core/dist/utils/sensor/keychain/keychain';
import {
  stopFingerprintScanner,
  promptFingerprintScanner,
} from '@vizir-banking/banking-app-core/dist/utils/sensor/fingerprint/fingerprint';

import translate from '~/i18n/translate';

import {FormField, Form, InfoWrapper, InfoIcon, InfoText} from './styles';
import schema from './schema';

type Props = NavigationProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface FormFields {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

class ChangeAppPassword extends React.PureComponent<Props> {
  static contextType = ApplicationContext;
  state = {
    showErrorModal: false,
  };

  private hasError(appError?: ApplicationError): boolean {
    return (
      appError !== undefined &&
      appError.code === ErrorCodes.BANK_API_CHECK_PASSWORD_FAILED
    );
  }

  componentWillUnmount = (): void => {
    if (!isWeb()) {
      stopFingerprintScanner();
    }
  };

  onSubmit = async (formFields: FormFields): Promise<void> => {
    const {actions, sensorIsUsed} = this.props;
    const context = this.context as ApplicationContextType;

    if (sensorIsUsed) {
      return this.sensorConfirm(formFields);
    }

    const updatePasswordParams: UpdatePasswordParams = {
      currentPassword: formFields.currentPassword,
      newPassword: formFields.confirmPassword,
    };

    actions.updateUserPassword(
      updatePasswordParams,
      context.bankingCoreHttpClient,
    );
  };

  getCheckPasswordError = (): string => {
    const {appError} = this.props;
    let checkPasswordError: string = '';

    if (this.hasError(appError)) {
      checkPasswordError = appError?.uiMessage || '';
    }

    return checkPasswordError;
  };

  currentPasswordChange = (): void => {
    const {actions} = this.props;
    const checkPasswordError = this.getCheckPasswordError();

    if (!isEmpty(checkPasswordError)) {
      actions.clearRequestError();
    }
  };

  sensorConfirm = async (fields: FormFields): Promise<void> => {
    const {actions, previousLoginUsername} = this.props;

    const context = this.context as ApplicationContextType;
    const message = translate('onboarding.sensor.authentication');

    try {
      await promptFingerprintScanner(message);
    } catch {
      this.setState({showErrorModal: true});
      stopFingerprintScanner();
      return;
    }

    const updatePasswordParams: UpdatePasswordParams = {
      currentPassword: fields.currentPassword,
      newPassword: fields.confirmPassword,
    };

    try {
      await actions.updateUserPassword(
        updatePasswordParams,
        context.bankingCoreHttpClient,
      );
      await createCredentials(previousLoginUsername, fields.password);
    } catch {}

    stopFingerprintScanner();
  };

  handleCloseModal = (): void => {
    this.setState({showErrorModal: false});
  };

  isNumericAppPassword = (): boolean => {
    const {env} = this.context as ApplicationContextType;
    return Boolean(env.REACT_APP_NUMERIC_APP_PASSWORD);
  };

  getMaxLength = (): number | undefined => {
    const MAX_NUMERIC_PASSWORD_LENGTH = 6;

    if (this.isNumericAppPassword()) {
      return MAX_NUMERIC_PASSWORD_LENGTH;
    }

    return undefined;
  };

  getKeyboardType = (): KeyboardTypeOptions => {
    if (this.isNumericAppPassword()) {
      return 'number-pad';
    }

    return 'default';
  };

  render(): ReactElement {
    const {showErrorModal} = this.state;
    const checkPasswordError = this.getCheckPasswordError();
    const passwordMinSize = this.isNumericAppPassword() ? 6 : 8;

    const passwordFormFieldCommonProps = {
      inputType: 'password',
      maxLength: this.getMaxLength(),
      keyboardType: this.getKeyboardType(),
    };

    return (
      <>
        <Form
          schema={schema}
          onSubmit={this.onSubmit}
          showErrorsWithoutSubmit
          passDisabledToButton
        >
          <FormField
            autoFocus
            label={translate('settings.changeAppPassword.form.currentPassword')}
            name="currentPassword"
            externalError={checkPasswordError}
            onChange={this.currentPasswordChange}
            {...passwordFormFieldCommonProps}
            returnKeyType="next"
          />
          <FormField
            label={translate('settings.changeAppPassword.form.password')}
            name="password"
            {...passwordFormFieldCommonProps}
            returnKeyType="next"
          />
          <FormField
            label={translate('settings.changeAppPassword.form.confirmPassword')}
            name="confirmPassword"
            {...passwordFormFieldCommonProps}
          />
          <InfoWrapper>
            <InfoIcon name="info" size={14} />
            <InfoText>
              {translate('common.passwordScreen.passwordMinSize', {
                minSize: passwordMinSize,
              })}
            </InfoText>
          </InfoWrapper>
          <Button
            isSubmit
            text={translate('settings.changeAppPassword.form.continue')}
          />
        </Form>

        <ErrorDialog
          show={showErrorModal}
          onClose={this.handleCloseModal}
          title={translate('settings.sensor.errorTitle')}
          message={translate('settings.sensor.errorMessage')}
        />
      </>
    );
  }
}

type State = {
  appError?: ApplicationError;
  previousLoginUsername: string;
  sensorIsUsed: boolean;
};

const mapStateToProps = ({application, sensor}: ReduxState): State => ({
  appError: application.error,
  previousLoginUsername: application.previousLoginUsername,
  sensorIsUsed: sensor.sensorIsUsed,
});

type Actions = {
  updateUserPassword: (
    params: UpdatePasswordParams,
    httpClient: BankingCoreHttpClient,
  ) => Promise<void>;
  clearRequestError: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(
    {updateUserPassword, clearRequestError},
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(ChangeAppPassword));
