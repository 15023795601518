import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

export const registerThemeTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'depositBillet', {
    depositAccountInfo: {
      title: {
        bold2: '{{env.REACT_APP_CUSTOMER_NAME}}',
      },
    },
  });
};
