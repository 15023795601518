import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {isCNPJ} from 'brazilian-values';
import {
  Bold,
  MaskTypes,
  Text,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {ApplicationState} from '@vizir-banking/banking-app-core/dist/redux/application/types';
import {changeCompanyFlow} from '@vizir-banking/banking-app-core/dist/redux/onboarding/change-company-flow';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {userIsRegistered} from '@vizir-banking/banking-app-core/dist/redux/onboarding/user-is-registered';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {ApplicationError} from '@vizir-banking/banking-app-core/dist/errors/application-error';

import DocumentScreen from '~/onboarding/components/document-screen/document-screen.container';
import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {REACT_APP_ONBOARDING_ALLOW_COMPANY} from '~/env';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import translate from '~/i18n/translate';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

class Document extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  submit = (value: string): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;
    const context = this.context as ApplicationContextType;

    actions
      .userIsRegistered(value, context.bankingCoreHttpClient)
      .then((isRegistered) => {
        if (isRegistered) {
          const isCompanyFlow = isCNPJ(value);

          actions.changeCompanyFlow(isCompanyFlow);
          actions.addCreationUser('documentNumber', value);

          navigateToNextOnboardingScreen();
        }
      });
  };

  getDocumentTypeText = (): string => {
    return REACT_APP_ONBOARDING_ALLOW_COMPANY
      ? translate('onboarding.cpfOrCnpj')
      : translate('onboarding.CPF');
  };

  getDocumentValidationSchemaName = (): SchemaNames => {
    return REACT_APP_ONBOARDING_ALLOW_COMPANY
      ? SchemaNames.CPF_CNPJ
      : SchemaNames.CPF;
  };

  getDocumentMaskType = (): MaskTypes => {
    return REACT_APP_ONBOARDING_ALLOW_COMPANY
      ? MaskTypes.CPF_CNPJ
      : MaskTypes.CPF;
  };

  renderInputLabel = (): ReactElement => {
    return (
      <Text>
        {translate('onboarding.WhatsYour')}
        <Bold>{this.getDocumentTypeText()}</Bold>?
      </Text>
    );
  };

  render(): ReactElement {
    return (
      <DocumentScreen
        showProgress
        reduxValueKey="documentNumber"
        renderTitle={this.renderInputLabel}
        maskName={this.getDocumentMaskType()}
        schemaName={this.getDocumentValidationSchemaName()}
        onSubmit={this.submit}
      />
    );
  }
}

const actionCreators = {
  addCreationUser,
  userIsRegistered,
  changeCompanyFlow,
};

type State = {
  appError?: ApplicationError;
};

const mapStateToProps = ({
  application,
}: {
  application: ApplicationState;
}): State => ({
  appError: application.error,
});

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
  userIsRegistered: (
    document: string,
    httpClient: BankingCoreHttpClient,
    setError?: boolean,
  ) => Promise<boolean>;
  changeCompanyFlow: (isMei: boolean) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(Document, ONBOARDING_SCREENS.document));
