import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {UserState} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {getBanks} from '@vizir-banking/banking-app-core-plugins/dist/transfer/redux/get-banks';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {
  HomeManager,
  MenuItem,
  MenuItemFeatureToggle,
} from '@vizir-banking/banking-app-core/dist/hooks/home-manager';
import CardCollection from '@vizir-banking/banking-app-core/dist/entities/cards/card-collection';
import BILLET_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/deposit/navigation/screen-definitions';
import VIRTUAL_CARD_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/virtual-card/navigation/screen-definitions';
import CARD_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/physical-card/navigation/screen-definitions';
import CARD_NO_NAME_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/card-no-name/navigation/screen-definitions';
import {CardStatus} from '@vizir-banking/banking-app-core/dist/entities/cards/card-status';
import {Card} from '@vizir-banking/banking-app-core/dist/entities/cards/card';

import {
  REACT_APP_ENABLE_FEATURE_TOGGLE,
  REACT_APP_ENABLE_PHYSICAL_CARD,
  REACT_APP_ENABLE_VIRTUAL_CARD,
  REACT_APP_ONBOARDING_CARD_NO_NAME,
} from '~/env';

import Home from './home';

type State = {
  cards?: CardCollection;
  accountBank?: string;
};

const mapStateToProps = ({user: {currentUser}}: {user: UserState}): State => ({
  cards: currentUser.cards,
  accountBank: currentUser.accountBank,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  NavigationProps;

class HomeContainer extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  componentDidMount = (): void => {
    this.loadBanks();
  };

  loadBanks = (): void => {
    const {actions} = this.props;
    const context = this.context as ApplicationContextType;
    actions.getBanks(context.bankingCoreHttpClient, false);
  };

  redirectToScreen = (screenName?: string): void => {
    if (screenName) {
      this.props.navigate(screenName);
    }
  };

  showRequestCard = (): boolean => {
    if (
      !this.props.cards ||
      !this.props.cards.physicalMainCard ||
      !this.props.cards.transactionCard
    ) {
      return true;
    }

    return (
      this.isCardCancelled(this.props.cards.physicalMainCard) &&
      !this.hasCardBlockedByIncorrectPassword(this.props.cards.physicalCards)
    );
  };

  isCardCancelled = (card: Card): boolean => {
    return card.status === CardStatus.canceled;
  };

  hasCardBlockedByIncorrectPassword = (cards: Card[]): boolean => {
    return (
      cards.filter(
        (card) => card.status === CardStatus.blockedIncorrectPassword,
      ).length > 0
    );
  };

  showAssignNoNameCard = (): boolean => {
    return this.showRequestCard() && REACT_APP_ONBOARDING_CARD_NO_NAME;
  };

  showUnlockCard = (): boolean => {
    if (!this.props.cards) {
      return false;
    }

    if (!this.props.cards.physicalMainCard) {
      return false;
    }

    return this.props.cards.physicalMainCard.status === CardStatus.blocked;
  };

  showCreateVirtualCard = (): boolean => {
    if (!this.props.cards || !this.props.cards.virtualMainCard) {
      return true;
    }

    return (
      this.props.cards.virtualMainCard.status !== CardStatus.blocked &&
      this.props.cards.virtualMainCard.status !== CardStatus.normal
    );
  };

  buildMenuItems = (items: MenuItem[], item: MenuItem): void => {
    const isDepositBillet = item.screenName === BILLET_SCREENS.depositBillet;

    if (isDepositBillet && this.props.accountBank) {
      items.push({
        ...item,
        screenName: BILLET_SCREENS.depositMethod,
      });
      return;
    }

    const isPhysicalCard = item.label === 'home.myCard';

    if (isPhysicalCard && !REACT_APP_ENABLE_PHYSICAL_CARD) {
      return;
    }

    if (isPhysicalCard && this.showUnlockCard()) {
      items.push({
        ...item,
        label: 'home.unlockCard',
        screenName: CARD_SCREENS.unlockCard,
      });
      return;
    }

    if (isPhysicalCard && this.showAssignNoNameCard()) {
      items.push({
        ...item,
        label: 'home.assignCard',
        screenName: CARD_NO_NAME_SCREENS.home,
      });
      return;
    }

    if (isPhysicalCard && this.showRequestCard()) {
      items.push({
        ...item,
        label: 'home.requestCard',
        screenName: CARD_SCREENS.requestCard,
      });
      return;
    }

    const isVirtualCard = item.label === 'home.virtualCard';

    if (isVirtualCard && !REACT_APP_ENABLE_VIRTUAL_CARD) {
      return;
    }

    if (isVirtualCard && this.showCreateVirtualCard()) {
      items.push({
        ...item,
        screenName: VIRTUAL_CARD_SCREENS.createCard,
      });
      return;
    }

    items.push(item);
  };

  removeMenuItems = (menuItem: MenuItem): boolean => {
    const {featureToggleAdapter} = this.context as ApplicationContextType;

    if (!REACT_APP_ENABLE_FEATURE_TOGGLE || !menuItem.featureToggle) {
      return true;
    }

    const {enabled} = featureToggleAdapter
      .getObject<MenuItemFeatureToggle>(menuItem.featureToggle)
      .getOrElse(() => ({
        enabled: false,
      }));

    return enabled;
  };

  composeMenuItems = (menuItem: MenuItem): MenuItem => {
    const {featureToggleAdapter} = this.context as ApplicationContextType;

    if (!REACT_APP_ENABLE_FEATURE_TOGGLE || !menuItem.featureToggle) {
      return menuItem;
    }

    const option = featureToggleAdapter.getObject<MenuItemFeatureToggle>(
      menuItem.featureToggle,
    );

    if (option.isDefined()) {
      const {badgeText, badgeColor} = option.get();

      menuItem.badgeText = badgeText;
      menuItem.badgeColor = badgeColor;
    }

    return menuItem;
  };

  getMenuItems = (): MenuItem[] => {
    const menuItems: MenuItem[] = [];

    HomeManager.getHomeMenuItems()
      .filter(this.removeMenuItems)
      .map(this.composeMenuItems)
      .forEach((menuItem) => {
        this.buildMenuItems(menuItems, menuItem);
      });

    return menuItems;
  };

  render(): ReactElement {
    const menuItems = this.getMenuItems();

    return (
      <Home
        redirectToScreen={this.redirectToScreen}
        menuItems={menuItems}
        navigate={this.props.navigate}
      />
    );
  }
}

type Actions = {
  getBanks: (httpClient: BankingCoreHttpClient, setLoading: boolean) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({getBanks}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(HomeContainer));
