import React, {ReactElement} from 'react';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import image from '~/assets/images/contract_change.png';
import AttachScreen from '~/onboarding/screens/send-document/base-screens/attach-document/attach-document';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';

class AttachContractChange extends React.PureComponent<
  OnboardingNavigationProps
> {
  handlePrimaryButtonPress = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  render(): ReactElement {
    return (
      <AttachScreen
        imgSrc={image}
        subTitle={translate(
          'onboarding.sendDocuments.attachContractChange.subTitle',
        )}
        onPrimaryButtonPress={this.handlePrimaryButtonPress}
        onSendLaterButtonPress={this.handlePrimaryButtonPress}
        secondaryButtonLabel={translate(
          'onboarding.sendDocuments.attachContractChange.transparentButton',
        )}
        documentType={DocumentTypes.ARTICLES_OF_ASSOCIATION}
      />
    );
  }
}

export default withOnboardingNavigation(
  AttachContractChange,
  ONBOARDING_SCREENS.attachContractChange,
);
