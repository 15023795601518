import React, {ReactElement, useState} from 'react';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {RequestErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {getIncomeReports} from '@vizir-banking/banking-app-core/dist/redux/income-report/get-income-reports';
import {sendIncomeReport} from '@vizir-banking/banking-app-core/dist/redux/income-report/send-income-report';
import {connect} from 'react-redux';
import {useApplicationFromContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {IncomeReportCollection} from '@vizir-banking/banking-app-core/dist/entities/income-report-collection';
import {bindActionCreators, Dispatch} from 'redux';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {SendReportData} from '@vizir-banking/banking-app-core/dist/api/income-report/types';

import {HOME_SCREENS} from '~/navigation/screen-definitions';
import {IncomeReportScreen} from '~/settings/screens/income-report/income-report';
import {SuccessDialog} from '~/settings/screens/income-report/success-dialog/success-dialog';

import {UnavailableReportDialog} from './unavailable-report-dialog/unavailable-report-dialog';

export type Props = NavigationProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BaseIncomeReportScreenContainer = (props: Props): ReactElement => {
  const {
    resetFromHomeAndNavigate,
    currentUser: {accountIds, email},
    actions,
  } = props;
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isUnavailableDialogOpen, setIsUnavailableDialogOpen] = useState(false);
  const {bankingCoreHttpClient} = useApplicationFromContext();

  const sendUserIncomeReport = async (): Promise<void> => {
    if (!accountIds) return;
    const accountId = accountIds[0].toString();

    const reportCollection = await actions.getIncomeReports(
      accountId,
      bankingCoreHttpClient,
    );

    if (reportCollection.isEmpty()) {
      setIsUnavailableDialogOpen(true);
      return;
    }

    const isSuccess = await actions.sendIncomeReport(
      accountId,
      {
        reportCode: reportCollection.getNewest().reportCode,
      },
      bankingCoreHttpClient,
    );
    if (isSuccess) setIsSuccessDialogOpen(true);
  };

  const handleSuccessDialogConfirm = (): void => {
    resetFromHomeAndNavigate(HOME_SCREENS.home);
  };

  const handleSuccessDialogClose = (): void => {
    setIsSuccessDialogOpen(false);
  };

  const handleUnavailableDialogClose = (): void => {
    setIsUnavailableDialogOpen(false);
  };

  const handleSend = async (): Promise<void> => {
    await sendUserIncomeReport();
  };

  const handleCancel = (): void => {
    resetFromHomeAndNavigate(HOME_SCREENS.home);
  };

  return (
    <>
      <SuccessDialog
        email={email}
        show={isSuccessDialogOpen}
        onConfirm={handleSuccessDialogConfirm}
        onClose={handleSuccessDialogClose}
      />
      <UnavailableReportDialog
        show={isUnavailableDialogOpen}
        onClose={handleUnavailableDialogClose}
      />
      <RequestErrorDialog />
      <IncomeReportScreen
        email={email}
        onSend={handleSend}
        onCancel={handleCancel}
      />
    </>
  );
};

type State = {
  currentUser: Partial<User>;
};

const mapStateToProps = ({user}: ReduxState): State => ({
  currentUser: user.currentUser,
});

const actionCreators = {getIncomeReports, sendIncomeReport};

type Actions = {
  getIncomeReports: (
    externalAccountId: string,
    httpClient: BankingCoreHttpClient,
  ) => Promise<IncomeReportCollection>;
  sendIncomeReport: (
    externalAccountId: string,
    reportData: SendReportData,
    httpClient: BankingCoreHttpClient,
  ) => Promise<boolean>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export const IncomeReportScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(BaseIncomeReportScreenContainer));
