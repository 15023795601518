import {ReactElement} from 'react';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import '~/home/navigation/register-screens';
import '~/onboarding/navigation/register-screens';
import '~/settings/navigation/register-screens';

import {REACT_APP_ONBOARDING_ONLY, REACT_APP_NEW_LOGIN_FLOW} from '~/env';

export const registerScreens = (): ReactElement[] => {
  if (REACT_APP_ONBOARDING_ONLY) {
    RoutesManager.removeSections(
      (section): boolean => section !== 'onboarding',
    );
  }

  if (REACT_APP_NEW_LOGIN_FLOW) {
    return RoutesManager.getAllRoutes().map((appRoute) => {
      appRoute.unauthorizedRoute = '/login/document';
      return appRoute.toReact();
    });
  }

  return RoutesManager.generateReactRoutes();
};
