import styled from 'styled-components';
import {Screen as BaseScreen} from '@vizir-banking/banking-app-core/dist/common';
import {
  Icon,
  Text,
  Bold,
  View,
  Image,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

export const Screen = styled(BaseScreen)`
  padding: 0;
`;

export const Wrapper = styled(View)`
  flex-grow: 1;
  padding: 38px 38px 76px;
`;

export const SuccessIcon = styled(Icon).attrs(({theme}: {theme: Theme}) => ({
  name: 'success',
  size: 120,
  backgroundColor: theme.colors.primary,
  checkColor: theme.colors.textAlternative,
}))``;

export const TitleWrapper = styled(View)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Bold)`
  font-size: 25px;
  text-align: center;
  margin-top: 40px;
  color: ${useTheme(({colors}) => colors.textOverPrimary)};
`;

export const Message = styled(Text)`
  font-size: 18px;
  margin-top: 32px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textSecondary)};
`;

export const LogoWrapper = styled(View)`
  margin-top: 30px;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-around;
`;

export const Logo = styled(Image)`
  width: 134px;
  height: 40px;
`;

export const BottomBar = styled(View)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background-color: ${useTheme(({colors}) => colors.button.background.primary)};
`;
