import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import t from '~/i18n/translate';
import {Image} from '~/onboarding/screens/send-document/base-screens/shared.styles';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import image from '~/assets/images/residential.png';
import DocumentComponent from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document';

const translate = (key: string): string => t(`onboarding.sendDocuments.${key}`);

type Props = ReturnType<typeof mapStateToProps> & OnboardingNavigationProps;

class PhotoResidential extends React.PureComponent<Props> {
  onCreateUser = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  renderImage = (): ReactElement => {
    return <Image source={image} />;
  };

  render(): ReactElement {
    return (
      <DocumentComponent
        documentPreviewChildren={this.renderImage()}
        documentPreviewTitle={translate('photoResidential.title')}
        documentPreviewMessage={translate('photoResidential.message')}
        documentPreviewSubMessage={''}
        documentType={DocumentTypes.PROOF_OF_ADDRESS}
        hasGallery
        onConfirmPicture={this.onCreateUser}
        onTransparentButtonPress={this.onCreateUser}
      />
    );
  }
}

type State = {
  isMei: boolean;
  isCompanyFlow: boolean;
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  isMei: onboarding.isMei,
  isCompanyFlow: onboarding.isCompanyFlow,
});

export default connect(mapStateToProps)(
  withOnboardingNavigation(
    PhotoResidential,
    ONBOARDING_SCREENS.photoResidential,
  ),
);
