import React, {ReactElement} from 'react';
import {
  Screen,
  ScreenTypes,
  HtmlView,
} from '@vizir-banking/banking-app-core/dist/common';

import {ScrollView} from '~/settings/components/terms/terms.styles';

type Props = {
  title: string;
  description: string;
};

const TermsScreen = (props: Props): ReactElement => {
  const {title, description} = props;

  return (
    <Screen title={title} type={ScreenTypes.LOGGED}>
      <ScrollView showsVerticalScrollIndicator scrollEventThrottle={16}>
        <HtmlView htmlContent={description} />
      </ScrollView>
    </Screen>
  );
};

export default TermsScreen;
