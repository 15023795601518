import {EstablishmentFormat} from '@vizir-banking/banking-app-core/dist/entities/user/enums/establishment-format';

export const legalNatureRelations = {
  [EstablishmentFormat.MEI]: ['213-5'],
  [EstablishmentFormat.EI]: ['213-5'],
  [EstablishmentFormat.EIRELI]: ['231-3', '230-5'],
  [EstablishmentFormat.LTDA]: ['206-2'],
  [EstablishmentFormat.SS]: ['224-0', '225-9', '223-2', '226-7'],
  [EstablishmentFormat.SA]: ['205-4', '204-6'],
  [EstablishmentFormat.ME]: ['206-2', '213-5'],
  [EstablishmentFormat.COOP]: ['233-0', '214-3'],
  [EstablishmentFormat.EPP]: [],
  [EstablishmentFormat.EMPG]: [],
};
