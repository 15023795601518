import {NotificationAdapter} from '@vizir-banking/banking-app-core/dist/notification/adapters/notification-adapter';
import {Notification} from '@vizir-banking/banking-app-core/dist/entities/notification/notification';

import {Listener} from '~/navigation/listeners/listener';

export class NotificationListener implements Listener {
  public constructor(
    private readonly notificationAdapter: NotificationAdapter,
    private readonly handleNotification: (notification: Notification) => void,
  ) {
    this.setListener = this.setListener.bind(this);
  }

  public setListener(): void {
    this.notificationAdapter.setListener(this.handleNotification);
  }

  public unsetListener(): void {
    this.notificationAdapter.unsetListener();
  }
}
