import React, {ReactElement} from 'react';

import {ApplicationProvider} from '~/providers/application/application-provider';
import {PixProvider} from '~/providers/pix/pix-provider';

import {PluginsProvider} from './plugins/plugins-provider';

export class WithContextProviders extends React.PureComponent {
  render(): ReactElement {
    return (
      <ApplicationProvider>
        <PluginsProvider>
          <PixProvider>{this.props.children}</PixProvider>
        </PluginsProvider>
      </ApplicationProvider>
    );
  }
}
