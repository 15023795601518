import React, {useState, useEffect, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import Screen from '@vizir-banking/banking-app-core/dist/common/screen/screen';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {Props} from '@vizir-banking/banking-app-core/dist/common/screen/types';

import {getNavigationProgress} from '~/onboarding/navigation/with-onboarding-navigation';

const OnboardingScreenComponent = (
  props: Props & NavigationProps,
): ReactElement => {
  const {currentRoute = ''} = props;

  const [progressSize, setProgressSize] = useState(0);

  const {isCompanyFlow, isMei} = useSelector((state: ReduxState) => ({
    isCompanyFlow: state.onboarding.isCompanyFlow,
    isMei: state.onboarding.isMei,
  }));

  useEffect(() => {
    setProgressSize(
      getNavigationProgress({isCompanyFlow, isMei}, currentRoute),
    );
  }, [currentRoute, isCompanyFlow, isMei]);

  return <Screen {...props} progressSize={progressSize} />;
};

export const OnboardingScreen = withNavigation(OnboardingScreenComponent);
