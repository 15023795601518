import React, {ReactElement} from 'react';
import {SlideUpModal} from '@vizir-banking/banking-app-core/dist/layout';

import translate from '~/i18n/translate';
import rgBack from '~/assets/images/rg-back.jpg';
import rgFront from '~/assets/images/rg-front.jpg';
import cnhBack from '~/assets/images/cnh-back.jpg';
import cnhFront from '~/assets/images/cnh-front.jpg';
import crnmBack from '~/assets/images/crnm-back.jpg';
import crnmFront from '~/assets/images/crnm-front.jpg';
import {
  Title,
  ImageWrapper,
  Image,
} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-image-tips-modal/photo-document-image-tips-modal.styles';

import {Props as PhotoDocumentImageTipsModalProps} from './types';

type Props = PhotoDocumentImageTipsModalProps & {
  children?: React.ReactElement;
};

const PhotoDocumentImageTipsModal = ({
  show,
  onClose,
  documentType,
  children,
}: Props): ReactElement => {
  const getDocumentSide = (): string => {
    if (documentType.includes('VERSE')) {
      return translate('onboarding.sendDocuments.documentImages.tips.back');
    }

    return translate('onboarding.sendDocuments.documentImages.tips.front');
  };

  const getDocumentImage = (): string | undefined => {
    if (documentType.includes('IDENTITY_CARD')) {
      return getIdentityCard();
    }

    if (documentType.includes('DRIVER_LICENSE')) {
      return getDriverLicense();
    }

    if (documentType.includes('RNE')) {
      return getForeignCard();
    }
  };

  const getIdentityCard = (): string => {
    return documentType.includes('VERSE') ? rgBack : rgFront;
  };

  const getDriverLicense = (): string => {
    return documentType.includes('VERSE') ? cnhBack : cnhFront;
  };

  const getForeignCard = (): string => {
    return documentType.includes('VERSE') ? crnmBack : crnmFront;
  };

  return (
    <SlideUpModal
      show={show}
      title={translate('onboarding.sendDocuments.documentImages.tips.title')}
      onClose={onClose}
    >
      <Title>{getDocumentSide()}</Title>
      <ImageWrapper>
        <Image source={getDocumentImage()} />
      </ImageWrapper>
      {children}
    </SlideUpModal>
  );
};

export default PhotoDocumentImageTipsModal;
