import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {formatToPhone} from 'brazilian-values';
import {bindActionCreators, Dispatch} from 'redux';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {UpdateUserProfile} from '@vizir-banking/banking-app-core/dist/api/user/types';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import updateUserProfile from '@vizir-banking/banking-app-core/dist/redux/user/update-user-profile';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import UserEntity from '@vizir-banking/banking-app-core/dist/entities/user/user';

import FormFieldDisabled from '~/settings/components/form-field-disabled/form-field-disabled';
import translate from '~/i18n/translate';

import {Form} from './styles';
import schema from './schema';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class EditProfileForm extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  onSubmit = (formFields: UpdateUserProfile): void => {
    const {actions} = this.props;
    const context = this.context as ApplicationContextType;

    actions.updateUserProfile(formFields, context.bankingCoreHttpClient);
  };

  removeCountryCode(phone: string): string {
    return phone.slice(phone.length - 11);
  }

  render(): ReactElement {
    const {currentUser} = this.props;
    const user = new UserEntity(currentUser);
    const {name = '', email = '', phone = ''} = user;

    return (
      <>
        <Form
          schema={schema}
          onSubmit={this.onSubmit}
          initialValues={{
            name,
            email,
          }}
        >
          <FormFieldDisabled
            label={translate('settings.editProfile.form.name')}
            value={name}
          />
          <FormFieldDisabled
            label={translate(`settings.documents.${user.documentType()}`)}
            value={user.formattedDocument()}
          />
          <FormFieldDisabled
            label={translate('settings.editProfile.form.phoneNumber')}
            value={formatToPhone(this.removeCountryCode(phone))}
          />
          <FormFieldDisabled
            label={translate('settings.editProfile.form.email')}
            value={email}
          />
        </Form>
      </>
    );
  }
}

type State = {
  currentUser: Partial<User>;
  isLoading: boolean;
};

const mapStateToProps = ({user, application}: ReduxState): State => ({
  currentUser: user.currentUser,
  isLoading: application.isLoading,
});

type Actions = {
  updateUserProfile: (
    formFields: UpdateUserProfile,
    httpClient: BankingCoreHttpClient,
  ) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({updateUserProfile}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
