import {AppRoute} from '@vizir-banking/banking-app-core/dist/navigation/app-route/app-route';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import AccountCreatedScreen from '~/onboarding/screens/account-created/account-created';
import AccountCreatingScreen from '~/onboarding/screens/account-creating/account-creating';
import AnnualRevenue from '~/onboarding/screens/annual-revenue/annual-revenue.container';
import AttachContractChange from '~/onboarding/screens/send-document/attach-contract-change/attach-contract-change';
import AttachSocialContract from '~/onboarding/screens/send-document/attach-social-contract/attach-social-contract';
import CompanyFantasyName from '~/onboarding/screens/company-fantasy-name/company-fantasy-name';
import MeiDocument from '~/onboarding/screens/mei-document/mei-document';
import MeiInfo from '~/onboarding/screens/mei-info/mei-info';
import NecessaryPartnerDocumentsScreen from '~/onboarding/screens/necessary-documents/necessary-documents';
import OnboardingAccessKey from '~/onboarding/screens/access-key/access-key';
import OnboardingAddress from '~/onboarding/screens/address/address';
import OnboardingBirthday from '~/onboarding/screens/birthday/birthday';
import OnboardingCnae from '~/onboarding/screens/cnae/cnae';
import OnboardingCnpjInfo from '~/onboarding/screens/cnpj-info/cnpj-info';
import OnboardingCode from '~/onboarding/screens/code/code';
import OnboardingCompanyPartners from '~/onboarding/screens/company-partners/company-partners';
import OnboardingCompanyStatus from '~/onboarding/screens/company-status/company-status.container';
import OnboardingDocument from '~/onboarding/screens/document/document';
import OnboardingEmail from '~/onboarding/screens/email/email';
import OnboardingIdentity from '~/onboarding/screens/identity';
import OnboardingLegalNature from '~/onboarding/screens/legal-nature/legal-nature';
import OnboardingMotherName from '~/onboarding/screens/mother-name/mother-name';
import OnboardingName from '~/onboarding/screens/name/name';
import OnboardingPassword from '~/onboarding/screens/password/password';
import OnboardingPhoneNumber from '~/onboarding/screens/phone-number/phone-number';
import OnboardingStateInscription from '~/onboarding/screens/state-inscription/state-inscription.container';
import OnboardingTermsConditions from '~/onboarding/screens/terms-conditions/terms-conditions';
import OnboardingZipCode from '~/onboarding/screens/zip-code/zip-code';
import PhotoIdentificationDocument from '~/onboarding/screens/send-document/photo-identification-document/photo-identification-document';
import PhotoIdentificationDocumentBack from '~/onboarding/screens/send-document/photo-identification-document/photo-identification-document-back';
import PhotoResidential from '~/onboarding/screens/send-document/photo-residential/photo-residential';
import PhotoSelfie from '~/onboarding/screens/send-document/photo-selfie/photo-selfie';
import PoliticallyExposedPerson from '~/onboarding/screens/politically-expose-person';
import Profession from '~/onboarding/screens/profession';
import MeiAccountCreating from '~/onboarding/screens/mei-account-creating/mei-account-creating';
import ValidateDocumentPhotos from '~/onboarding/screens/validate-document-photos/validate-document-photos';
import {REACT_APP_BETA_ACCESS_KEY} from '~/env';
import definitions from '~/onboarding/navigation/screen-definitions';

RoutesManager.addSection('onboarding', definitions, (): AppRoute[] => {
  const routes = [
    new AppRoute(
      definitions.start,
      REACT_APP_BETA_ACCESS_KEY ? OnboardingAccessKey : OnboardingPhoneNumber,
    ),
    new AppRoute(definitions.birthday, OnboardingBirthday),
    new AppRoute(definitions.code, OnboardingCode),
    new AppRoute(definitions.document, OnboardingDocument),
    new AppRoute(definitions.identity, OnboardingIdentity),
    new AppRoute(definitions.email, OnboardingEmail),
    new AppRoute(definitions.motherName, OnboardingMotherName),
    new AppRoute(definitions.name, OnboardingName),
    new AppRoute(definitions.accessKey, OnboardingAccessKey),
    new AppRoute(definitions.phoneNumber, OnboardingPhoneNumber),
    new AppRoute(definitions.zipCode, OnboardingZipCode),
    new AppRoute(definitions.address, OnboardingAddress),
    new AppRoute(definitions.password, OnboardingPassword),
    new AppRoute(definitions.stateInscription, OnboardingStateInscription),
    new AppRoute(definitions.termsConditions, OnboardingTermsConditions),
    new AppRoute(definitions.cnae, OnboardingCnae),
    new AppRoute(definitions.legalNature, OnboardingLegalNature),
    new AppRoute(definitions.cnpjInfo, OnboardingCnpjInfo),
    new AppRoute(definitions.companyPartners, OnboardingCompanyPartners),
    new AppRoute(definitions.companyStatus, OnboardingCompanyStatus),
    new AppRoute(
      definitions.photoIdentificationDocument,
      PhotoIdentificationDocument,
    ),
    new AppRoute(
      definitions.photoIdentificationDocumentBack,
      PhotoIdentificationDocumentBack,
    ),
    new AppRoute(definitions.photoSelfie, PhotoSelfie),
    new AppRoute(definitions.photoResidential, PhotoResidential),
    new AppRoute(definitions.accountCreated, AccountCreatedScreen),
    new AppRoute(
      definitions.necessaryPartnerDocuments,
      NecessaryPartnerDocumentsScreen,
    ),
    new AppRoute(definitions.attachContractChange, AttachContractChange),
    new AppRoute(definitions.attachSocialContract, AttachSocialContract),
    new AppRoute(definitions.meiInfo, MeiInfo),
    new AppRoute(definitions.meiDocument, MeiDocument),
    new AppRoute(definitions.companyFantasyName, CompanyFantasyName),
    new AppRoute(
      definitions.politicallyExposedPerson,
      PoliticallyExposedPerson,
    ),
    new AppRoute(definitions.profession, Profession),
    new AppRoute(definitions.annualRevenue, AnnualRevenue),
    new AppRoute(definitions.accountCreating, AccountCreatingScreen),
    new AppRoute(definitions.meiAccountCreating, MeiAccountCreating),
    new AppRoute(definitions.validateDocumentPhotos, ValidateDocumentPhotos),
  ];

  return routes;
});
