import styled from 'styled-components';
import {
  View,
  Icon as BaseIcon,
  Text as BaseText,
  Button as BaseButton,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(BaseIcon)`
  margin-bottom: 43px;
`;

export const MessageWrapper = styled(View)`
  align-items: center;
`;

export const Text = styled(BaseText)`
  font-size: 20px;
  color: ${useTheme(({colors}) => colors.textTertiary)};
  text-align: center;
  margin-bottom: 30px;
`;

export const Button = styled(BaseButton)``;
