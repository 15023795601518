import styled from 'styled-components';
import {
  View,
  Icon,
  Text,
  Touchable as PrimitiveTouchable,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  flex-grow: 1;
  justify-content: space-between;
`;

export const Touchable = styled(PrimitiveTouchable)`
  margin: 42px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AddIcon = styled(Icon).attrs({
  name: 'add',
  size: 25,
})`
  margin-right: 10px;
`;

export const AddButtonText = styled(Text)`
  font-size: 20px;
  color: ${useTheme(({colors}) => colors.button.text.transparent)};
`;
