import styled from 'styled-components';
import {
  View,
  Text,
  Bold,
  Icon as BaseIcon,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const ListItemWrapper = styled(View)`
  flex-direction: row;
`;

export const Icon = styled(BaseIcon)``;

export const LabelItemWrapper = styled(View)`
  margin-left: 10px;
`;

export const Label = styled(Text)`
  font-size: 16px;
  color: ${useTheme(({colors}) => colors.title.primary)};
`;

export const BoldLabel = styled(Bold)`
  font-size: 20px;
  text-align: left;
  color: ${useTheme(({colors}) => colors.title.primary)};
`;
