import React, {ReactElement} from 'react';
import isWeb from '@vizir-banking/banking-app-core/dist/utils/is-web';
import {Br, Link, Button} from '@vizir-banking/banking-app-core/dist/layout';
import {Types} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  REACT_APP_APPLE_STORE_URL,
  REACT_APP_PLAY_STORE_URL,
  REACT_APP_NEW_LOGIN_FLOW,
} from '~/env';
import apple from '~/assets/images/apple.png';
import google from '~/assets/images/google.png';
import {
  withOnboardingNavigation,
  OnboardingNavigationProps,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {
  Logo,
  Title,
  Screen,
  Message,
  Wrapper,
  BottomBar,
  LogoWrapper,
  SuccessIcon,
  TitleWrapper,
} from '~/onboarding/screens/account-created/account-created.styles';

const AccountCreated = ({
  navigate,
}: OnboardingNavigationProps): ReactElement => {
  const handleGoToLogin = (): void => {
    if (REACT_APP_NEW_LOGIN_FLOW) {
      navigate(RoutesManager.getRoute('login', 'document'));
      return;
    }

    navigate(RoutesManager.getRoute('login', 'login'));
  };

  const renderActions = (): ReactElement => {
    if (isWeb()) {
      return (
        <LogoWrapper>
          {REACT_APP_APPLE_STORE_URL && (
            <Link href={REACT_APP_APPLE_STORE_URL} target="_blank">
              <Logo source={apple} />
            </Link>
          )}
          {REACT_APP_PLAY_STORE_URL && (
            <Link href={REACT_APP_PLAY_STORE_URL} target="_blank">
              <Logo source={google} />
            </Link>
          )}
        </LogoWrapper>
      );
    }

    return (
      <Button
        text={translate('onboarding.accountCreated.buttonLabel')}
        onPress={handleGoToLogin}
      />
    );
  };

  return (
    <Screen navType={Types.NONE}>
      <Wrapper>
        <TitleWrapper>
          <SuccessIcon />
          <Title>{translate('onboarding.accountCreated.title')}</Title>
          <Message>
            {translate('onboarding.accountCreated.willBeValidated')}
            <Br />
            <Br />
            {translate('onboarding.accountCreated.startUsing')}
          </Message>
        </TitleWrapper>

        {renderActions()}
      </Wrapper>
      <BottomBar />
    </Screen>
  );
};

export default withOnboardingNavigation(
  AccountCreated,
  ONBOARDING_SCREENS.accountCreated,
);
