import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {FormValues} from '@vizir-banking/banking-app-core/dist/layout/form/types';
import {SingleInputForm} from '@vizir-banking/banking-app-core/dist/common';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {Props} from './types';

export type ComponentProps = Props & ReturnType<typeof mapStateToProps>;

const SingleInputFormContainer = ({
  user,
  content,
  onSubmit,
  reduxValueKey,
  validationSchemaName,
  ...rest
}: ComponentProps): ReactElement => {
  const getInitialValues = (): FormValues | undefined => {
    if (!user || !reduxValueKey) {
      return;
    }

    const value = user[reduxValueKey as keyof User];

    return {
      input: value,
    };
  };

  return (
    <SingleInputForm
      {...rest}
      content={content}
      onSubmit={onSubmit}
      validationSchemaName={validationSchemaName as SchemaNames}
      initialValue={getInitialValues()}
    />
  );
};

const mapStateToProps = ({
  onboarding,
}: ReduxState): {user: ReduxState['onboarding']['creationUser']} => ({
  user: onboarding.creationUser,
});

export default connect(mapStateToProps)(SingleInputFormContainer);
