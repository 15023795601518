import React, {ReactElement} from 'react';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {
  Text,
  Bold,
  MaskTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

import translate from '~/i18n/translate';
import CheckBox from '~/onboarding/components/check-box/check-box';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

import {Props} from './types';

class StateInscription extends React.Component<Props> {
  private form: any;
  static contextType = ApplicationContext;

  state = {
    checked: Boolean(this.props.isInscriptionFree),
    checkboxColor: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      checked: Boolean(props.isInscriptionFree),
      checkboxColor: null,
    };

    this.form = React.createRef();
  }

  setCheckboxColor = (color: string): void => {
    this.setState({checkboxColor: color});
  };

  handleError = (): void => {
    const {checked} = this.state;

    if (
      this.form.current.state.hasError &&
      !checked &&
      this.form.current.state.submitted
    ) {
      const context = this.context as ApplicationContextType;
      const theme: Theme = context.theme;
      this.setCheckboxColor(theme.colors.error.primary);
    }
  };

  handleChecked = (): void => {
    const {onCheckboxChange} = this.props;
    const {checked} = this.state;
    const context = this.context as ApplicationContextType;
    const theme: Theme = context.theme;

    this.form.current.setFieldValue('input', '');
    this.setCheckboxColor(theme.colors.checkbox.border);
    onCheckboxChange();
    this.setState({checked: !checked});
  };

  handleValueChange = (): void => {
    const {checked} = this.state;

    if (checked) {
      this.setState({checked: !checked});
    }

    const context = this.context as ApplicationContextType;
    const theme: Theme = context.theme;

    this.setCheckboxColor(theme.colors.checkbox.border);
  };

  handleSubmit = (value: string): void => {
    const {onSubmit} = this.props;

    onSubmit(value);
  };

  renderText = (): ReactElement => {
    return (
      <Text>
        {translate('onboarding.stateInscription.start')}
        <Bold>{translate('onboarding.stateInscription.stateInscription')}</Bold>
        {translate('onboarding.stateInscription.end')}
      </Text>
    );
  };

  render(): ReactElement {
    const {onSubmit} = this.props;
    const {checked} = this.state;

    const context = this.context as ApplicationContextType;
    const theme: Theme = context.theme;

    return (
      <OnboardingScreen showProgress isCompanyFlow>
        <SingleInputForm
          formRef={this.form}
          onError={this.handleError}
          onChange={this.handleValueChange}
          reduxValueKey="stateInscription"
          validationSchemaName={SchemaNames.STATE_INSCRIPTION}
          extraValidationData={{isInscriptionFree: checked}}
          content={this.renderText}
          onSubmit={onSubmit}
          textInputMask={MaskTypes.STATE_INSCRIPTION}
          maxLength={15}
          keyboardType="number-pad"
        >
          <CheckBox
            checkboxStyle={{
              borderColor:
                this.state.checkboxColor ?? theme.colors.checkbox.border,
            }}
            text={translate('onboarding.stateInscription.checkboxLabel')}
            checked={checked}
            onChangeValue={this.handleChecked}
          />
        </SingleInputForm>
      </OnboardingScreen>
    );
  }
}

export default StateInscription;
