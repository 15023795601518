import React, {ReactElement} from 'react';
import {SlideUpModal} from '@vizir-banking/banking-app-core/dist/layout';

import translate from '~/i18n/translate';
import {
  TipsWrapper,
  ListItem,
  ItemIcon,
  ItemTip,
} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-tips-modal/photo-document-tips-modal.styles';

import {Props as PhotoDocumentTipsModalProps} from './types';

type Props = PhotoDocumentTipsModalProps & {
  children?: React.ReactElement;
};

const PhotoDocumentTipsModal = ({
  show,
  onClose,
  tips,
  children,
}: Props): ReactElement => {
  const renderTips = (): ReactElement[] =>
    tips.map(({iconName, iconSize, tip}, index) => (
      <ListItem isFirst key={index}>
        <ItemIcon name={iconName} size={iconSize} />
        <ItemTip>{tip}</ItemTip>
      </ListItem>
    ));

  return (
    <SlideUpModal
      show={show}
      title={translate('onboarding.sendDocuments.photoDocument.tips.title')}
      onClose={onClose}
    >
      <TipsWrapper data-testid="slideUpModal">{renderTips()}</TipsWrapper>
      {children}
    </SlideUpModal>
  );
};

export default PhotoDocumentTipsModal;
