import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Bold, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const actionCreators = {addCreationUser};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

class NameScreen extends React.PureComponent<Props> {
  submit = (value: string): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('name', value);

    navigateToNextOnboardingScreen();
  };

  renderText(): ReactElement {
    return (
      <>
        <Text>{translate('onboarding.WhatsYour')}</Text>
        <Bold>{translate('onboarding.fullName')}</Bold>?
      </>
    );
  }

  render(): Element {
    return (
      <OnboardingScreen showProgress>
        <SingleInputForm
          reduxValueKey="name"
          validationSchemaName={SchemaNames.NAME}
          content={this.renderText}
          onSubmit={this.submit}
          maxLength={100}
          autoCapitalize="words"
        />
      </OnboardingScreen>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withOnboardingNavigation(NameScreen, ONBOARDING_SCREENS.name));
