import React, {ReactElement} from 'react';
import {BaseCodeScreen} from '@vizir-banking/banking-app-core/dist/common';
import {codeTypes} from '@vizir-banking/banking-app-core/dist/common/code-screen/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';

type Props = OnboardingNavigationProps;

class CodeScreen extends React.PureComponent<Props> {
  submit = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        <BaseCodeScreen onSubmit={this.submit} type={codeTypes.ONBOARDING} />
      </OnboardingScreen>
    );
  }
}

export default withOnboardingNavigation(CodeScreen, ONBOARDING_SCREENS.code);
