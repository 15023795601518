import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
  Bold,
  Text,
  MaskTypes,
  Br,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const actionCreators = {addCreationUser};

type Actions = {addCreationUser: (fielName: keyof User, value: string) => void};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export type Props = ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

export class CnaeScreen extends React.PureComponent<Props> {
  submit = (value: string): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('cnae', value);
    navigateToNextOnboardingScreen();
  };

  renderText(): ReactElement {
    return (
      <>
        <Text>{translate('onboarding.cnae.tellUs')}</Text>
        <Br />
        <Text>
          {`${translate('onboarding.cnae.whatIs')} `}
          <Bold>{translate('onboarding.cnae.cnae')}</Bold>?
        </Text>
      </>
    );
  }

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        <SingleInputForm
          validationSchemaName={SchemaNames.CNAE}
          content={this.renderText}
          reduxValueKey="cnae"
          textInputMask={MaskTypes.CNAE}
          onSubmit={this.submit}
          keyboardType="numeric"
        />
      </OnboardingScreen>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withOnboardingNavigation(CnaeScreen, ONBOARDING_SCREENS.cnae));
