import {css} from 'styled-components';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const ScreenStyles = css`
  padding: 0;
`;

export const ContainerStyles = css`
  flex-grow: 1;
`;

export const ImagePreviewStyles = css`
  height: 1px;
  flex-grow: 1;
`;

export const ActionAreaStyles = css`
  align-items: center;
  padding: 16px ${useTheme(({sizes}) => sizes.contentPadding)}px;
`;

export const ConfirmationTextStyles = css`
  font-size: 18px;
  color: ${useTheme(({colors}) => colors.textOverPrimary)};
  margin-bottom: 16px;
`;

export const ButtonsWrapperStyles = css`
  width: 100%;
  flex-direction: row;
`;

export const ActionButtonStyles = css`
  flex: 1;
`;

export const BaseNavbarStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;
