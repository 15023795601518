import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('en', 'app', {
  DefaultSuccessMessage: 'Successful request!',
  CameraTitlePermission: 'Permission to use camera',
  CameraMessagePermission: 'We need your permission to use your camera',
  FailedToTakePicture:
    'We could not take a picture, please check if the permissions were not denied and try again.',
  PositiveLabel: 'Ok',
  NegativeLabel: 'Cancel',
  AudioTitlePermission: 'Permission to use audio recording',
  AudioMessagePermission: 'We need your permission to use your audio',
  CameraHint:
    'Now place your phone directly over your document and take a photograph',
  decimalSeparator: '.',
  thousandsSeparator: ',',
  dialog: {close: 'Close'},
  moneyPrefix: 'R$',
  dateFormat: 'MM/DD/YYYY',
  yourBalance: 'Your balance',
  drawer: {
    aliasBank: {
      branch: 'Branch',
      account: 'Account',
      bank: 'Bank',
    },
    digitalAccount: 'Digital Account',
    statement: 'Statement',
    transfer: 'Bank Transfer',
    payment: 'Bills Payment',
    depositBillet: 'Add money',
    requestCard: 'Card request',
    unlockCard: 'Card unlock',
    myCard: 'My card',
    virtualCard: 'Virtual Card',
    myDetails: 'Profile',
    terms: 'Terms and Conditions',
    faq: 'Faq',
    logout: 'Logout',
    version: 'Version',
  },
  shareTitle: 'Share',
  apiErrors: {
    GENERIC_ERROR: {
      title: 'Attention',
      message:
        'There was a problem processing your request. Please try again or contact our support.',
    },
    BANK_API_USER_BLOCKED: {
      title: 'Blocked credentials',
      message:
        'Your credentials are blocked. Please contact our support team {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    BANK_API_UPDATE_PASSWORD_BLOCKED: {
      title: 'Attention',
      message:
        'Your credentials are partially blocked for password update. Please contact our support team {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    BANK_API_CARD_BLOCKED: {
      title: 'Attention',
      message: 'Your card was blocked, please contact our support',
    },
    BANK_API_CARD_CREATE_ERROR: {
      title: 'Attention',
      message:
        'There was a problem processing your request. Please try again or contact our support.',
    },
    BANK_API_GET_TERMS_FAILED: {
      title: 'Attention',
      message: 'There was a problem loading the terms, please try again.',
    },
    BANK_API_CARD_MAX_PASSWORD_RETRIES: {
      title: 'Attention',
      message:
        'There were too many wrong password attempts, please contact our support',
    },
    BANK_API_SEND_DOCUMENTS_FAILED: {
      title: 'Attention',
      message:
        'There was a problem sending documents, please try again or contact our support.',
    },
    BANK_API_ACCOUNT_NOT_FOUND: {
      title: 'Attention',
      message: 'Destination account not found.',
    },
    BANK_API_USER_INVALID_PASSWORD: {
      title: 'Attention',
      message: 'Invalid user and/or password',
    },
    BANK_API_UNAUTHORIZED: {
      title: 'Attention',
      message: 'Your session expired, please sign in again.',
    },
    BANK_API_CARD_DOESNT_BELONG_TO_ACCOUNT: {
      title: 'Attention',
      message:
        'The card does not belong to the current account, please sign in again.',
    },
    BANK_API_CARD_INVALID_PASSWORD: {
      title: 'Attention',
      message: 'The informed password is invalid.',
    },
    BANK_API_CARD_QUANTITY_EXCEEDED: {
      title: 'Attention',
      message: 'You exceeeded the monthly virtual cards limit',
    },
    BANK_API_COMPANY_WAITING_PARTNERS_REGISTRY: {
      title: 'Attention',
      message: 'Your account is incomplete. Waiting partners registration.',
    },
    BANK_API_ACCOUNT_WAITING_ANALYSIS: {
      title: 'Attention',
      message:
        'Your account is in analysis. We will make contact when we finish the process.',
    },
    BANK_API_ACCOUNT_REJECTED: {
      title: 'Attention',
      message:
        'Your accout was not approved. Please, contact central by the number {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}.',
    },
    BANK_API_CPF_ALREADY_TAKEN: {
      title: 'Attention',
      message: 'There is already an account for this document.',
    },
    BANK_API_PASSWORD_FAILED_TO_DECRYPT: {
      title: 'Attention',
      message:
        'There was a problem processing your request. Please try again or contact our support.',
    },
    BANK_API_PERSON_TELEPHONE_NOT_FOUND: {
      title: 'Attention',
      message:
        'The phone number does not belong to the current account, please sign in again.',
    },
    BANK_API_PHONE_DOESNT_BELONG_TO_ACCOUNT: {
      title: 'Attention',
      message:
        'The phone number does not belong to the current account, please sign in again.',
    },
    BANK_API_SESSION_EXPIRED: {
      title: 'Session expired',
      message: 'Please sign in again.',
    },
    BANK_API_ONBOARDING_SESSION_EXPIRED: {
      title: 'Session expired',
      message: 'Start creating your account again.',
    },
    BANK_API_SMS_FAILED: {
      title: 'Attention',
      message:
        'There was a problem sending the code to your number. Please try again or contact our support.',
    },
    BANK_API_VERIFICATION_CODE_IS_INVALID: {
      title: 'Attention',
      message: 'The informed verification code is invalid.',
    },
    USER_CREATION_FAILED: {
      title: 'Attention',
      message:
        'Error completing registration. Please try again or contact support.',
    },
    BANK_API_BILLET_ALREADY_PAID: {
      title: 'Attention',
      message: 'This billet has already been paid',
    },
    BANK_API_BILLET_EXPIRED: {
      title: 'Attention',
      message: 'Billet expired',
    },
    BANK_API_BILLET_INVALID: {
      title: 'Attention',
      message: "We don't recognize your billet",
    },
    BANK_API_BILLET_PAYMENT_FAILED: {
      title: 'Attention',
      message: 'There was a problem processing your payment, please try again.',
    },
    BANK_API_BILLET_PAYMENT_TIME_NOT_ALLOWED: {
      title: 'Attention',
      message:
        'The authorized time window for payment of this billet is from {{minTime}} to {{maxTime}} (GMT-3), from Monday to Friday (except holidays). Payment will be scheduled to {{dateOrTime}.',
    },
    BANK_API_CHECK_PASSWORD_FAILED: {
      title: 'Attention',
      message: 'Current password incorrect.',
    },
    BANK_API_UPDATE_PASSWORD_FAILED: {
      title: 'Attention',
      message:
        'There was a problem changing your password, please try again or contact our support.',
    },
    BANK_API_RESET_PASSWORD_FAILED: {
      title: 'Attention',
      message:
        'There was a problem recovering you password, please try again or contact our support.',
    },
    BANK_API_USER_DOCUMENT_ALREADY_EXISTS: {
      title: 'Attention',
      message: 'User is already registered.',
    },
    BANK_API_INVALID_RECIPIENT_ACCOUNT: {
      title: 'Attention',
      message:
        'We could not find the recipient account. Please verify and try again.',
    },
    BANK_API_TRANSFER_FAILED: {
      title: 'Attention',
      message:
        "Oops! Your balance is insufficient to make this transfer. In addition to the transferred amount, don't forget the TED rate.",
    },
    BANK_API_BILLET_GENERATION_FAILED: {
      title: 'Attention',
      message:
        'There was a problem requesting the generation of your billet. Please check your balance and try again.',
    },
    BANK_API_USER_INVALID_DOCUMENT: {
      title: 'Attention',
      message: 'Invalid document.',
    },
    BANK_API_USER_NOT_REGULAR_DOCUMENT: {
      title: 'Attention',
      message: 'Document status is not regular.',
    },
    BANK_API_USER_NAME_NOT_MATCH: {
      title: 'Attention',
      message:
        'The name inserted does not match with data extracted from official government sources.',
    },
    BANK_API_USER_NAME_INVALID_LENGT: {
      title: 'Attention',
      message: 'The name inserted should not have more than 100 characters.',
    },
    BANK_API_COMPANY_FANTASY_NAME_INVALID_LENGTH: {
      title: 'Attention',
      message:
        'The fatansy name inserted should not have more than 50 characters',
    },
    BANK_API_USER_MOTHER_NAME_NOT_MATCH: {
      title: 'Attention',
      message:
        "The mother's name inserted does not match with data extracted from official government sources.",
    },
    BANK_API_USER_BIRTH_NOT_MATCH: {
      title: 'Attention',
      message:
        'The date of birth inserted does not match with the data extracted from official government sources.',
    },
    BANK_API_USER_NOT_ALLOW_INFORMED_DOCUMENT: {
      title: 'Attention',
      message: 'Operation not allowed for the informed document.',
    },
    BANK_API_USER_BLACK_LIST: {
      title: 'Attention',
      message: 'Document number is currently in the Black List',
    },
    BANK_API_UNKNOWN_USER_DATA: {
      title: 'Attention',
      message: 'There was a unknown error processing your data',
    },
    BANK_API_COMPANY_INVALID_DATA: {
      title: 'Attention',
      message: 'We found some invalid data in your registration',
    },
    invalid_recipient: {
      title: 'Attention',
      message: 'This billet cannot be payed using Lucree Wallet.',
    },
    internal_error: {
      title: 'Attention',
      message:
        'There was a problem processing your request. Please try again or contact our support.',
    },
    contact_not_found: {
      title: 'Attention',
      message: 'Contact not found.',
    },
    bank_transfer_fail: {
      title: 'Attention',
      message: 'The transfer could not be completed. Try again later.',
    },
    billet_payment_failed: {
      title: 'Attention',
      message: 'There was an unknown error when paying the boleto',
    },
    billet_invalid_bank: {
      title: 'Attention',
      message:
        'Unfortunately, for the time being, we do not pay the bills of this institution.',
    },
    billet_invalid_barcode: {
      title: 'Attention',
      message:
        'It seems that the number of the ticket is not correct. Can you check it again before trying to go forward?',
    },
    billet_already_paid: {
      title: 'Attention',
      message:
        "It seems that this bill has already been paid. Don't you want to check it before trying again?",
    },
    billet_invalid_or_already_paid: {
      title: 'Attention',
      message:
        "It seems that this bill has already been paid or is not valid. Don't you want to check it before trying again?",
    },
    cannot_update_password_blocked_by_password: {
      title: 'Attention',
      message:
        'For security reasons, the status of your card does not allow this operation to be completed. If you want to regularize your situation, please contact us.',
    },
    card_blocked_incorrect_password: {
      title: 'Attention',
      message:
        'For security reasons, the status of your card does not allow this operation to be completed. If you want to regularize your situation, please contact us.',
    },
    card_for_password_validation_not_found: {
      title: 'Attention',
      message:
        'Unfortunately it is not possible to carry out operations without having at least one card associated with your account. To regularize your situation, request your card or create a virtual card. If this is not possible, please contact us.',
    },
    invalid_amount_to_be_financed: {
      title: 'Attention',
      message: 'Payments bellow R$20 are not allowed via Wallet',
    },
    invalid_cvv: {
      title: 'Invalid data',
      message: 'Check your card information.',
    },
    invalid_password: {
      title: 'Attention',
      message:
        'The password is incorrect. You have up to 3 attempts. After this your card will be blocked.',
    },
    destination_phone_not_found: {
      title: 'Attention',
      message: "The destination phone number wasn't found",
    },
    destination_account_not_found: {
      title: 'Attention',
      message:
        "The destination account wasn't found. Please enter the correct number.",
    },
    destination_account_inactive: {
      title: 'Attention',
      message:
        'It is not possible to transfer to this account at this time. Please try again later or confirm with the carrier that it is active.',
    },
    destination_account_is_the_same_account: {
      title: "Ops! there's something wrong",
      message: 'You have entered your own account number.',
    },
    update_password_blocked: {
      title: 'Attention',
      message:
        'Your credentials are partially blocked. Please contact our support to unblock {{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
    },
    current_credential_invalid: {
      title: 'Attention',
      message: 'Incorrect current password',
    },
    p2p_transfer_fail: {
      title: 'Attention',
      message: 'The transfer could not be completed. Try again later.',
    },
    proposal_in_progress: {
      title: 'Attention',
      message: 'You already have an in progress proposal.',
    },
    recent_rejection: {
      title: 'Attention',
      message: 'It is not possible to request a new credit yet.',
    },
    payment_authorization_failed: {
      title: 'Attention',
      message: 'Ops, process failed, try again later.',
    },
    payment_failed: {
      title: 'Attention',
      message: 'Ops, process failed, try again later.',
    },
    verification_code_is_invalid: {
      title: 'Attention',
      message: 'The verification code is invalid or expired',
    },
    wallet_card_not_allowed: {
      title: 'Attention',
      message: 'Card not allowed',
    },
    wallet_transaction_denied: {
      title: 'Attention',
      message:
        'There was a problem processing your request, please check your cvv and try again.',
    },
    wallet_invalid_payment_time_window: {
      title: 'Attention',
      message:
        'The authorized time window for payment of this billet is from 7H to 18:30H (GMT-3), from Monday to Friday (except holidays).',
    },
    card_validation_failed: {
      title: 'Unmatched value',
      message:
        'The amount you entered does not match the charge we sent and we were unable to verify your card.',
    },
    validation_max_retries_exceeded: {
      title: 'Attention',
      message: 'The maximum number of attempts has been exceeded.',
    },
    UNKNOWN_API_ERROR: {
      title: 'Attention',
      message:
        'There was a problem processing your request. Please try again or contact our support.',
    },
    BANK_API_CARD_VALIDATE_CVV_ERROR: {
      title: 'Invalid data',
      message: 'Check your card information.',
    },
    BANK_API_CARD_UNBLOCK_ERROR: {
      title: 'Attention',
      message:
        'For security reasons, the status of your card does not allow this operation to be completed. If you want to regularize your situation, please contact us.',
    },
    BANK_API_LACK_OF_REGULATORY_INFORMATION: {
      title: 'Transaction not allowed',
      message:
        'Transaction not allowed due to the lack of regulatory information. Please contact our support.',
    },
    BANK_API_USER_DOCUMENT_NOT_AUTHORIZED_ON_WHITELIST: {
      title: 'Restricted access',
      message: 'Your SSN is not authorized to create an account.',
    },
  },
  requestSuccessDialogTitle: 'Success!',
  genericErrorTitle: 'Attention',
  genericErrorMessage:
    'There was a problem processing your request. Please try again or contact our support.',
  customerName: '{{env.REACT_APP_CUSTOMER_NAME}}',
  contactEmail: '{{env.REACT_APP_CUSTOMER_CONTACT_EMAIL}}',
  contactPhone: '{{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
  button: {
    continue: 'Continue',
  },
  resetScreens: {
    appPassword: {
      smsCode: {
        resendSms: 'Send again',
        sendedSms: 'sms sended',
        smsSubmissionFailed: 'Sms submission has failed',
        invalidCode: 'The validation code is invalid',
        smsCodeTitle: 'We sent a code to your number by SMS. Insert it below:',
      },
    },
  },
  creditCard: {
    defaultCardHolderNamePlaceholder: 'Full Name',
    defaultCardExpiryMonthPlaceholder: 'MM',
    defaultCardExpiryYearPlaceholder: 'YY',
    defaultMainCardPlaceholder: '(Main)',
    validateCard: 'Press to validate your card',
    options: 'Options',
  },
  verificationMinValue: '0.50',
  verificationMaxValue: '10.00',
});
