import React, {useEffect, ReactElement} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {parseCurrencyToNumber} from '@vizir-banking/banking-app-core/dist/utils/format-currency';
import {formatNumber} from '@vizir-banking/banking-app-core/dist/utils/format-number';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {useApplicationFromContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {getProfessions} from '@vizir-banking/banking-app-core/dist/redux/onboarding/get-professions';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {Profession} from '@vizir-banking/banking-app-core/dist/redux/onboarding/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import ProfessionScreen from '~/onboarding/screens/profession/profession';

import {Values} from './types';

type Option = {value: string; label: string};

const mapProfessionsOptions = (option: Profession): Option => {
  return {
    value: option.conductorId,
    label: option.description,
  };
};

const buildProfessionsOptions = (options: Profession[]): Option[] => {
  return sortBy(options.map(mapProfessionsOptions), ['label']);
};

export const ProfessionContainer = (props: Props): ReactElement => {
  const applicationContext = useApplicationFromContext();

  useEffect(() => {
    props.actions.getProfessions(applicationContext.bankingCoreHttpClient);
  }, [applicationContext.bankingCoreHttpClient, props.actions]);

  const onSubmit = (values: Values): void => {
    props.actions.addCreationUser(
      'professionId',
      parseInt(values.professionId, 10),
    );
    props.actions.addCreationUser(
      'monthlyIncome',
      parseCurrencyToNumber(values.monthlyIncome),
    );
    props.navigateToNextOnboardingScreen();
  };

  return (
    <ProfessionScreen
      options={buildProfessionsOptions(props.professions)}
      professionId={props.professionId.toString()}
      monthlyIncome={formatNumber(props.monthlyIncome)}
      onSubmit={onSubmit}
    />
  );
};

const actionCreators = {addCreationUser, getProfessions};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string | number) => void;
  getProfessions: (httpClient: BankingCoreHttpClient) => void;
};

export const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

type State = {
  professions: Profession[];
  professionId: string;
  monthlyIncome: number;
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  professions: onboarding.professions,
  professionId: get(onboarding, 'creationUser.professionId', ''),
  monthlyIncome: get(onboarding, 'creationUser.monthlyIncome') as number,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(ProfessionContainer, ONBOARDING_SCREENS.profession));
