import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {
  Bold,
  Button,
  ButtonTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {ContentContainerTypes} from '@vizir-banking/banking-app-core/dist/common/screen/types';
import {Types as NavTypes} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {REACT_APP_BETA_ACCESS_KEY} from '~/env';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {
  Screen,
  Message,
  WrapMessage,
  SendEmailText,
  LinkText,
  AccessKeyInput,
  Link,
  Label,
  ErrorMessage,
} from '~/onboarding/screens/access-key/access-key.styles';

const AccessKey = (props: OnboardingNavigationProps): ReactElement => {
  const [accesskey, changeAccessKey] = React.useState('');
  const [error, changeError] = React.useState('');

  const handleChange = (text: string): void => {
    changeAccessKey(text);
    changeError('');
  };

  const handleSubmit = (): void => {
    if (accesskey !== REACT_APP_BETA_ACCESS_KEY) {
      return changeError(translate('onboarding.accessKey.invalidKey'));
    }

    props.navigateToNextOnboardingScreen();
  };

  const submitDisabled = (): boolean => {
    return accesskey.length < 1;
  };

  return (
    <Screen
      contentContainerType={ContentContainerTypes.SCROLLVIEW}
      navType={NavTypes.EMPTY}
    >
      <WrapMessage>
        <Message>
          <Trans
            i18nKey="onboarding.accessKey.callout"
            components={[<Bold />]}
          />
        </Message>
        <SendEmailText>
          {translate('onboarding.accessKey.contactPartOne')}
        </SendEmailText>
        <Link href={`mailto:${translate('app.contactEmail')}`}>
          <LinkText>{translate('app.contactEmail')}</LinkText>
        </Link>
        <SendEmailText>
          {translate('onboarding.accessKey.contactPartTwo')}
        </SendEmailText>
      </WrapMessage>

      <Message>
        <Label>{translate('onboarding.accessKey.collaboratorPartOne')} </Label>
        <Label>
          <Trans
            i18nKey="onboarding.accessKey.collaboratorPartTwo"
            components={[<Bold />]}
          />
        </Label>
      </Message>

      <AccessKeyInput
        hasError={error !== ''}
        value={accesskey}
        onChange={handleChange}
      />
      <ErrorMessage>{error}</ErrorMessage>
      <Button
        disabled={submitDisabled()}
        onPress={handleSubmit}
        type={ButtonTypes.PRIMARY}
        text={translate('app.button.continue')}
      />
    </Screen>
  );
};

export default withOnboardingNavigation(
  AccessKey,
  ONBOARDING_SCREENS.accessKey,
);
