import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import get from 'lodash/get';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {
  UserState,
  User,
} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {ApplicationState} from '@vizir-banking/banking-app-core/dist/redux/application/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import PoliticallyExposedPersonScreen from '~/onboarding/screens/politically-expose-person/politically-exposed-peson';

import {Values} from './types';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

export const PoliticallyExposedPersonContainer = (
  props: Props,
): ReactElement => {
  const onSubmit = (values: Partial<Values>): void => {
    props.actions.addCreationUser('isPEP', values.isPEP);
    props.navigateToNextOnboardingScreen();
  };

  return (
    <PoliticallyExposedPersonScreen onSubmit={onSubmit} isPEP={props.isPEP} />
  );
};

const actionCreators = {addCreationUser};

type Actions = {
  addCreationUser: (fieldName: keyof User, value?: boolean) => void;
};

export const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  isLoading: boolean;
  isPEP: boolean;
};

const mapStateToProps = ({
  user,
  application,
}: {
  user: UserState;
  application: ApplicationState;
}): State => ({
  isLoading: application.isLoading,
  isPEP: get(user, 'creationUser.isPEP'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    PoliticallyExposedPersonContainer,
    ONBOARDING_SCREENS.politicallyExposedPerson,
  ),
);
