import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {isCPF} from 'brazilian-values';
import {Dialog} from '@vizir-banking/banking-app-core/dist/layout';
import {RequestErrorDialog} from '@vizir-banking/banking-app-core/dist/common';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {CompanyPartner} from '@vizir-banking/banking-app-core/dist/entities/user/company-partner';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import CompanyPartnerForm from '~/onboarding/screens/company-partners/company-partner-form';
import sadFace from '~/assets/images/sad_face.png';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';
import {
  Title,
  HoldingMessage,
} from '~/onboarding/screens/company-partners/company-partners.styles';

const EXTRA_SPACE = 15;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OnboardingNavigationProps;

class CompanyPartnersScreen extends React.PureComponent<Props> {
  state = {
    isHolding: false,
  };

  scrollView = React.createRef<any>();

  scrollToError = (client: any): void => {
    if (!client) {
      return;
    }

    const y = Math.abs(client.top) + client.height + EXTRA_SPACE;
    const scrollTop = this.scrollView.current.scrollTop;

    this.scrollView.current.scrollTo(0, scrollTop - y);
  };

  handleCloseHoldingError = (): void => {
    this.setState({isHolding: false});
  };

  handleSubmit = (formsValues: CompanyPartner[]): void => {
    const hasindividualPartner = formsValues.find((partner) =>
      isCPF(partner.documentNumber),
    );

    if (!hasindividualPartner) {
      return this.setState({isHolding: true});
    }

    const {navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('partners', formsValues);

    navigateToNextOnboardingScreen();
  };

  render(): ReactElement {
    const {partnerFormValues} = this.props;

    return (
      <OnboardingScreen
        showProgress
        isCompanyFlow
        scrollViewRef={this.scrollView}
      >
        <RequestErrorDialog />
        <Dialog
          show={this.state.isHolding}
          title={translate('app.genericErrorTitle')}
          image={sadFace}
          onClose={this.handleCloseHoldingError}
        >
          <HoldingMessage>
            {translate('onboarding.partnerData.holdingMessage')}
          </HoldingMessage>
        </Dialog>
        <Title>{translate('onboarding.partnerData.partnerData')}</Title>
        <CompanyPartnerForm
          extraValidationData={{companyDocument: this.props.document}}
          initialValues={partnerFormValues}
          onSubmitFailed={this.scrollToError}
          onSubmit={this.handleSubmit}
        />
      </OnboardingScreen>
    );
  }
}

const actionCreators = {addCreationUser};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: CompanyPartner[]) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  document?: string;
  partnerFormValues: CompanyPartner[] | [];
};

const mapStateToProps = ({onboarding}: ReduxState): State => ({
  document: onboarding.creationUser.documentNumber,
  partnerFormValues: get(
    onboarding,
    'creationUser.partners',
    [],
  ) as CompanyPartner[],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(
    CompanyPartnersScreen,
    ONBOARDING_SCREENS.companyPartners,
  ),
);
