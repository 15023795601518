import React, {ReactElement} from 'react';
import {FormFieldTypes} from '@vizir-banking/banking-app-core/dist/common';
import {Text, Bold} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';

import translate from '~/i18n/translate';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const translateKey = (key: string): string =>
  translate(`onboarding.annualRevenue.${key}`);

type Props = {
  onSubmit: (value: string) => void;
};

const renderText = (): ReactElement => (
  <Text>
    {translateKey('title.normal')}
    <Bold>{translateKey('title.bold')}</Bold>
    {translateKey('title.normal2')}
  </Text>
);

const AnnualRevenueScreen = ({onSubmit}: Props): ReactElement => (
  <OnboardingScreen showProgress>
    <SingleInputForm
      maxLength={18}
      formFieldType={FormFieldTypes.MONEY}
      reduxValueKey="annualRevenue"
      validationSchemaName={SchemaNames.ANNUAL_REVENUE}
      content={renderText}
      onSubmit={onSubmit}
    />
  </OnboardingScreen>
);

export default AnnualRevenueScreen;
