import React, {ReactElement} from 'react';
import {Bold} from '@vizir-banking/banking-app-core/dist/layout';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {cleanDocuments} from '@vizir-banking/banking-app-core/dist/redux/onboarding/upload-document';

import t from '~/i18n/translate';
import DocumentComponent from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document';
import {
  Message,
  List,
} from '~/onboarding/screens/send-document/photo-identification-document/photo-identification-document.styles';
import PhotoDocumentListItem from '~/onboarding/screens/send-document/photo-identification-document/components/photo-identification-document-item-list';

const translate = (key: string): string => t(`onboarding.sendDocuments.${key}`);

type DocumentFrontType =
  | DocumentTypes.DRIVER_LICENSE_FRONT
  | DocumentTypes.IDENTITY_CARD_FRONT
  | DocumentTypes.RNE_FRONT;

type Props = ReturnType<typeof mapDispatchToProps> & {
  onConfirmPicture: () => void;
};

type State = {
  documentType?: DocumentFrontType;
};

class PhotoIdentificationDocument extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      documentType: undefined,
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  documentIdentificationListOptions = [
    {
      value: DocumentTypes.DRIVER_LICENSE_FRONT,
      label: (
        <PhotoDocumentListItem
          icon="cnh_list"
          boldLabel={translate('photoMainDocument.list.cnh.title')}
          label={translate('photoMainDocument.list.cnh.label')}
        />
      ),
    },
    {
      value: DocumentTypes.IDENTITY_CARD_FRONT,
      label: (
        <PhotoDocumentListItem
          icon="rg_list"
          boldLabel={translate('photoMainDocument.list.rg.title')}
          label={translate('photoMainDocument.list.rg.label')}
        />
      ),
    },
    {
      value: DocumentTypes.RNE_FRONT,
      label: (
        <PhotoDocumentListItem
          icon="rne_list"
          boldLabel={translate('photoMainDocument.list.rne.title')}
          label={translate('photoMainDocument.list.rne.label')}
        />
      ),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  tips = [
    {
      iconName: 'plastic_cnh',
      iconSize: 42,
      tip: translate('photoDocument.tips.document.plastic'),
    },
    {
      iconName: 'rne_photo',
      iconSize: 39,
      tip: translate('photoDocument.tips.document.frame'),
    },
    {
      iconName: 'rne',
      iconSize: 42,
      tip: translate('photoDocument.tips.document.surface'),
    },
    {
      iconName: 'light_fixture',
      iconSize: 44,
      tip: translate('photoDocument.tips.document.lights'),
    },
  ];

  handleItemSelected = (documentType: {value: DocumentFrontType}): void => {
    this.props.actions.cleanDocuments();
    this.setState({documentType: documentType.value});
  };

  getDocumentBackType = (): DocumentTypes | undefined => {
    const {documentType} = this.state;

    return documentType
      ? (documentType.replace('FRONT', 'VERSE') as DocumentTypes)
      : undefined;
  };

  getDocumentIndex = (): number => {
    return this.documentIdentificationListOptions.findIndex(
      (document) => document.value === this.state.documentType,
    );
  };

  renderMessage = (): ReactElement => {
    return (
      <Message>
        {translate('photoMainDocument.message.normal')}
        <Bold>{translate('photoMainDocument.message.bold')}</Bold>
      </Message>
    );
  };

  renderList = (): ReactElement => {
    return (
      <List
        hasSelectedIcon
        initialSelectedIndex={this.getDocumentIndex()}
        options={this.documentIdentificationListOptions}
        onItemSelected={this.handleItemSelected}
      />
    );
  };

  render(): ReactElement {
    return (
      <DocumentComponent
        hasGallery
        hasDocumentMask
        tips={this.tips}
        documentPreviewSubMessage={translate('photoMainDocument.subMessage')}
        documentPreviewMessage={this.renderMessage}
        documentPreviewChildren={this.renderList()}
        documentType={this.state.documentType}
        onConfirmPicture={this.props.onConfirmPicture}
        documentBackType={this.getDocumentBackType()}
        documentPreviewTitle={''}
      />
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
): {
  actions: {
    cleanDocuments: () => void;
  };
} => ({
  actions: bindActionCreators({cleanDocuments}, dispatch),
});

export default connect(null, mapDispatchToProps)(PhotoIdentificationDocument);
