import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {
  setSensorIsUsed,
  setUserDeclinedSensor,
} from '@vizir-banking/banking-app-core/dist/redux/sensor/sensor';

import {SensorSettings} from '~/settings/screens/sensor-settings/sensor-settings';

type State = {
  sensorIsUsed: boolean;
  userDeclinedSensor: boolean;
};

type Actions = {
  setSensorIsUsed: (value: boolean) => void;
  setUserDeclinedSensor: (value: boolean) => void;
};

const actionCreators = {
  setSensorIsUsed,
  setUserDeclinedSensor,
};

const mapStateToProps = ({sensor}: ReduxState): State => ({
  sensorIsUsed: sensor.sensorIsUsed,
  userDeclinedSensor: sensor.userDeclinedSensor,
});

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorSettings);
