import React, {ReactElement, useEffect} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {getTerms} from '@vizir-banking/banking-app-core/dist/redux/application/terms';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {useApplicationFromContext} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {TermType} from '@vizir-banking/banking-app-core/dist/redux/application/contracts/term-types';
import {Term} from '@vizir-banking/banking-app-core/dist/redux/application/contracts/term';

import translate from '~/i18n/translate';
import TermsScreen from '~/settings/components/terms/terms';

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  NavigationProps;

const PrivacyPolicyScreenContainer = (props: Props): ReactElement => {
  const {actions, terms} = props;

  const applicationContext = useApplicationFromContext();

  useEffect(() => {
    actions.getTerms(
      [TermType.privacyPolicy],
      applicationContext.bankingCoreHttpClient,
    );
  }, [actions, applicationContext.bankingCoreHttpClient]);

  const getPrivacyPolicy = (): string => {
    const privacyPolicy = terms.find(
      (term) => term.type === TermType.privacyPolicy,
    );

    return privacyPolicy === undefined ? '' : privacyPolicy.description;
  };

  return (
    <TermsScreen
      title={translate('settings.privacyPolicy.title')}
      description={getPrivacyPolicy()}
    />
  );
};

const actionCreators = {getTerms};

type State = {
  terms: Term[];
};

const mapStateToProps = ({application}: ReduxState): State => ({
  terms: application.terms,
});

type Actions = {
  getTerms: (termTypes: TermType[], httpClient: BankingCoreHttpClient) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(PrivacyPolicyScreenContainer));
