import {compose, applyMiddleware, StoreEnhancer} from 'redux';
import thunk from 'redux-thunk';

export const applyMiddlewares = (): StoreEnhancer => {
  const composeEnhancers =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middleware = composeEnhancers(applyMiddleware(thunk));

  return middleware;
};
