import firebase from 'firebase';

import {REACT_APP_FIREBASE_ENV} from '~/env';
import firebaseDevConf from '~/config/firebase-conf.dev.json';
import firebaseProdConf from '~/config/firebase-conf.prod.json';

const firebaseConfs: Record<string, object> = {
  DEV: firebaseDevConf,
  PROD: firebaseProdConf,
};

export const initializeAuthenticator = (): void => {
  const config = firebaseConfs[REACT_APP_FIREBASE_ENV];

  if (config === undefined) {
    throw new Error('Invalid FIREBASE_ENV');
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
};
