import moment from 'moment';
import Yup from '@vizir-banking/banking-app-core/dist/utils/yup/yup';
import {
  requiredField,
  invalidDate,
} from '@vizir-banking/banking-app-core/dist/utils/validation-messages';

const MAX_AGE = 120;

const schema = Yup.object({
  rg: Yup.string().required(requiredField),
  rgIssuingDate: Yup.string()
    .test({
      name: 'notOverAged',
      message: invalidDate,
      test(value) {
        const issuanceDate = moment(value, 'DD/MM/YYYY');

        return moment().diff(issuanceDate, 'years') <= MAX_AGE;
      },
    })
    .test({
      name: 'notInTheFuture',
      message: invalidDate,
      test(value) {
        const issuanceDate = moment(value, 'DD/MM/YYYY');
        return issuanceDate.isSameOrBefore(moment());
      },
    })
    .date()
    .required(requiredField),
});

export default schema;
