import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import withInitialRoute from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-initial-route/with-initial-route';
import {IconManager} from '@vizir-banking/banking-app-core/dist/hooks/icon-manager';
import {ImageManager} from '@vizir-banking/banking-app-core/dist/hooks/image-manager';
import {HomeManager} from '@vizir-banking/banking-app-core/dist/hooks/home-manager';
import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';
import EventEmitter, {
  Events,
} from '@vizir-banking/banking-app-core/dist/utils/event-emitter/event-emitter';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';
import {FeatureToggleGate} from '@vizir-banking/banking-app-core/dist/feature-toggle/components/feature-toggle-gate/feature-toggle-gate';

import {registerScreens} from '~/navigation/routes';
import {createStore} from '~/redux/store';
import Listeners from '~/navigation/listeners/listeners-component';
import {initializeI18n} from '~/i18n/initialize';
import {GlobalStyles} from '~/index.styles';
import {initializeAuthenticator} from '~/authenticator/initialize';
import {WithContextProviders} from '~/providers/with-context-providers';
import registerPlugins from '~/register-plugins';
import {registerIcons} from '~/assets/register-icons';
import {registerImages} from '~/assets/register-images';
import {registerMenuItems} from '~/hooks/core-home-menu-register';
import {registerThemeTranslations} from '~/theme/register-translations';
import VersionGate from '~/version-gate/version-gate';
import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {REACT_APP_NEW_LOGIN_FLOW} from '~/env';

registerIcons(IconManager);
registerImages(ImageManager);
registerMenuItems(HomeManager);
registerPlugins();
registerThemeTranslations(TranslationsManager);
initializeI18n();
initializeAuthenticator();

const {store, persistor} = createStore();

const RedirectWithInitialRoute = withInitialRoute(Redirect, 'to');
const Main = (): ReactElement => (
  <Switch>
    {registerScreens()}
    <RedirectWithInitialRoute from="/" to="fake" />
  </Switch>
);

const ListenersWithRouter = withRouter(
  ({history}: RouteComponentProps): React.ReactElement => {
    const handleUnauthorized = (): void => {
      if (notRedirectScreens(history.location.pathname)) return;

      if (isNavigatingOnOnboarding(history.location.pathname)) {
        history.replace(RoutesManager.getRoute('login', 'signUp'));
        return;
      }

      if (REACT_APP_NEW_LOGIN_FLOW) {
        history.replace(RoutesManager.getRoute('login', 'document'));
        return;
      }

      history.replace(RoutesManager.getRoute('login', 'login'));
    };

    return (
      <Listeners
        onUnauthorized={handleUnauthorized}
        onNotification={(): void => {}}
        onSessionExpired={(): void => {}}
      />
    );
  },
);

const notRedirectScreens = (route: string): boolean => {
  return [
    RoutesManager.getRoute('login', 'document'),
    RoutesManager.getRoute('login', 'login'),
    RoutesManager.getRoute('login', 'signUp'),
  ].includes(route);
};

const isNavigatingOnOnboarding = (route: string): boolean => {
  return Object.values(ONBOARDING_SCREENS).includes(route);
};

const WebRouteNavigationEventsDispatcher = withRouter(
  ({history}: RouteComponentProps): null => {
    /* React Router DOM updates this component on every navigation */
    EventEmitter.dispatch(Events.ROUTE_NAVIGATION, {
      path: history.location.pathname,
    });
    return null;
  },
);

const App = (): ReactElement => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WithContextProviders>
          <FeatureToggleGate loading={null}>
            <>
              <VersionGate />
              <GlobalStyles />
              <Router>
                <ListenersWithRouter />
                <WebRouteNavigationEventsDispatcher />
                <Route path="/" component={Main} />
              </Router>
            </>
          </FeatureToggleGate>
        </WithContextProviders>
      </PersistGate>
    </Provider>
  );
};

// @ts-ignore
ReactDOM.render(<App />, document.getElementById('root'));
