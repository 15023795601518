import React, {ReactElement} from 'react';
import {ErrorDialog} from '@vizir-banking/banking-app-core/dist/common';

import translate from '~/i18n/translate';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const UnavailableReportDialog = ({
  show,
  onClose,
}: Props): ReactElement => {
  return (
    <ErrorDialog
      title={translate('settings.incomeReport.unavailableReportModal.title')}
      show={show}
      message={translate(
        'settings.incomeReport.unavailableReportModal.message',
      )}
      onClose={onClose}
    />
  );
};
