import {css} from 'styled-components';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const ScreenStyle = css`
  flex: 1;
  padding: 30px;
  @media (min-width: 400px) {
    justify-content: center;
  }
`;

export const MessageStyle = css`
  font-size: 25px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
  margin-bottom: 40px;
`;

export const SendEmailTextStyle = css`
  font-size: 25px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;

export const LabelStyle = css`
  font-size: 25px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;

export const WrapMessageStyle = css`
  margin-bottom: 40px;
`;

export const LinkTextStyle = css`
  font-size: 25px;
  color: ${useTheme(({colors}) => colors.link)};
  font-weight: normal;
`;

export const AccessKeyInputStyle = css`
  margin-bottom: 15px;
  text-align: center;
`;

export const ErrorMessageStyle = css`
  color: ${useTheme(({colors}) => colors.error.primary)};
  margin-bottom: 25px;
  text-align: center;
`;
