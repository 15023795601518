import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
  Screen,
  FilePicker,
  FileTypes,
} from '@vizir-banking/banking-app-core/dist/common';
import {Button, Dialog} from '@vizir-banking/banking-app-core/dist/layout';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {File} from '@vizir-banking/banking-app-core/dist/api/user/types';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {uploadDocument} from '@vizir-banking/banking-app-core/dist/redux/onboarding/upload-document';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import translate from '~/i18n/translate';
import {
  Title,
  Image,
  Message,
  InfoText,
  InfoIcon,
  SuccessIcon,
  InfoWrapper,
  ImageWrapper,
  ImageContainer,
  SecondaryButton,
} from '~/onboarding/screens/send-document/base-screens/shared.styles';

type Props = ReturnType<typeof mapDispatchToProps> & {
  imgSrc: string;
  subTitle: string;
  isCompanyFlow?: boolean;
  onSendLaterButtonPress?: () => void;
  onPrimaryButtonPress: (files: any[]) => void;
  secondaryButtonLabel?: string;
  documentType?: DocumentTypes;
};

type State = {
  isAttached: boolean;
  file: any;
  maxFileSizeReached: boolean;
};

const MAX_FILE_SIZE = 2097152;

class SendDocumentScreen extends React.PureComponent<Props, State> {
  static contextType = ApplicationContext;

  state = {
    isAttached: false,
    file: {name: ''},
    maxFileSizeReached: false,
  };

  handleCloseDialog = (): void => {
    this.setState({maxFileSizeReached: false});
  };

  handleFileSelected = (file: File): void => {
    const context = this.context as ApplicationContextType;
    const {actions, documentType} = this.props;

    if (file.size !== undefined && file.size > MAX_FILE_SIZE) {
      return this.setState({maxFileSizeReached: true});
    }

    actions
      .uploadDocument(file, context.bankingCoreHttpClient, documentType)
      .then(() => {
        this.setState({
          file,
          isAttached: true,
        });
      });
  };

  render(): ReactElement {
    const {
      imgSrc,
      subTitle,
      isCompanyFlow = false,
      onPrimaryButtonPress,
      secondaryButtonLabel,
    } = this.props;

    const {isAttached, file, maxFileSizeReached} = this.state;

    const isAttachedText = isAttached ? 'attached' : 'notAttached';

    const label =
      secondaryButtonLabel ||
      translate('onboarding.sendDocuments.photoDocument.transparentButton');

    return (
      <Screen showProgress isCompanyFlow={isCompanyFlow}>
        <Dialog
          show={maxFileSizeReached}
          iconName="alert"
          onClose={this.handleCloseDialog}
        >
          <Message>
            {translate(
              'onboarding.sendDocuments.attachDocument.maxFileSizeReached',
            )}
          </Message>
        </Dialog>
        <Title>
          {translate(
            `onboarding.sendDocuments.attachDocument.title.${isAttachedText}`,
          )}
        </Title>
        <Message lessMarginTop isBold={!isAttached}>
          {isAttached ? file.name : subTitle}
        </Message>
        <ImageWrapper>
          <ImageContainer>
            {isAttached && <SuccessIcon />}
            <Image source={imgSrc} />
          </ImageContainer>
          {!isAttached && (
            <InfoWrapper>
              <InfoIcon />
              <InfoText>
                {translate('onboarding.sendDocuments.attachDocument.info')}
              </InfoText>
            </InfoWrapper>
          )}
        </ImageWrapper>
        <FilePicker
          disabled={isAttached}
          fileTypes={FileTypes.PDF}
          onFileSelected={this.handleFileSelected}
        >
          <Button
            text={translate(
              `onboarding.sendDocuments.attachDocument.button.${isAttachedText}`,
            )}
            onPress={onPrimaryButtonPress}
          />
        </FilePicker>
        {this.props.onSendLaterButtonPress && (
          <SecondaryButton
            text={label}
            onPress={this.props.onSendLaterButtonPress}
          />
        )}
      </Screen>
    );
  }
}

const actionCreators = {uploadDocument};

type Actions = {
  uploadDocument: (
    file: File,
    httpClient: BankingCoreHttpClient,
    documentType?: DocumentTypes,
  ) => Promise<boolean>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(SendDocumentScreen);
