import React, {ReactNode, ReactElement} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {isCPF, isCNPJ} from 'brazilian-values';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import removeSpecialChars from '@vizir-banking/banking-app-core/dist/utils/remove-special-chars';
import {MaskTypes, Form} from '@vizir-banking/banking-app-core/dist/layout';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {userIsRegistered} from '@vizir-banking/banking-app-core/dist/redux/onboarding/user-is-registered';
import {CompanyPartner} from '@vizir-banking/banking-app-core/dist/entities/user/company-partner';

import translate from '~/i18n/translate';
import PartnerActionDialog from '~/onboarding/screens/company-partners/partner-action-dialog/partner-action-dialog';
import CheckBox from '~/onboarding/components/check-box/check-box';
import SCHEMA from '~/onboarding/screens/company-partners/partner-form/partner-form.schema';
import {
  Wrapper,
  AdminWrapper,
  Title,
  FormField,
  Header,
  TrashIcon,
} from '~/onboarding/screens/company-partners/partner-form/partner-form.styles';

import {Props, State} from './types';

const COMPANY_DOCUMENT_LENGTH = 14;
const PERSON_DOCUMENT_LENGTH = 11;

const parseTwoDigitsString = (number: number): string => {
  const string = String(number);

  if (number < 10) return 0 + string;
  return string;
};

class PartnerForm extends React.Component<
  Props & ReturnType<typeof mapDispatchToProps>,
  State
> {
  static contextType = ApplicationContext;

  constructor(props: Props & ReturnType<typeof mapDispatchToProps>) {
    super(props);

    const {initialValues} = props;

    const defaultState = {
      dialogVisible: false,
      isPersonDocument: false,
      isUserRegistered: false,
      showAllFields: false,
      admin: false,
    };

    if (!isEmpty(initialValues)) {
      const {documentNumber = '', admin = false} = initialValues || {};

      const document = removeSpecialChars(documentNumber);
      const isPersonDocument = document.length <= PERSON_DOCUMENT_LENGTH;
      const isUserRegistered = !get(initialValues, 'phone');

      this.state = {
        ...defaultState,
        admin,
        isUserRegistered,
        isPersonDocument,
        showAllFields: true,
      };

      return;
    }

    this.state = defaultState;
  }

  onSuccess = (partner: CompanyPartner): void => {
    const {admin} = this.state;
    const {index, onSubmit} = this.props;

    onSubmit(index, {...partner, admin});
  };

  handleDeleteForm = (): void => {
    const {index, onDelete} = this.props;

    onDelete(index);
  };

  handleToggleAdmin = (): void => {
    const {admin} = this.state;

    this.setState({admin: !admin});
  };

  showDeleteConfirmationDialog = (): void => {
    this.setState({dialogVisible: true});
  };

  hideDeleteConfirmationDialog = (): void => {
    this.setState({dialogVisible: false});
  };

  handleDocumentNumberChange = async (document: string): Promise<void> => {
    const context = this.context as ApplicationContextType;

    const documentNumber = removeSpecialChars(document);

    const isCpf = isCPF(documentNumber);

    const isPersonDocument = documentNumber.length <= PERSON_DOCUMENT_LENGTH;
    const isDocumentFilled =
      documentNumber.length === PERSON_DOCUMENT_LENGTH ||
      documentNumber.length === COMPANY_DOCUMENT_LENGTH;

    const showAllFields = (isCpf || isCNPJ(documentNumber)) && isDocumentFilled;

    let isUserRegistered = false;

    if (isCpf) {
      isUserRegistered = !(await this.props.actions.userIsRegistered(
        documentNumber,
        context.bankingCoreHttpClient,
        false,
      ));
    }

    this.setState({
      isPersonDocument,
      isUserRegistered,
      showAllFields,
    });
  };

  renderPartnerFields = (): ReactNode | null => {
    const {
      isPersonDocument,
      isUserRegistered,
      showAllFields,
      admin,
    } = this.state;

    if (!showAllFields) return null;

    if (!isPersonDocument) {
      const nameLabel = translate('onboarding.partnerData.partnerCompanyName');

      return (
        <FormField
          hasFloatingLabel
          name="name"
          label={nameLabel}
          autoCapitalize="words"
          maxLength={100}
          returnKeyType="next"
        />
      );
    }

    const nameLabel = translate('onboarding.partnerData.partnerFullName');
    const phoneLabel = translate('onboarding.partnerData.partnerPhone');

    const {index} = this.props;

    const [nameField, phoneField, adminField] = [
      <FormField
        hasFloatingLabel
        name="name"
        key={`partner-form-${index}-name`}
        label={nameLabel}
        autoCapitalize="words"
        maxLength={100}
        returnKeyType="next"
      />,
      <FormField
        hasFloatingLabel
        name="phone"
        key={`partner-form-${index}-phone`}
        label={phoneLabel}
        mask={MaskTypes.PHONE}
      />,
      <AdminWrapper key={`partner-form-${index}-admin`}>
        <CheckBox
          text={translate('onboarding.partnerData.accountAdmin')}
          checked={admin}
          onChangeValue={this.handleToggleAdmin}
        />
      </AdminWrapper>,
    ];

    if (isUserRegistered) {
      return [adminField];
    }

    return [nameField, phoneField, adminField];
  };

  render(): ReactElement {
    const {index, initialValues, formRef, onDelete} = this.props;

    const {dialogVisible, isPersonDocument, isUserRegistered} = this.state;

    const showDeleteIcon = onDelete && index > 0;

    const cpfCnpjLabel = `${translate('onboarding.CPF')} / ${translate(
      'onboarding.CNPJ',
    )}`;

    const extraValidationData = {isPersonDocument, isUserRegistered};

    return (
      <Wrapper>
        <PartnerActionDialog
          dialogTitle={translate('onboarding.DeletePartnerTitle')}
          dialogText={translate('onboarding.DeletePartnerInfo')}
          actionButtonText={translate('onboarding.DeletePartner')}
          secondButtonText={translate('onboarding.KeepPartner')}
          onClose={this.hideDeleteConfirmationDialog}
          onActionButtonPress={this.handleDeleteForm}
          dialogVisible={dialogVisible}
        />
        <Header>
          <Title>
            {parseTwoDigitsString(index + 1)}.{' '}
            {translate('onboarding.partnerData.partnerCompany')}
          </Title>
          {showDeleteIcon && (
            <TrashIcon onPress={this.showDeleteConfirmationDialog} />
          )}
        </Header>
        <Form
          ref={formRef}
          schema={SCHEMA}
          extraValidationData={extraValidationData}
          initialValues={initialValues}
          onSubmit={this.onSuccess}
        >
          <FormField
            hasFloatingLabel
            name="documentNumber"
            label={cpfCnpjLabel}
            mask={MaskTypes.CPF_CNPJ}
            onChange={this.handleDocumentNumberChange}
            returnKeyType="next"
          />
          {this.renderPartnerFields()}
        </Form>
      </Wrapper>
    );
  }
}

type Actions = {
  userIsRegistered: (
    document: string,
    httpClient: BankingCoreHttpClient,
    setError?: boolean,
  ) => Promise<boolean>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({userIsRegistered}, dispatch),
});

export default connect(null, mapDispatchToProps)(PartnerForm);
