import styled from 'styled-components';
import {Screen as BaseScreen} from '@vizir-banking/banking-app-core/dist/common';
import {
  Text,
  Bold,
  View,
  Button as BaseButton,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Screen = styled(BaseScreen)`
  padding: 0;
`;

export const Wrapper = styled(View)`
  flex-grow: 1;
  padding: 38px 38px 76px;
`;

export const TitleWrapper = styled(View)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Bold)`
  font-size: ${useTheme(({sizes}) => sizes.titleSize)}px;
  text-align: center;
  margin-top: 40px;
  color: ${useTheme(({colors}) => colors.textOverPrimary)};
`;

export const Message = styled(Text)`
  font-size: ${useTheme(({sizes}) => sizes.text.default)}px;
  margin-top: 32px;
  text-align: center;
  color: ${useTheme(({colors}) => colors.textSecondary)};
`;

export const Button = styled(BaseButton)`
  margin-top: 32px;
`;

export const BottomBar = styled(View)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background-color: ${useTheme(({colors}) => colors.button.background.primary)};
`;
