import styled from 'styled-components';
import {
  View,
  Bold,
  Text,
  Button,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const DialogTitle = styled(Bold)`
  font-size: 25px;
  text-align: center;
`;
export const TextWrapper = styled(View)`
  padding: 30px 0px;
`;

export const InfoText = styled(Text)`
  font-size: 16px;
  color: ${useTheme(({colors}) => colors.button.text.secondary)};
  text-align: center;
`;

export const SecondaryButton = styled(Button)`
  margin-top: 20px;
`;
