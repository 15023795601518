import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {Versions} from '@vizir-banking/banking-app-core/dist/entities/application/versions';
import React from 'react';
import {connect} from 'react-redux';

import {UpdateModal} from '~/version-gate/update-modal/update-modal';
import appDetails from '~/utils/app-details/app-details';
import {UpdateType} from '~/version-gate/update-modal/update-modal.type';
import {
  VersionGateProps,
  mapStateToProps,
  mapDispatchToProps,
} from '~/version-gate/version-gate.types';
import {REACT_APP_ENABLE_BLOCK_OLD_VERSIONS} from '~/env';

class VersionGate extends React.Component<VersionGateProps> {
  static contextType = ApplicationContext;

  private getUpdateType(forceUpdate: boolean): UpdateType {
    return forceUpdate ? UpdateType.force : UpdateType.prompt;
  }

  private shouldRenderModal(versions?: Versions): boolean {
    return (
      REACT_APP_ENABLE_BLOCK_OLD_VERSIONS &&
      versions !== undefined &&
      appDetails.version !== null &&
      appDetails.storeUrl !== null &&
      !versions.frontend.isLatest(appDetails.version)
    );
  }

  public async componentDidMount(): Promise<void> {
    const context = this.context as ApplicationContextType;

    await this.props.actions.getVersions(context.bankingCoreHttpClient);
  }

  public render(): React.ReactElement | null {
    const {versions} = this.props;

    if (!this.shouldRenderModal(versions)) {
      return null;
    }

    const updateType = this.getUpdateType(
      versions!.frontend.isLessThenMin(appDetails.version!),
    );

    return <UpdateModal updateType={updateType} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionGate);
