import {Button, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';
import styled from 'styled-components';
import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

export const RedirectButton = styled(Button)`
  margin-top: 30px;
`;

export const Message = styled(Text)`
  font-size: 18px;
  text-align: center;
  color: ${useTheme(({colors}: Theme) => colors.textOverPrimary)};
`;
