import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Types} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';
import {Screen} from '@vizir-banking/banking-app-core/dist/common';
import {resetUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {REACT_APP_CUSTOMER_NAME} from '~/env';
import {
  Wrapper,
  Icon,
  MessageWrapper,
  Text,
  Button,
} from '~/onboarding/screens/cnpj-info/cnpj-info.styles';

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

export const CnpjInfo = (props: Props): ReactElement => {
  const onPress = (): void => {
    props.actions.resetUser();
    props.navigateToNextOnboardingScreen();
  };

  return (
    <Screen navType={Types.BASIC}>
      <Wrapper>
        <Icon name="alert" size={76} />
        <MessageWrapper>
          <Text>
            Nesse momento, estamos aceitando credenciamento apenas de MEI, EI e
            EIRELI! Mas fique tranquilo, logo mais a sua empresa também poderá
            fazer parte da Conta {REACT_APP_CUSTOMER_NAME}!
          </Text>
        </MessageWrapper>
      </Wrapper>
      <Button text="Abrir conta pessoa física" onPress={onPress} />
    </Screen>
  );
};

const actionCreators = {resetUser};

type Actions = {resetUser: () => void};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(withOnboardingNavigation(CnpjInfo, ONBOARDING_SCREENS.cnpjInfo));
