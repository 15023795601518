export const AppSizes = {
  horizontalPadding: 15,
  verticalMargin: 30,
  buttonPadding: 14,
  titleSize: 25,
  contentPadding: 38,
  contentPaddingTop: 38,
  basicNavbarPadding: 20,
  modalHorizontalPadding: 36,
  modalVerticalPadding: 35,
  loggedScreenContainerRadius: 38,
  homeScreenContainerRadius: 27,
  buttonRadius: 26.5,
  cardMenuItem: {
    elevation: 1,
    shadowSize: 4,
  },
  text: {
    default: 18,
    label: 16,
    title: 20,
  },
  searchInput: {
    radius: 4,
  },
  buttonText: {
    normal: 18,
    underline: 16,
  },
  modal: {
    text: 18,
  },
  tabs: {
    tabsRadius: 0,
  },
  statement: {
    list: {
      groupHeaderSize: 16,
      item: {
        additionalInfoSize: 12,
        descriptionTextSize: 16,
        valueTextSize: 16,
      },
      footerSize: 16,
    },
    details: {
      descriptionText: {
        date: 16,
        additionalInfoSize: 12,
        establishmentSize: 25,
        notEstablishmentSize: 20,
        receiptDescriptionSize: 16,
      },
      valueTextSize: 25,
      footerSize: 16,
    },
  },
  table: {
    description: 16,
    headerText: 16,
    cellText: 16,
  },
};
