import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {uploadDocument} from '@vizir-banking/banking-app-core/dist/redux/onboarding/upload-document';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {File} from '@vizir-banking/banking-app-core/dist/api/user/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {CompanyPartner} from '@vizir-banking/banking-app-core/dist/entities/user/company-partner';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import translate from '~/i18n/translate';
import {PhotoDocumentProps} from '~/onboarding/screens/send-document/base-screens/photo-document/types';
import DocumentPreview from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-preview/photo-document-preview';
import DocumentCamera from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-camera/photo-document-camera';
import DocumentConfirm from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-confirm/photo-document-confirm';

enum SendDocumentsSteps {
  PREVIEW,
  CAMERA,
  COMFIRM,
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  NavigationProps &
  PhotoDocumentProps;

type DocumentPicturesType = {
  file: File;
  type: DocumentTypes;
  uri: string;
};

type State = {
  step: SendDocumentsSteps;
  documentPicture?: DocumentPicturesType;
};

class PhotoDocument extends React.PureComponent<Props, State> {
  static contextType = ApplicationContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      step: SendDocumentsSteps.PREVIEW,
      documentPicture: undefined,
    };
  }

  getImageSource = (): string => {
    const {documentPicture} = this.state;

    return documentPicture ? documentPicture.uri : '';
  };

  changeStepToCamera = (): void => {
    this.setState({step: SendDocumentsSteps.CAMERA});
  };

  handlePhotoCaptured = (file: File): void => {
    const {documentType} = this.props;

    // @ts-ignore
    const reader = new FileReader(); // eslint-disable-line no-undef

    reader.readAsDataURL(file);

    reader.onloadend = (): void => {
      this.setState({
        step: SendDocumentsSteps.COMFIRM,
        documentPicture: {
          type: documentType!,
          uri: reader.result,
          file,
        },
      });
    };
  };

  handleCloseCamera = (): void => {
    this.setState({
      step: SendDocumentsSteps.PREVIEW,
      documentPicture: undefined,
    });
  };

  handleTakeAnotherPicture = (): void => {
    if (!this.props.tips) {
      return this.handleCloseCamera();
    }

    this.changeStepToCamera();
  };

  handleConfirmPicture = (): Promise<void> => {
    return this.uploadDocumentPictures();
  };

  uploadDocumentPictures = async (): Promise<void> => {
    const context = this.context as ApplicationContextType;
    const {documentPicture} = this.state;
    const {onConfirmPicture, actions} = this.props;

    if (!documentPicture) return;

    const isSuccessUpload = await actions.uploadDocument(
      documentPicture.file,
      context.bankingCoreHttpClient,
      documentPicture.type,
    );

    if (isSuccessUpload) {
      onConfirmPicture();
    }
  };

  renderPreviewScreen = (): ReactElement => {
    return (
      <DocumentPreview
        documentType={this.props.documentType}
        title={this.props.documentPreviewTitle}
        message={this.props.documentPreviewMessage}
        subMessage={this.props.documentPreviewSubMessage}
        hasHints={Boolean(this.props.tips)}
        onPhotoCaptured={this.handlePhotoCaptured}
        primaryButtonText={translate(
          'onboarding.sendDocuments.photoMainDocument.primaryButton',
        )}
        onPrimaryButtonPress={this.changeStepToCamera}
        onTransparentButtonPress={this.props.onTransparentButtonPress}
        isLoading={this.props.isLoading}
        children={this.props.documentPreviewChildren}
      />
    );
  };

  renderCamera = (): ReactElement => {
    return (
      <DocumentCamera
        documentType={this.props.documentType}
        tips={this.props.tips}
        onClose={this.handleCloseCamera}
        hasHints={this.props.hasHints}
        onPhotoCaptured={this.handlePhotoCaptured}
      />
    );
  };

  renderDocumentConfirm = (): ReactElement => {
    return (
      <DocumentConfirm
        imageSource={this.getImageSource()}
        isLoading={this.props.isLoading}
        onPrimaryButtonPress={this.handleConfirmPicture}
        onTakeAnotherPicture={this.handleTakeAnotherPicture}
      />
    );
  };

  render(): ReactElement {
    const rendersSteps = {
      [SendDocumentsSteps.PREVIEW]: this.renderPreviewScreen,
      [SendDocumentsSteps.CAMERA]: this.renderCamera,
      [SendDocumentsSteps.COMFIRM]: this.renderDocumentConfirm,
    };

    return rendersSteps[this.state.step]();
  }
}

const actionCreators = {uploadDocument};

type StateType = {
  isLoading: boolean;
  isCompanyFlow: boolean;
  partners?: CompanyPartner[];
};

const mapStateToProps = ({application, onboarding}: ReduxState): StateType => ({
  isLoading: application.isLoading,
  isCompanyFlow: onboarding.isCompanyFlow,
  partners: onboarding.creationUser.partners,
});

type Actions = {
  uploadDocument: (
    file: File,
    httpClient: BankingCoreHttpClient,
    documentType?: DocumentTypes,
  ) => Promise<boolean>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(PhotoDocument));
