import React, {ReactElement} from 'react';
import {
  InfoText,
  FormFieldTypes,
} from '@vizir-banking/banking-app-core/dist/common';

import {Props} from '~/onboarding/screens/profession/types';
import {
  Form,
  FormField,
  Button,
  Title,
  FieldWrapper,
  Wrapper,
} from '~/onboarding/screens/profession/profession.styles';
import translate from '~/i18n/translate';
import schema from '~/onboarding/screens/profession/profession.schema';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

class ProfessionScreen extends React.Component<Props> {
  renderText = (): ReactElement => {
    return <Title>{translate('onboarding.profession.title')}</Title>;
  };

  render(): ReactElement {
    const {professionId, monthlyIncome, options} = this.props;
    return (
      <OnboardingScreen showProgress>
        <Wrapper>
          <Form
            initialValues={{professionId, monthlyIncome}}
            onSubmit={this.props.onSubmit}
            schema={schema}
          >
            <FieldWrapper>
              {this.renderText()}
              <FormField
                label={translate('onboarding.profession.profession')}
                name="professionId"
                type={FormFieldTypes.SELECT}
                modalTitle={translate(
                  'onboarding.profession.professionPlaceholder',
                )}
                options={options}
                placeholder={translate(
                  'onboarding.profession.professionPlaceholder',
                )}
                returnKeyType="next"
              />
              <FormField
                label={translate('onboarding.profession.monthlyIncome')}
                name="monthlyIncome"
                type={FormFieldTypes.MONEY}
              />
              <InfoText
                text={translate('onboarding.profession.infoText')}
                title={translate('onboarding.profession.infoButton')}
              />
            </FieldWrapper>
            <Button
              isSubmit
              text={translate('onboarding.profession.continue')}
            />
          </Form>
        </Wrapper>
      </OnboardingScreen>
    );
  }
}

export default ProfessionScreen;
