import Yup from '@vizir-banking/banking-app-core/dist/utils/yup/yup';
import {requiredField} from '@vizir-banking/banking-app-core/dist/utils/validation-messages';

/* eslint-disable no-confusing-arrow */
const schema = Yup.object({
  documentNumber: Yup.string().cpfOrCnpj().required(requiredField),
  name: Yup.string().when(
    '$isUserRegistered',
    (isUserRegistered: boolean, valueSchema: Yup.StringSchema) =>
      isUserRegistered
        ? valueSchema.notRequired()
        : valueSchema.legalName().required(requiredField),
  ),
  phone: Yup.string().when(
    ['$isPersonDocument', '$isUserRegistered'],
    (
      isPersonDocument: boolean,
      isUserRegistered: boolean,
      valueSchema: Yup.StringSchema,
    ) =>
      isPersonDocument && !isUserRegistered
        ? valueSchema.phone().required(requiredField)
        : valueSchema.notRequired(),
  ),
});
/* eslint-enable no-confusing-arrow */

export default schema;
