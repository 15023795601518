import styled from 'styled-components';
import {
  Bold,
  ScrollView as BaseScrollView,
  Text as BaseText,
} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

import {
  TitleStyle,
  TextStyle,
} from '~/onboarding/screens/terms-conditions/terms-conditions.styles.base';

export const ScrollView = styled(BaseScrollView)`
  height: 1px;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 0 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${useTheme(({colors}) => colors.scrollbarBackground)};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${useTheme(({colors}) => colors.primary)};
    border-radius: 10px;
  }
`;

export const Title = styled(Bold)`
  ${TitleStyle}
`;

export const Text = styled(BaseText)`
  ${TextStyle}
`;
