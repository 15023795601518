export const AppFonts = {
  primary: {
    weight: {
      normal: 'Acre-Regular',
      100: 'Acre-Thin',
      300: 'Acre-Light',
      500: 'Acre-Medium',
      600: 'Acre-Semibold',
      800: 'Acre-Extrabold',
      900: 'Acre-Black',
      bold: 'Acre-Bold',
    },
  },
  button: {
    uppercaseTransform: true,
  },
};
