import React, {ReactElement} from 'react';
import {
  Button,
  ButtonTypes,
  View,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Screen, ScreenTypes} from '@vizir-banking/banking-app-core/dist/common';

import translate from '~/i18n/translate';
import {
  CancelButton,
  Confirm,
  Description,
  Email,
  Header,
  Wrapper,
} from '~/settings/screens/income-report/income-report.styles';

type Props = {
  email: string | undefined;
  onSend: () => void;
  onCancel: () => void;
};

export const IncomeReportScreen = ({
  email,
  onSend,
  onCancel,
}: Props): ReactElement => {
  const translateShorter = (key: string): string =>
    translate(`settings.incomeReport.${key}`);

  return (
    <Screen type={ScreenTypes.LOGGED} title={translateShorter('title')}>
      <Wrapper>
        <View>
          <Header>{translateShorter('header')}</Header>
          <Description>{translateShorter('description')}</Description>
          <Email>{email ?? ''}</Email>
          <Confirm>{translateShorter('confirm')}</Confirm>
        </View>

        <View>
          <Button text={translateShorter('send')} onPress={onSend} />
          <CancelButton
            type={ButtonTypes.SECONDARY}
            text={translateShorter('cancel')}
            onPress={onCancel}
          />
        </View>
      </Wrapper>
    </Screen>
  );
};
