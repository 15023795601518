import styled from 'styled-components';
import {View, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Message = styled(Text)`
  font-size: 18px;
  margin-bottom: 40px;
`;

export const ToggleWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 34px;
`;

export const ToggleLabel = styled(Text)`
  font-size: 18px;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;
