import styled from 'styled-components';
import {View} from '@vizir-banking/banking-app-core/dist/layout';
import BaseImage from '@vizir-banking/banking-app-core/dist/layout/image/image';

import {Title as SharedTitle} from '~/onboarding/components/shared-styles/shared-styles';

export const Image = styled(BaseImage)`
  align-self: center;
  margin: 10px 10px 60px;
  height: 200px;
`;

export const Title = styled(SharedTitle)`
  margin: 26px 0;
`;

export const ImageWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;
