import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {isEmpty, isNull} from 'lodash';
import {ListOption} from '@vizir-banking/banking-app-core/dist/layout/list/types';
import isWeb from '@vizir-banking/banking-app-core/dist/utils/is-web';
import openUrl from '@vizir-banking/banking-app-core/dist/utils/open-url';
import withNavigation from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/with-navigation';
import {NavigationProps} from '@vizir-banking/banking-app-core/dist/navigation/hocs/with-navigation/types';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {Biometrics} from '@vizir-banking/banking-app-core/dist/redux/sensor/types';
import BILLET_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/deposit/navigation/screen-definitions';
import CARD_SCREENS from '@vizir-banking/banking-app-core-plugins/dist/physical-card/navigation/screen-definitions';
import UserEntity from '@vizir-banking/banking-app-core/dist/entities/user/user';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {bindActionCreators, Dispatch} from 'redux';
import {logout} from '@vizir-banking/banking-app-core/dist/redux/application/logout';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';
import {resetCredentials} from '@vizir-banking/banking-app-core/dist/utils/sensor/keychain/keychain';

import translate from '~/i18n/translate';
import {
  REACT_APP_ENABLE_PHYSICAL_CARD,
  REACT_APP_ENABLE_SENSOR_LOGIN,
  REACT_APP_ENABLE_VIRTUAL_CARD,
  REACT_APP_ENABLE_INCOME_REPORT,
} from '~/env';
import {SETTINGS_SCREENS} from '~/navigation/screen-definitions';
import appDetails from '~/utils/app-details/app-details';
import {ProfileScreen} from '~/settings/screens/profile/profile';

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  NavigationProps;

export class BaseProfileScreenContainer extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  state = {
    isLogoutDialogOpen: false,
  };

  shouldRenderRateApp = (): boolean => {
    return !isWeb() && !isEmpty(appDetails.storeUrl);
  };

  navigateToStore = (): void => {
    if (isEmpty(appDetails.storeUrl)) {
      return;
    }

    openUrl(appDetails.storeUrl!);
  };

  onItemSelected = (item: ListOption): void => {
    const {navigate} = this.props;

    if (item.value === translate('settings.rateApp')) {
      this.navigateToStore();
      return;
    }

    if (item.value === translate('settings.logout.title')) {
      this.showLogoutDialog();
      return;
    }

    navigate(item.value);
  };

  handleShowBankData = (): void => {
    const {navigate} = this.props;

    navigate(BILLET_SCREENS.depositAccountInfo);
  };

  showLogoutDialog = (): void => {
    this.setState({
      isLogoutDialogOpen: true,
    });
  };

  hideLogoutDialog = (): void => {
    this.setState({
      isLogoutDialogOpen: false,
    });
  };

  onLogout = async (): Promise<void> => {
    const {actions, sensorIsUsed} = this.props;
    const context = this.context as ApplicationContextType;

    actions.logout(context.bankingCoreHttpClient);

    if (context.env.REACT_APP_NEW_LOGIN_FLOW) {
      this.props.reset(RoutesManager.getRoute('login', 'document'));
      return;
    }

    if (!sensorIsUsed) {
      await resetCredentials();
    }

    this.props.reset(RoutesManager.getRoute('login', 'login'));
  };

  hasAnyCardBlockedByIncorrectPassword = (): boolean => {
    const {currentUser} = this.props;
    const context = this.context as ApplicationContextType;

    return !!(
      currentUser.cards?.isAnyCardBlockedByIncorrectPassword &&
      context.env.REACT_APP_ENABLE_UNBLOCK_INCORRECT_CARD_PASSWORD
    );
  };

  options = (): ListOption[] => {
    const {sensorType} = this.props;
    const options = [
      {
        value: SETTINGS_SCREENS.editProfile,
        label: translate('settings.editProfile.title'),
      },
    ];

    if (REACT_APP_ENABLE_INCOME_REPORT) {
      options.push({
        value: SETTINGS_SCREENS.incomeReport,
        label: translate('settings.incomeReport.title'),
      });
    }

    options.push({
      value: SETTINGS_SCREENS.changeAppPassword,
      label: translate('settings.changeAppPassword.title'),
    });

    if (REACT_APP_ENABLE_PHYSICAL_CARD || REACT_APP_ENABLE_VIRTUAL_CARD) {
      options.push({
        value: CARD_SCREENS.setPassword,
        label: this.hasAnyCardBlockedByIncorrectPassword()
          ? translate('settings.changeCardPassword.unblockCard')
          : translate('settings.changeCardPassword.title'),
      });
    }

    if (REACT_APP_ENABLE_SENSOR_LOGIN && !isNull(sensorType)) {
      options.push({
        value: SETTINGS_SCREENS.sensorSettings,
        label: translate('settings.sensor.title'),
      });
    }

    if (this.shouldRenderRateApp()) {
      options.push({
        value: translate('settings.rateApp'),
        label: translate('settings.rateApp'),
      });
    }

    options.push(
      {
        value: RoutesManager.getRoute('faq', 'topics'),
        label: translate('settings.faq.title'),
      },
      {
        value: SETTINGS_SCREENS.termsOfUse,
        label: translate('settings.termsOfUse.title'),
      },
      {
        value: SETTINGS_SCREENS.privacyPolicy,
        label: translate('settings.privacyPolicy.title'),
      },
      {
        value: translate('settings.logout.title'),
        label: translate('settings.logout.title'),
      },
    );

    return options;
  };

  render(): ReactElement {
    const {currentUser} = this.props;
    const {isLogoutDialogOpen} = this.state;

    const user = new UserEntity(currentUser);

    return (
      <ProfileScreen
        user={user}
        options={this.options()}
        handleShowBankData={this.handleShowBankData}
        onItemSelected={this.onItemSelected}
        onLogout={this.onLogout}
        hideLogoutDialog={this.hideLogoutDialog}
        isLogoutDialogOpen={isLogoutDialogOpen}
      />
    );
  }
}

type State = {
  currentUser: Partial<User>;
  sensorType: Biometrics | null;
  sensorIsUsed: boolean;
};

type Actions = {
  logout: (httpClient: BankingCoreHttpClient) => void;
};

const mapStateToProps = ({user, sensor}: ReduxState): State => ({
  currentUser: user.currentUser,
  sensorType: sensor.sensorType,
  sensorIsUsed: sensor.sensorIsUsed,
});

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators({logout}, dispatch),
});

export const ProfileScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(BaseProfileScreenContainer));
