import React, {ReactElement} from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {Button} from '@vizir-banking/banking-app-core/dist/layout';
import {CompanyPartner} from '@vizir-banking/banking-app-core/dist/entities/user/company-partner';

import translate from '~/i18n/translate';
import {
  Wrapper,
  AddIcon,
  Touchable,
  AddButtonText,
} from '~/onboarding/screens/company-partners/company-partner-form.styles';
import PartnerForm from '~/onboarding/screens/company-partners/partner-form/partner-form';

import {Props, State, FormState} from './types';

const generateId = (): string => Math.random().toString(36).slice(2);

class CompanyPartnerForm extends React.Component<Props, State> {
  state = {
    isAllFormsValid: false,
    restoredValuesFromInitialValues: false,
    forms: [
      {
        isValid: false,
        initialValues: {} as CompanyPartner,
        values: {} as CompanyPartner,
        id: generateId(),
      },
    ],
  };

  formRefs: any = {};

  static getDerivedStateFromProps(
    nextProps: Props,
    state: State,
  ): Partial<State> {
    const {restoredValuesFromInitialValues} = state;
    const {initialValues} = nextProps;

    if (
      isEmpty(state.forms[0].values) &&
      !isEmpty(initialValues) &&
      !restoredValuesFromInitialValues
    ) {
      const forms = initialValues!.map((values) => ({
        values,
        initialValues: values,
        isValid: false,
        id: generateId(),
      }));

      return {forms, restoredValuesFromInitialValues: true};
    }

    return {};
  }

  verifyFormsValidation = (): void => {
    const {forms} = this.state;
    const {onSubmit, onSubmitFailed} = this.props;

    const validationPromises = Object.values(this.formRefs).map(
      async (form: any) => await form.current.handleSubmit(),
    );

    Promise.all(validationPromises).then((formsValidations) => {
      const isAllFormsValid = formsValidations.every((isValid) => isValid);

      if (!isAllFormsValid) {
        const invalidFormIndex = forms.findIndex((form) => !form.isValid);

        const firstErrorInputRef = get(
          this.formRefs,
          `[${invalidFormIndex}].current`,
          null,
        );

        if (!firstErrorInputRef) return;

        onSubmitFailed &&
          onSubmitFailed(firstErrorInputRef.getClientRectsOfInvalidFields()[0]);

        return;
      }

      const partners: CompanyPartner[] = forms.map(
        ({values}: {values: CompanyPartner}) => values,
      );

      onSubmit(partners);
    });
  };

  handleDeleteForm = (index: number): void => {
    const {forms} = this.state;
    const copyForms = [...forms];
    const formId = forms[index].id;

    copyForms.splice(index, 1);

    const newRefs = omit(this.formRefs, formId);

    this.formRefs = newRefs;

    const isAllFormsValid = copyForms.every(
      ({isValid}: {isValid: boolean}) => isValid,
    );

    this.setState({forms: copyForms, isAllFormsValid});
  };

  handleSubmit = (index: number, values: CompanyPartner): void => {
    const {forms} = this.state;
    const copyForms = [...forms];

    copyForms[index].isValid = true;

    copyForms[index].values = values;

    const isAllFormsValid = copyForms.every(
      ({isValid}: {isValid: boolean}) => isValid,
    );

    this.setState({forms: copyForms, isAllFormsValid});
  };

  handleAddNewForm = (): void => {
    const {forms}: {forms: FormState[]} = this.state;

    const newFormObject: FormState = {
      isValid: false,
      id: generateId(),
      values: {} as CompanyPartner,
      initialValues: {} as CompanyPartner,
    };

    const newForms = [...forms, newFormObject];

    const isAllFormsValid = newForms.every(
      ({isValid}: {isValid: boolean}) => isValid,
    );

    this.setState({forms: newForms, isAllFormsValid});
  };

  renderForm = (form: FormState, index: number): ReactElement => {
    const isRefCreated = !isEmpty(this.formRefs[form.id]);

    if (!isRefCreated) {
      this.formRefs = {
        ...this.formRefs,
        [form.id]: React.createRef(),
      };
    }

    const ref = this.formRefs[form.id];

    return (
      <PartnerForm
        formRef={ref}
        key={`partner-form-${form.id}`}
        index={index}
        initialValues={form.initialValues}
        onSubmit={this.handleSubmit}
        onDelete={this.handleDeleteForm}
      />
    );
  };

  render(): ReactElement {
    const {className, style} = this.props;

    const {forms} = this.state;

    return (
      <Wrapper className={className} style={style}>
        {forms.map(this.renderForm)}
        <Touchable onPress={this.handleAddNewForm}>
          <AddIcon />
          <AddButtonText>
            {translate('onboarding.AddAnotherPartner')}
          </AddButtonText>
        </Touchable>

        <Button
          text={translate('onboarding.Continue')}
          onPress={this.verifyFormsValidation}
        />
      </Wrapper>
    );
  }
}

export default CompanyPartnerForm;
