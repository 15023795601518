import styled from 'styled-components';
import {
  RadioGroup as BaseRadioGroup,
  InfoText as BaseInfoText,
  FormField as BaseFormField,
} from '@vizir-banking/banking-app-core/dist/common';
import {
  Form as BaseForm,
  Button as BaseButton,
  Text,
  View,
} from '@vizir-banking/banking-app-core/dist/layout';
import {Input} from '@vizir-banking/banking-app-core/dist/common/text-input/text-input.styles';

export const Wrapper = styled(View)`
  flex-grow: 1;
`;

export const RadioGroup = styled(BaseRadioGroup)`
  margin: 0;
  justify-content: center;
`;

export const Title = styled(Text)`
  font-size: 25px;
  margin: 0 0 39px;
  text-align: center;
`;

export const Form = styled(BaseForm)`
  flex-grow: 1;
  justify-content: center;
`;

export const FormField = styled(BaseFormField).attrs({
  textInputStyle: {textAlign: 'center'},
})`
  ${Input} {
    text-align: center;
  }

  margin-top: 31px;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
`;

export const FieldWrapper = styled(View)`
  flex-grow: 1;
  justify-content: center;
`;

export const Button = styled(BaseButton)``;

export const InfoText = styled(BaseInfoText)`
  margin-top: 25px;
`;
