import styled from 'styled-components';
import {View, Bold, Icon} from '@vizir-banking/banking-app-core/dist/layout';
import FormFieldLayout from '@vizir-banking/banking-app-core/dist/common/form-field/form-field';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  margin-bottom: 30px;
`;

export const Header = styled(View)`
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
`;

export const TrashIcon = styled(Icon).attrs({
  name: 'trash',
  width: 16,
  height: 17,
})``;

export const Title = styled(Bold)`
  font-size: 25px;
  color: ${useTheme(({colors}) => colors.textTertiary)};
`;

export const FormField = styled(FormFieldLayout)`
  margin-top: 10px;
`;

export const AdminWrapper = styled(View)`
  align-items: flex-start;
`;
