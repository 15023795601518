import {css} from 'styled-components';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const TitleStyle = css`
  text-align: center;
  font-size: 25px;
  margin: 30px 0;
  color: ${useTheme(({colors}) => colors.title.primary)};
`;

export const TextStyle = css`
  font-size: 16px;
  color: ${useTheme(({colors}) => colors.title.primary)};
`;
