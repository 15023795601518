import styled from 'styled-components';
import {View, Text} from '@vizir-banking/banking-app-core/dist/layout';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const Wrapper = styled(View)`
  margin-bottom: 45px;
`;

export const Label = styled(Text)`
  color: ${useTheme(({colors}) => colors.textSecondary)};
  font-size: 16px;
`;

export const Value = styled(Text)`
  color: ${useTheme(({colors}) => colors.textSecondary)};
  font-size: 18px;
`;
