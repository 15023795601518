import Yup from '@vizir-banking/banking-app-core/dist/utils/yup/yup';
import {
  requiredField,
  invalidEmail,
} from '@vizir-banking/banking-app-core/dist/utils/validation-messages';

const schema = Yup.object({
  name: Yup.string().required(requiredField),
  email: Yup.string().email(invalidEmail).required(requiredField),
});

export default schema;
