import React, {ReactElement} from 'react';
import {
  ConfirmDialog,
  Screen,
  ScreenTypes,
  UserAvatar,
} from '@vizir-banking/banking-app-core/dist/common';
import {Button, ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import UserEntity from '@vizir-banking/banking-app-core/dist/entities/user/user';
import {ListOption} from '@vizir-banking/banking-app-core/dist/layout/list/types';
import isEmpty from 'lodash/isEmpty';

import {
  AccountInfo,
  AccountWrapper,
  ButtonWrapper,
  List,
  ProfileInfo,
  UserDocument,
  UserName,
} from '~/settings/screens/profile/profile.styles';
import translate from '~/i18n/translate';

type Props = {
  user: UserEntity;
  options: ListOption[];
  handleShowBankData: () => void;
  onItemSelected: (item: ListOption) => void;
  isLogoutDialogOpen: boolean;
  hideLogoutDialog: () => void;
  onLogout: () => Promise<void>;
};

export const ProfileScreen = (props: Props): ReactElement => {
  const {
    user,
    options,
    handleShowBankData,
    onItemSelected,
    isLogoutDialogOpen,
    hideLogoutDialog,
    onLogout,
  } = props;

  const renderProfileInfo = (): ReactElement | null => {
    if (isEmpty(user)) {
      return null;
    }

    return (
      <ProfileInfo>
        <UserAvatar size={70} fontSize={22} username={userName} />
        <UserName>{userName}</UserName>
        <UserDocument>
          {translate(`settings.documents.${documentKey}`)}:{' '}
          {user.formattedDocument()}
        </UserDocument>
        <AccountWrapper>
          <AccountInfo>
            {translate('settings.digitalAccount')}: {user.mainAccountId()}
          </AccountInfo>
        </AccountWrapper>
      </ProfileInfo>
    );
  };

  const renderLogoutDialog = (): ReactElement => {
    return (
      <ConfirmDialog
        show={isLogoutDialogOpen}
        message={translate('settings.logout.confirmMessage')}
        submitText={translate('settings.logout.submit')}
        cancelText={translate('settings.logout.cancel')}
        onClose={hideLogoutDialog}
        onSubmit={onLogout}
      />
    );
  };

  const userName = user.name || '';
  const documentKey = user.documentType();

  return (
    <Screen type={ScreenTypes.LOGGED} title={translate('settings.title')}>
      {renderProfileInfo()}

      <ButtonWrapper>
        <Button
          type={ButtonTypes.SECONDARY}
          text={translate('settings.seeBankDataButton')}
          onPress={handleShowBankData}
        />
      </ButtonWrapper>

      <List
        options={options}
        onItemSelected={onItemSelected}
        hasItemIcon
        iconName="forward_arrow"
      />

      {renderLogoutDialog()}
    </Screen>
  );
};
