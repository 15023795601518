import isWeb from '@vizir-banking/banking-app-core/dist/utils/is-web';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {
  REACT_APP_BETA_ACCESS_KEY,
  REACT_APP_ONBOARDING_ALLOW_COMPANY,
  REACT_APP_ONBOARDING_REQUIRE_PHOTO_RESIDENTIAL,
  REACT_APP_ENABLE_DEVICE_KEY,
} from '~/env';

type State = {
  isMei: boolean;
  isCompanyFlow: boolean;
};

export const mapStateToProps = ({onboarding}: ReduxState): State => ({
  isMei: onboarding.isMei,
  isCompanyFlow: onboarding.isCompanyFlow,
});

type Props = ReturnType<typeof mapStateToProps>;

export type NavigationDefinitions = Array<
  string | ((props: Props) => string | NavigationDefinitions)
>;

const cond = (
  predicate: boolean | ((props: Props) => boolean),
  then: NavigationDefinitions | string,
) => (props: Props): NavigationDefinitions | string | Array<string> => {
  if (predicate === true) return then;
  if (predicate === false) return [];
  return predicate(props) ? then : [];
};

export const navigationDefinitions: NavigationDefinitions = [
  cond(REACT_APP_BETA_ACCESS_KEY !== '', ONBOARDING_SCREENS.accessKey),
  ONBOARDING_SCREENS.phoneNumber,
  ONBOARDING_SCREENS.code,
  ONBOARDING_SCREENS.email,
  ONBOARDING_SCREENS.document,

  // Person onboarding
  cond(({isCompanyFlow}) => !isCompanyFlow, [
    ONBOARDING_SCREENS.identity,
    ONBOARDING_SCREENS.name,
    ONBOARDING_SCREENS.birthday,
    ONBOARDING_SCREENS.motherName,
    ONBOARDING_SCREENS.politicallyExposedPerson,
    ONBOARDING_SCREENS.zipCode,
    ONBOARDING_SCREENS.address,
    ONBOARDING_SCREENS.profession,
    ONBOARDING_SCREENS.password,
    ONBOARDING_SCREENS.termsConditions,
    ONBOARDING_SCREENS.photoIdentificationDocument,
    cond(isWeb(), ONBOARDING_SCREENS.photoIdentificationDocumentBack),
    ONBOARDING_SCREENS.photoSelfie,
  ]),

  // Company onboarding
  cond(
    ({isCompanyFlow}) => REACT_APP_ONBOARDING_ALLOW_COMPANY && isCompanyFlow,
    [
      ONBOARDING_SCREENS.companyFantasyName,
      ONBOARDING_SCREENS.legalNature,
      ONBOARDING_SCREENS.birthday,
      ONBOARDING_SCREENS.stateInscription,
      ONBOARDING_SCREENS.cnae,
      ONBOARDING_SCREENS.companyStatus,
      ONBOARDING_SCREENS.annualRevenue,
      ONBOARDING_SCREENS.zipCode,
      ONBOARDING_SCREENS.address,
      ONBOARDING_SCREENS.password,
      ONBOARDING_SCREENS.termsConditions,

      // MEI
      cond(({isMei}) => isMei, [
        ONBOARDING_SCREENS.meiDocument,
        ONBOARDING_SCREENS.meiAccountCreating,
        ONBOARDING_SCREENS.meiInfo,
      ]),

      cond(({isMei}) => !isMei, [
        ONBOARDING_SCREENS.companyPartners,
        ONBOARDING_SCREENS.attachSocialContract,
        ONBOARDING_SCREENS.attachContractChange,
      ]),
    ],
  ),

  cond(
    REACT_APP_ONBOARDING_REQUIRE_PHOTO_RESIDENTIAL,
    ONBOARDING_SCREENS.photoResidential,
  ),
  cond(
    ({isCompanyFlow}) =>
      !isWeb() && !isCompanyFlow && REACT_APP_ENABLE_DEVICE_KEY,
    ONBOARDING_SCREENS.validateDocumentPhotos,
  ),
  ONBOARDING_SCREENS.accountCreating,
  ONBOARDING_SCREENS.accountCreated,
];
