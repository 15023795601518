import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('en', 'settings', {
  editProfile: {
    title: 'Personal data',
    form: {
      name: 'Full Name',
      phoneNumber: 'Cell Phone Number',
      email: 'E-mail',
      save: 'Save',
    },
    successModal: {
      message: 'Profile updated!',
      buttonLabels: {
        backToStart: 'back to start',
      },
    },
  },
  changeAppPassword: {
    title: 'Change App Password',
    header: 'Enter the current password, the new password and then confirm it',
    form: {
      password: 'New Password',
      confirmPassword: 'Confirm New Password',
      currentPassword: 'Current Password',
      continue: 'Continue',
    },
    successModal: {
      message: 'Password updated!',
      buttonLabels: {
        backToStart: 'back to start',
      },
    },
  },
  changeCardPassword: {
    title: 'Change Card Password',
    unblockCard: 'Unblock card',
  },
  incomeReport: {
    title: 'Income Report',
    header: 'Send via e-mail',
    description: 'Your Income Report will be sent to:',
    confirm: 'Confirm send e-mail?',
    send: 'Send',
    cancel: 'Cancel',
    successModal: {
      title: 'Success!',
      message: 'Your Income Report will be sent to:',
      button: 'Ok, got it',
    },
    unavailableReportModal: {
      title: 'Attention',
      message: 'No Income Reports report available for the last base year.',
    },
  },
  title: 'Profile',
  rateApp: 'Rate App',
  documents: {
    cpf: 'CPF',
    cnpj: 'CNPJ',
  },
  seeBankDataButton: 'See bank data',
  digitalAccount: 'Digital account',
  agency: 'Agency',
  bankAccount: 'Bank Account',
  sensor: {
    title: 'Biometry',
    message: 'To access your account using the mobile phone unlocking system:',
    label: 'Enable to access the account',
    errorTitle: 'Authentication required',
    errorMessage:
      'It was not possible to change your password, repeat the process',
  },
  faq: {
    title: 'FAQ',
  },
  termsOfUse: {
    title: 'Terms of Use',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
  },
  logout: {
    title: 'Logout',
    confirmMessage: 'Are you sure you want to logout?',
    submit: 'Yes',
    cancel: 'No',
  },
});
