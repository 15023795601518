import {Theme} from '@vizir-banking/banking-app-core/dist/theme/types';

/* eslint-disable no-restricted-imports */
import {AppFonts} from './app-fonts';
import {AppSizes} from './app-sizes';
import {AppColors} from './app-colors';
import BaseColors from './colors.json';
/* eslint-enable no-restricted-imports */

export const theme: Theme = {
  colors: AppColors,
  sizes: AppSizes,
  fonts: AppFonts,
  baseColors: BaseColors,
};
