import React, {ReactElement} from 'react';
import {
  RequestErrorDialog,
  ScreenTypes,
} from '@vizir-banking/banking-app-core/dist/common';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import {Types as NavBarTypes} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';

import translate from '~/i18n/translate';
import {ConfirmDocumentProps} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-confirm/types';
import {
  Screen,
  ImagePreview,
  ActionArea,
  ConfirmationText,
  Container,
  ButtonsWrapper,
  ActionButton,
  Navbar,
} from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document-confirm/photo-document-confirm.styles';

class PhotoDocumentConfirm extends React.PureComponent<ConfirmDocumentProps> {
  render(): ReactElement {
    return (
      <Screen type={ScreenTypes.SIMPLE} navType={NavBarTypes.NONE}>
        <Container>
          <Navbar
            icon="back_white"
            onLeftIconPress={this.props.onTakeAnotherPicture}
          />
          <RequestErrorDialog />
          <ImagePreview
            resizeMode="cover"
            resizeMethod="resize"
            source={this.props.imageSource}
          />
          <ActionArea>
            <ConfirmationText>
              {translate('onboarding.documents.photoConfirmation')}
            </ConfirmationText>
            <ButtonsWrapper>
              <ActionButton
                text={translate('onboarding.documents.dismiss')}
                type={ButtonTypes.SECONDARY}
                onPress={this.props.onTakeAnotherPicture}
                hasMarginRight
              />
              <ActionButton
                text={translate('onboarding.documents.accept')}
                onPress={this.props.onPrimaryButtonPress}
              />
            </ButtonsWrapper>
          </ActionArea>
        </Container>
      </Screen>
    );
  }
}

export default PhotoDocumentConfirm;
