import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {CONFLICT} from 'http-status-codes';
import {ButtonTypes} from '@vizir-banking/banking-app-core/dist/layout';
import {Types} from '@vizir-banking/banking-app-core/dist/layout/navbar/types';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {userIsRegistered} from '@vizir-banking/banking-app-core/dist/redux/onboarding/user-is-registered';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {createUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/create-user';
import {resetUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding';
import {
  User,
  ValueOf,
} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {clearRequestError} from '@vizir-banking/banking-app-core/dist/redux/application';
import UserEntity, {
  UserAttributes,
} from '@vizir-banking/banking-app-core/dist/entities/user/user';
import ApiError from '@vizir-banking/banking-app-core/dist/errors/api-error';
import {RoutesManager} from '@vizir-banking/banking-app-core/dist/hooks/routes-manager';

import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import InvalidData from '~/onboarding/screens/account-creating/components/invalid-data';
import InvalidRegistration from '~/onboarding/screens/account-creating/components/invalid-registration';
import {
  Screen,
  Wrapper,
  BottomBar,
  GoBackToLoginLink,
} from '~/onboarding/screens/account-creating/account-creating.styles';
import {REACT_APP_NEW_LOGIN_FLOW} from '~/env';

import RetryFailure from './components/retry';
import Loading from './components/loading';

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

export class AccountCreating extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  state = {loading: false};

  constructor(props: Props) {
    super(props);

    this.createUser = this.createUser.bind(this);
  }

  componentDidMount(): void {
    this.createUser().catch(() => {
      this.setState({loading: false});
    });
  }

  async createUser(): Promise<boolean> {
    this.setState({loading: true});

    const context = this.context as ApplicationContextType;

    if (!this.isUserDataValid()) {
      this.setState({loading: false});
      return false;
    }

    const createdSuccessfully = await this.props.actions.createUser(
      {
        dispatchLoading: false,
      },
      context.bankingCoreHttpClient,
    );

    this.setState({loading: false});

    if (createdSuccessfully) {
      this.navigateToNextOnboardingScreen();
    }

    return createdSuccessfully;
  }

  isUserDataValid = (): boolean => {
    const user = new UserEntity(this.props.creationUser as UserAttributes);
    return user.isValid();
  };

  isInvalidRegistrationError = (): boolean => {
    return this.props.error?.statusCode === CONFLICT;
  };

  isDocumentUploadError = (): boolean => {
    return this.props.error?.code === 'BANK_API_SEND_DOCUMENTS_FAILED';
  };

  navigateToLogin = (): void => {
    this.props.actions.clearRequestError();

    if (REACT_APP_NEW_LOGIN_FLOW) {
      this.props.navigate(RoutesManager.getRoute('login', 'document'));
      return;
    }

    this.props.navigate(RoutesManager.getRoute('login', 'login'));
  };

  navigateToUploadDocumentsScreen = (): void => {
    this.props.actions.clearRequestError();
    this.props.actions.addCreationUser('documents', []);
    this.props.navigateToDocumentUploadScreen();
  };

  navigateToFirstOnboardingScreen = (): void => {
    const {navigate, actions, getFirstOnboardingScreenName} = this.props;

    actions.clearRequestError();
    navigate(getFirstOnboardingScreenName());
  };

  navigateToNextOnboardingScreen = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  renderContent = (): ReactElement => {
    if (this.state.loading) {
      return <Loading />;
    }

    if (!this.isUserDataValid()) {
      return <InvalidData goBack={this.props.goBack} />;
    }

    if (this.isInvalidRegistrationError()) {
      return (
        <InvalidRegistration
          error={this.props.error}
          goToStart={this.navigateToFirstOnboardingScreen}
        />
      );
    }

    if (this.isDocumentUploadError()) {
      return (
        <RetryFailure
          error={this.props.error}
          title={translate('onboarding.accountCreating.retryDocumentUpload')}
          message={translate(
            'onboarding.accountCreating.retryDocumentUploadMessage',
          )}
          buttonLabel={translate(
            'onboarding.accountCreating.retryDocumentUploadButton',
          )}
          onPressRetry={this.navigateToUploadDocumentsScreen}
        />
      );
    }

    return (
      <RetryFailure error={this.props.error} onPressRetry={this.createUser} />
    );
  };

  render(): ReactElement {
    return (
      <Screen navType={Types.NONE}>
        <Wrapper>
          {!this.state.loading && (
            <GoBackToLoginLink
              type={ButtonTypes.TRANSPARENT}
              text={translate('onboarding.accountCreating.goBackLogin')}
              onPress={this.navigateToLogin}
            />
          )}
          {this.renderContent()}
        </Wrapper>
        <BottomBar />
      </Screen>
    );
  }
}

const actionCreators = {
  addCreationUser,
  createUser,
  resetUser,
  clearRequestError,
  userIsRegistered,
};

export type Actions = {
  clearRequestError: () => void;
  createUser: (
    arg: {dispatchLoading?: boolean},
    httpClient: BankingCoreHttpClient,
  ) => Promise<boolean>;
  addCreationUser: (fieldName: keyof User, value: ValueOf<User>) => void;
  resetUser: () => void;
  userIsRegistered: (
    document: string,
    httpClient: BankingCoreHttpClient,
    setError?: boolean,
  ) => Promise<boolean>;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  error?: ApiError;
  creationUser: Partial<User>;
};

const mapStateToProps = ({application, onboarding}: ReduxState): State => ({
  error: application.error as ApiError,
  creationUser: onboarding.creationUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnboardingNavigation(AccountCreating, ONBOARDING_SCREENS.accountCreating),
);
