import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {
  setIsLoading,
  setShowRequestErrorDialog,
  setRequestError,
} from '@vizir-banking/banking-app-core/dist/redux/application';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {getInfo} from '@vizir-banking/banking-app-core/dist/redux/user/get-user-data';
import {logout} from '@vizir-banking/banking-app-core/dist/redux/application/logout';
import UserEntity from '@vizir-banking/banking-app-core/dist/entities/user/user';
import {Notification} from '@vizir-banking/banking-app-core/dist/entities/notification/notification';

import {UnauthorizedListener} from '~/navigation/listeners/unauthorized/unauthorized-listener';
import {Actions} from '~/navigation/listeners/listener';
import {AppStateChangeListener} from '~/navigation/listeners/app-state-change/app-state-change-listener';
import {LoginListener} from '~/navigation/listeners/login-listener/login-listener';
import {NotificationListener} from '~/navigation/listeners/notification/notification-listener';
import {FirebaseRouteTrackingListener} from '~/navigation/listeners/firebase-route-tracking/firebase-route-tracking-listener';

const actionCreators = {
  getInfo,
  logout,
  setIsLoading,
  setRequestError,
  setShowRequestErrorDialog,
};

type State = {
  authToken?: string;
  isLogged: boolean;
  sessionId?: string;
  currentUser: Partial<UserEntity>;
};

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    onUnauthorized: () => void;
    onNotification: (notification: Notification) => void;
    onSessionExpired: () => void;
  };

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

const mapStateToProps = ({
  application,
  onboarding,
  user,
}: ReduxState): State => ({
  isLogged: application.isLogged,
  authToken: application.authToken,
  sessionId: onboarding.onboardingSessionId,
  currentUser: user.currentUser,
});

class ListenersComponent extends React.Component<Props> {
  private appStateChangeListener!: AppStateChangeListener;
  private unauthorizedListener!: UnauthorizedListener;
  private loginListener!: LoginListener;
  private notificationListener!: NotificationListener;
  private firebaseRouteTrackingListener!: FirebaseRouteTrackingListener;
  static contextType = ApplicationContext;

  private get applicationContext(): ApplicationContextType {
    return this.context;
  }

  private setAuthorization = (client: BankingCoreHttpClient): void => {
    if (this.props.authToken) {
      client.setAuthorization(this.props.authToken);

      return;
    }

    this.applicationContext.bankingCoreHttpClient.clearAuthorization();
  };

  private setSessionId = (client: BankingCoreHttpClient): void => {
    if (this.props.sessionId) {
      client.setOnboardingSession(this.props.sessionId);

      return;
    }

    this.applicationContext.bankingCoreHttpClient.clearOnboardingSession();
  };

  private buildListeners(): void {
    this.appStateChangeListener = new AppStateChangeListener(
      this.props.actions,
      this.props.isLogged,
      this.applicationContext.bankingCoreHttpClient,
      this.props.onSessionExpired,
    );
    this.unauthorizedListener = new UnauthorizedListener(
      this.props.actions,
      this.props.onUnauthorized,
      this.applicationContext.bankingCoreHttpClient,
    );
    this.loginListener = new LoginListener(this.props.currentUser);
    this.notificationListener = new NotificationListener(
      this.applicationContext.notificationAdapter,
      this.props.onNotification,
    );
    this.firebaseRouteTrackingListener = new FirebaseRouteTrackingListener();
  }

  private startListeners(): void {
    this.notificationListener.setListener();
    this.appStateChangeListener.setListener();
    this.unauthorizedListener.setListener();
    this.loginListener.setListener();
    this.firebaseRouteTrackingListener.setListener();
  }

  private stopListeners(): void {
    this.notificationListener.unsetListener();
    this.appStateChangeListener.unsetListener();
    this.unauthorizedListener.unsetListener();
    this.loginListener.unsetListener();
    this.firebaseRouteTrackingListener.unsetListener();
  }

  componentDidMount(): void {
    this.setAuthorization(this.applicationContext.bankingCoreHttpClient);
    this.setSessionId(this.applicationContext.bankingCoreHttpClient);
    this.buildListeners();
    this.startListeners();
  }

  async componentDidUpdate(): Promise<void> {
    const context = this.context as ApplicationContextType;

    this.appStateChangeListener.setIsLogged(this.props.isLogged);
    this.appStateChangeListener.setHttpClient(context.bankingCoreHttpClient);
    this.unauthorizedListener.setHttpClient(context.bankingCoreHttpClient);
    this.setAuthorization(context.bankingCoreHttpClient);
    this.setSessionId(context.bankingCoreHttpClient);
    this.loginListener.setCurrentUser(this.props.currentUser);
  }

  componentWillUnmount(): void {
    this.stopListeners();
  }

  render(): null {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListenersComponent);
