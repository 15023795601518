import styled from 'styled-components';
import {
  Button as BaseButton,
  Text,
  View,
} from '@vizir-banking/banking-app-core/dist/layout';

export const Wrapper = styled(View)`
  align-items: center;
`;

export const Message = styled(Text)`
  text-align: center;
`;

export const Email = styled(Text)`
  margin-top: 24px;
`;

export const Button = styled(BaseButton)`
  margin-top: 36px;
`;
