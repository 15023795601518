import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';

import {Actions, Listener} from '~/navigation/listeners/listener';

export abstract class AppStateChangeListenerBase implements Listener {
  public constructor(
    protected readonly actions: Actions,
    protected isLogged: boolean,
    protected httpClient: BankingCoreHttpClient,
    protected onSessionExpired: () => void,
  ) {}

  public setIsLogged(isLogged: boolean): void {
    this.isLogged = isLogged;
  }

  public setHttpClient(httpClient: BankingCoreHttpClient): void {
    this.httpClient = httpClient;
  }

  public abstract setListener(): void;

  public abstract unsetListener(): void;
}
