import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {InfoText} from '@vizir-banking/banking-app-core/dist/common';
import {
  Bold,
  Text,
  MaskTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {BankingCoreHttpClient} from '@vizir-banking/banking-app-core/dist/api/http-client/banking-core-http-client';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {getAddressByZipcode} from '@vizir-banking/banking-app-core/dist/redux/onboarding/get-address-by-zipcode';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import SingleInputForm from '~/onboarding/components/single-input-form/single-input-form';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const actionCreators = {addCreationUser, getAddressByZipcode};

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

class ZipCodeScreen extends React.PureComponent<Props> {
  static contextType = ApplicationContext;

  submit = async (value: string): Promise<void> => {
    const context = this.context as ApplicationContextType;
    const {storedZipCode, navigateToNextOnboardingScreen, actions} = this.props;

    actions.addCreationUser('zipCode', value);

    if (storedZipCode !== value) {
      await actions.getAddressByZipcode(value, context.bankingCoreHttpClient);
    }

    navigateToNextOnboardingScreen();
  };

  renderText(): ReactElement {
    return (
      <>
        <Text>
          {`${translate('onboarding.zipcode.zipcodeFormMessage')}. ${translate(
            'onboarding.WhatsYour',
          )}`}
          <Bold>{`${translate('onboarding.zipcode.zipcode')}`}</Bold>?
        </Text>
      </>
    );
  }

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        <SingleInputForm
          validationSchemaName={SchemaNames.CEP}
          content={this.renderText}
          reduxValueKey="zipCode"
          onSubmit={this.submit}
          textInputMask={MaskTypes.CEP}
        >
          <InfoText title={translate('onboarding.zipcode.infoText')} text="" />
        </SingleInputForm>
      </OnboardingScreen>
    );
  }
}

type Actions = {
  addCreationUser: (fieldName: keyof User, value: string) => void;
  getAddressByZipcode: (
    zipCode: string,
    httpClient: BankingCoreHttpClient,
  ) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  storedZipCode: string;
};

const mapStateToProps = (state: ReduxState): State => ({
  storedZipCode: get(state.user, 'creationUser.addresses[0].zipCode', ''),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(ZipCodeScreen, ONBOARDING_SCREENS.zipCode));
