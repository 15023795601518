import {createStore as reduxCreateStore, combineReducers, Store} from 'redux';
import {persistStore, Persistor} from 'redux-persist';
import {ReduxManager} from '@vizir-banking/banking-app-core/dist/hooks/redux-manager';

import {applyMiddlewares} from './apply-middlewares';

const createStore = (): {
  store: Store;
  persistor: Persistor;
} => {
  const reducers = combineReducers(ReduxManager.getReducers());

  const store = reduxCreateStore(reducers, applyMiddlewares());

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export {createStore};
