import React, {ReactElement} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Bold, Text} from '@vizir-banking/banking-app-core/dist/layout';
import AddressForm from '@vizir-banking/banking-app-core/dist/common/address-form/address-form';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {User} from '@vizir-banking/banking-app-core/dist/redux/user/types';
import {Address} from '@vizir-banking/banking-app-core/dist/entities/user/address';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import {FormTitle} from '~/onboarding/components/shared-styles/shared-styles';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import {OnboardingScreen} from '~/onboarding/components/screen-with-onboarding-progress/screen-with-onboarding-progress';

const actionCreators = {addCreationUser};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: Partial<Address>[]) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

type State = {
  isLoading: boolean;
  isCompany: boolean;
  address: {
    zipCode: string;
    state: string;
    city: string;
    complement: string;
    street: string;
    number: string;
    neighborhood: string;
  };
};

const mapStateToProps = ({onboarding, application}: ReduxState): State => ({
  isLoading: application.isLoading,
  isCompany: onboarding.isCompanyFlow,
  address: {
    zipCode: get(onboarding, 'creationUser.addresses[0].zipCode', ''),
    state: get(onboarding, 'creationUser.addresses[0].state', ''),
    city: get(onboarding, 'creationUser.addresses[0].city', ''),
    complement: get(onboarding, 'creationUser.addresses[0].complement', ''),
    street: get(onboarding, 'creationUser.addresses[0].street', ''),
    number: get(onboarding, 'creationUser.addresses[0].number', ''),
    neighborhood: get(onboarding, 'creationUser.addresses[0].neighborhood', ''),
  },
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OnboardingNavigationProps;

class AddressScreen extends React.PureComponent<Props> {
  submit = (formValues: any): void => {
    const {navigateToNextOnboardingScreen, actions} = this.props;

    const addresses: Partial<Address>[] = [formValues];

    actions.addCreationUser('addresses', addresses);

    navigateToNextOnboardingScreen();
  };

  renderText(): Element {
    return (
      <FormTitle>
        <Text>
          {translate('onboarding.fillInThe')}{' '}
          <Bold>{translate('onboarding.number')}</Bold>{' '}
        </Text>
        <Text>
          {translate('onboarding.andThe')}{' '}
          <Bold>{translate('onboarding.complement')}.</Bold>
        </Text>
      </FormTitle>
    );
  }

  render(): ReactElement {
    return (
      <OnboardingScreen showProgress>
        {this.renderText()}
        <AddressForm
          disabledZipcode
          onSubmit={this.submit}
          initialValues={this.props.address}
        />
      </OnboardingScreen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnboardingNavigation(AddressScreen, ONBOARDING_SCREENS.address));
