import {EstablishmentFormat} from '@vizir-banking/banking-app-core/dist/entities/user/enums/establishment-format';

export const establishmentFormatList = [
  {value: EstablishmentFormat.EI, label: EstablishmentFormat.EI},
  {value: EstablishmentFormat.MEI, label: EstablishmentFormat.MEI},
  {value: EstablishmentFormat.EIRELI, label: EstablishmentFormat.EIRELI},
  {value: EstablishmentFormat.LTDA, label: EstablishmentFormat.LTDA},
  {value: EstablishmentFormat.SS, label: EstablishmentFormat.SS},
  {value: EstablishmentFormat.SA, label: EstablishmentFormat.SA},
  {value: EstablishmentFormat.ME, label: EstablishmentFormat.ME},
  {value: EstablishmentFormat.EPP, label: EstablishmentFormat.EPP},
  {value: EstablishmentFormat.EMPG, label: EstablishmentFormat.EMPG},
  {value: EstablishmentFormat.COOP, label: EstablishmentFormat.COOP},
];
