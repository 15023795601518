import React, {ReactElement} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {
  Bold,
  Text,
  MaskTypes,
} from '@vizir-banking/banking-app-core/dist/layout';
import {SchemaNames} from '@vizir-banking/banking-app-core/dist/common/single-input-form/types';
import {addCreationUser} from '@vizir-banking/banking-app-core/dist/redux/onboarding/add-creation-user';
import {
  User,
  ValueOf,
} from '@vizir-banking/banking-app-core/dist/redux/user/types';

import DocumentScreen from '~/onboarding/components/document-screen/document-screen.container';
import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import translate from '~/i18n/translate';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';

type Props = ReturnType<typeof mapDispatchToProps> & OnboardingNavigationProps;

const MeiDocument = (props: Props): ReactElement => {
  const submit = (documentNumber: string): void => {
    props.actions.addCreationUser('partners', [{documentNumber}]);
    props.navigateToNextOnboardingScreen();
  };

  const renderText = (): ReactElement => {
    return (
      <Text>
        {translate('onboarding.meiDocument.label')}
        <Bold>{translate('onboarding.CPF')}</Bold>:
      </Text>
    );
  };

  return (
    <DocumentScreen
      showProgress
      isCompanyFlow
      renderTitle={renderText}
      schemaName={SchemaNames.CPF}
      maskName={MaskTypes.CPF}
      onSubmit={submit}
    />
  );
};

const actionCreators = {
  addCreationUser,
};

type Actions = {
  addCreationUser: (fieldName: keyof User, value: ValueOf<User>) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): {actions: Actions} => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(withOnboardingNavigation(MeiDocument, ONBOARDING_SCREENS.meiDocument));
